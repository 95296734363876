<template>
	<div class="d-flex flex-column flex h100 page-body">
		<navbar @login="doReturn" :versionCheckRefresh="response ? response.id : 'nocheck'"></navbar>
		<div class="app">
			<!-- <sidenav></sidenav> -->
			<div id="content" v-if="item" class="app-content box-shadow-3">
				<!-- Optional navbar at top of page -->
				<div class="navbar navbar-light bg-light">
					<div class="d-flex flex-row align-items-center">
						<div class="d-flex flex-row align-content-center">
							<i
								v-if="driver.icon"
								class="no-select material-icons"
								style="font-size: 20px; line-height: 24px"
								v-tippy
								:title="driver.name"
								>{{ driver.icon }}</i
							>
							<i
								v-if="driver.fa_icon"
								class="no-select fas"
								:class="{ [driver.fa_icon]: true }"
								style="font-size: 16px; line-height: 24px"
								v-tippy
								:title="driver.name"
								>{{ driver.icon }}</i
							>
							<span v-if="alertName" class="mx-1">
								{{ alertName }}
								<i class="far fa-angle-right ml-1"></i>
							</span>
							<span v-if="item.hide_response_id" class="mx-1">{{ item.name }}</span>
							<span v-if="!item.hide_response_id" class="mx-1">{{ item.name }}:</span>
							<strong v-if="showResponsesRef">
								{{ response.ref_id }}
								<a
									v-if="response.signed_full_doc_url && showFullDoc"
									target="_blank"
									:href="response.signed_full_doc_url"
									class="ml-1"
									v-tippy
									:title="$t('tooltip.view_full_document')"
								>
									<i class="fas fa-external-link"></i>
								</a>
							</strong>
							<strong v-if="!item.hide_response_id && !(response && response.ref_id)">{{
								$t("data_description.loading")
							}}</strong>

							<!-- <span class="ml-3">
								ToLoad: {{ mediaToLoad }} Loaded: {{ mediaLoaded }} Failed: {{ mediaFailed }}
							</span> -->
						</div>
					</div>

					<div class="d-flex flex-row align-items-center">
						<div
							v-if="item.show_timeout_timer"
							class="px-2 mr-2 slow-color"
							v-b-popover
							id="timeout"
							:class="timeoutColor(timeout)"
							style="padding-top: 2px"
						>
							<i class="fas fa-clock fa-lg"></i>
						</div>
						<b-popover
							v-if="item.show_timeout_timer"
							target="#timeout"
							triggers="hover"
							placement="leftbottom"
						>
							<template slot="title">
								<span class="px-3 text-center">Timeout</span>
							</template>
							<div
								class="d-flex flex-row align-items-baseline justify-content-center slow-color"
								:class="timeoutColor(timeout)"
							>
								<h6 class="_600 mb-0">{{ fs.shortDurationMinutes(timeout) }}</h6>
								<span class="text-muted mx-small">m</span>
								<h6 class="_600 mb-0">{{ fs.shortDurationSeconds(timeout) }}</h6>
								<span class="text-muted mx-small">s</span>
							</div>
						</b-popover>
						<div v-if="score_count > 0" class="mr-3">
							{{ $t("fields.scored") }}:
							<strong>{{ score_count }}</strong>
						</div>
						<template v-if="response && user.role.copyleaks_reports && anyCopyleaksResults(response)">
							<div
								id="copyleaksReport"
								class="exit-button b-l ml-0 mr-0 d-flex flex-column justify-content-center align-items-center"
							>
								<div
									class="copyleaks-icon d-flex flex-column justify-content-center align-items-center"
								>
									{{ getHighestCopyleaksScore(response) }}<br />%
								</div>
							</div>
							<b-popover target="#copyleaksReport" triggers="hover" placement="leftbottom">
								<template slot="title">
									<div class="text-center">
										<img
											src="https://auth.copyleaks.com/logo.svg"
											height="40"
											class="text-center"
										/>
									</div>
								</template>
								<copyleaks-results :response="response" />
							</b-popover>
						</template>
						<template
							v-if="
								user.role.allow_print &&
								response &&
								(response.media.length === 0 || response.media[0].mime_type !== 'iris')
							"
						>
							<template
								v-if="
									(response.media.length == 0 && response.mime_type == 'text') ||
									(response.media.length == 1 && response.media[0].mime_type == 'text')
								"
							>
								<a
									target="_blank"
									class="exit-button b-l ml-0 mr-0 d-flex flex-column justify-content-center"
									:class="{ 'show-print': showPrint }"
									v-tippy="{ placement: 'bottom' }"
									:title="$t('tooltip.print')"
									data-toggle="dropdown"
								>
									<i class="fas fa-lg fa-print"></i>
								</a>
								<div class="dropdown-menu dropdown-menu-right">
									<a class="dropdown-item" @click="print(response)">
										<label class="mb-0">As Text</label>
									</a>
									<a class="dropdown-item" @click="printStyled(response)">
										<label class="mb-0">As Displayed</label>
									</a>
								</div>
							</template>
							<a
								v-else-if="response.media.length == 0 && response.mime_type != 'text'"
								@click="downloadResponse(response)"
								target="_blank"
								class="exit-button b-l ml-0 mr-0 d-flex flex-column justify-content-center"
								:class="{ 'show-print': showPrint }"
								v-tippy="{ placement: 'bottom' }"
								:title="$t('tooltip.download')"
							>
								<i class="fas fa-lg fa-file-export"></i>
							</a>
							<a
								v-else
								@click="print(response)"
								target="_blank"
								class="exit-button b-l ml-0 mr-0 d-flex flex-column justify-content-center"
								:class="{ 'show-print': showPrint }"
								v-tippy="{ placement: 'bottom' }"
								:title="$t('tooltip.print')"
							>
								<i class="fas fa-lg fa-print"></i>
							</a>
						</template>
						<div
							class="exit-button b-l ml-0 d-flex flex-column justify-content-center"
							@click="doReturn()"
							v-tippy="{ placement: 'bottom' }"
							:title="$t('tooltip.exit')"
						>
							<!-- Exit -->
							<i class="fas fa-lg fa-times"></i>
						</div>
					</div>
				</div>

				<div class="content" v-if="item">
					<div
						class="viewer-container"
						:class="{
							'show-sidenav': showRubric,
							'show-resources': showResources,
							'show-notes': showNotes,
							'show-res': showRes,
							'show-message': showMessageUI,
						}"
					>
						<!-- The media area -->
						<div
							class="viewer-viewport"
							:class="{ trans: !resizing }"
							id="media"
							:style="{ right: viewportRight, bottom: viewportBottom }"
						>
							<media
								v-show="view == 0"
								:response="response0"
								:item="item"
								:score="score"
								:chan="chan"
								@setup="setupFinished(0)"
								:pageRefID="pageNav && pageNav.pageRefID"
								class="viewport-media"
								:class="{ 'showing-media': view == 0 }"
								resizeContainer="media"
								:showPrint="showPrint"
								:highlighter.sync="highlighter"
								@viewedAll="viewedAll = true"
							></media>
							<media
								v-show="view == 1"
								:response="response1"
								:item="item"
								:score="score"
								:chan="chan"
								@setup="setupFinished(1)"
								:pageRefID="pageNav && pageNav.pageRefID"
								class="viewport-media"
								:class="{ 'showing-media': view == 1 }"
								resizeContainer="media"
								:showPrint="showPrint"
								:highlighter.sync="highlighter"
								@viewedAll="viewedAll = true"
							></media>
							<div
								class="faux-scroll-border"
								:class="{ trans: relayouting }"
								:style="{
									right: viewportScrollBorderRight,
									bottom: viewportBottom,
									width: scrollWidth > 0 ? '3px' : 0,
								}"
							></div>
						</div>

						<ViewerControls
							:user="user"
							:chan="chan"
							:item="item"
							@calculator="toggleCalc"
							class="controls-overlay trans"
							:canAnnotate="showRubricComponent || (driver.sidebarType() == 'qc_true_score' && response)"
							:keypressHandler.sync="viewerControlsKeypressHandler"
							:positionRight.sync="viewerControlsPositionRight"
							:style="{
								left: viewerControlsPositionRight ? '' : '20px',
								right: viewerControlsPositionRight ? `calc(${viewportRight} + 42px)` : '',
							}"
						></ViewerControls>

						<div
							v-if="showCalc"
							class="calc-box trans d-flex flex-column justify-content-end"
							:style="{ right: calcRight, bottom: viewportBottom }"
						>
							<calculator />
						</div>

						<!-- Personal notes -->
						<div
							v-if="item.notes_enabled"
							class="notes-container px-2"
							:class="{ trans: !resizing }"
							:style="{ right: viewportRight, bottom: notesBottom, height: notesHeight }"
						>
							<personal-note :item="item" :user="user" />
						</div>

						<!-- Notes split resize -->
						<div
							v-if="item.notes_enabled"
							class="resize-btn-h toggles-overlay"
							@mousedown="notesResizeStart"
							@mouseup="notesResizeEnd"
							@touchstart="notesResizeStart"
							@touchend="notesResizeEnd"
							id="notes-resize"
							:class="{ show: toggle_hit || resizing, trans: !resizing }"
							:style="{ bottom: notesResizeBottom, left: notesResizeLeft }"
						>
							<i class="material-icons no-select">drag_indicator</i>
						</div>

						<div
							v-if="item.notes_enabled"
							class="notes-collapse-btn bottom-btn"
							@click="toggleNotes()"
							id="notes-toggle"
							:class="{ show: toggle_hit || resizing, trans: !resizing }"
							:style="{ left: '8px', bottom: viewportBottom }"
						>
							<i
								v-if="!showNotes"
								v-tippy="{ placement: 'top' }"
								:title="$t('tooltip.show_personal_notes')"
								class="material-icons"
								>open_in_browser</i
							>
							<i
								v-if="showNotes"
								v-tippy="{ placement: 'top' }"
								:title="$t('tooltip.hide_personal_notes')"
								class="material-icons"
								>keyboard_arrow_down</i
							>
						</div>

						<!-- Resources pane -->
						<div
							v-show="showResources && !showExemplarSets"
							class="resources"
							:class="{ trans: !resizing }"
							id="resources"
							:style="{ right: resourcesRight, width: resourcesWidth }"
						>
							<div class="r-controls-overlay trans dropright">
								<button
									class="md-btn md-fab md-fab-sm mb-2 theme"
									v-tippy
									:title="$t('tooltip.resources')"
									data-toggle="dropdown"
									aria-expanded="false"
								>
									<i class="far fa-info-circle"></i>
								</button>
								<div v-if="item.resources && item.resources.length > 0" class="dropdown-menu">
									<div v-if="!item.resources || item.resources.length == 0">
										<h6 class="text-muted text-center">
											{{ $t("data_description.no_resources") }}
										</h6>
									</div>
									<template v-for="res in item.resources">
										<a
											v-if="Utils.isRenderableResource(res)"
											:key="res.id"
											class="dropdown-item theme-hover d-flex flex-row align-items-center"
											@click="pickedResource = res"
											:class="{ active: pickedResource && res.id == pickedResource.id }"
										>
											<i
												class="fa-lg"
												:class="{
													[pickedResource && res.id == pickedResource.id ? 'fas' : 'far']:
														true,
													[fs.mimeTypeIcon(res.mime_type)]: true,
												}"
											></i>
											<span class="flex">{{ res.name }}</span>
											<i
												class="far ml-3 fa-external-link text-very-muted float-right"
												style="margin-right: -16px"
												@click="viewExternalResource(res)"
												v-tippy
												:title="$t('tooltip.open_in_new_window')"
											></i>
										</a>
										<a
											v-if="
												!Utils.isRenderableResource(res) && !user.role.prevent_resource_download
											"
											:key="res.id"
											class="dropdown-item theme-hover d-flex flex-row align-items-center"
											@click="downloadResource(res)"
											:class="{ active: pickedResource && res.id == pickedResource.id }"
										>
											<i
												class="fa-lg"
												:class="{
													[pickedResource && res.id == pickedResource.id ? 'fas' : 'far']:
														true,
													[fs.mimeTypeIcon(res.mime_type)]: true,
												}"
											></i>
											<div class="flex d-flex flex-column">
												<div>
													<span class="flex">{{ res.name }}</span>
												</div>
												<div class="text-xxs text-muted mb-1" style="line-height: 0.8">
													{{ $t("ExternalResource.download_only") }}
												</div>
											</div>
											<i
												class="far ml-3 fa-lg fa-download text-extra-muted float-right"
												style="margin-right: -16px"
											></i>
										</a>
										<div
											v-if="
												!Utils.isRenderableResource(res) && user.role.prevent_resource_download
											"
											:key="res.id"
											class="dropdown-item"
										>
											<div>
												<i
													class="fa-lg"
													:class="{
														[pickedResource && res.id == pickedResource.id ? 'fas' : 'far']:
															true,
														[fs.mimeTypeIcon(res.mime_type)]: true,
													}"
												></i>
												<span class="flex">{{ res.name }}</span>
											</div>
											<div class="text-xxs text-muted">
												{{ $t("ExternalResource.cannot_render_or_download") }}
											</div>
										</div>
									</template>
								</div>
							</div>
							<!-- <div class="nav-active-bg tabs-overlay trans mx-5">
								<ul class="nav nav-tabs justify-content-center b-l">
									<li v-for="resource in item.resources" :key="resource.id" class="nav-item">
										<a class="nav-link px-2 py-1" @click="pickResource(resource)" :class="{'active': pickedResource && pickedResource.id == resource.id, 'show': pickedResource && pickedResource.id == resource.id}"
										    v-tippy="{placement: 'bottom', theme: 'popover'}" :title="resource.name">
											{{resource.name}}
										</a>
									</li>
								</ul>
							</div>-->
							<div class="resources-container" id="resources-container" v-if="pickedResource">
								<resource :resource="pickedResource" :item="item" @open="viewResource" />
							</div>
						</div>

						<!-- Exemplar pane -->
						<div
							v-show="showResources && showExemplarSets"
							class="resources"
							:class="{ trans: !resizing }"
							id="exemplars"
							:style="{ right: resourcesRight, width: resourcesWidth }"
						>
							<div class="e-controls-overlay trans dropright">
								<button
									class="md-btn md-fab md-fab-sm mb-2 theme"
									v-tippy
									:title="$t('tooltip.exemplar_sets')"
									data-toggle="dropdown"
									aria-expanded="false"
								>
									<i class="far fa-info-circle"></i>
								</button>
								<div class="dropdown-menu">
									<div v-if="!exemplarSets || exemplarSets.length == 0">
										<h6 class="text-muted text-center">
											{{ $t("data_description.no_exemplar_sets") }}
										</h6>
									</div>
									<template v-if="exemplarSets && exemplarSets.length > 0">
										<a
											v-for="exSet in exemplarSets"
											:key="exSet.id"
											class="dropdown-item theme-hover d-flex flex-row align-items-center"
											@click="pickExemplarSet(exSet)"
											:class="{ active: exSet.id == pickedExemplarSet.id }"
										>
											<i
												class="fa-lg fa-file-certificate"
												:class="{ [exSet.id == pickedExemplarSet.id ? 'fas' : 'far']: true }"
											></i>
											<span class="flex">{{ exSet.name }}</span>
											<a
												class="float-right"
												style="margin-right: -16px"
												target="_blank"
												:href="`#/exemplar_set/${params.section_id}/${params.item_id}/${exSet.id}/${isForTraining}`"
											>
												<i
													class="far ml-3 fa-external-link text-very-muted"
													v-tippy
													:title="$t('tooltip.open_in_new_window')"
												></i>
											</a>
										</a>
									</template>
								</div>
							</div>
							<!-- <div class="nav-active-bg tabs-overlay trans mx-5">
								<ul class="nav nav-tabs justify-content-center b-l">
									<li v-for="resource in item.resources" :key="resource.id" class="nav-item">
										<a class="nav-link px-2 py-1" @click="pickResource(resource)" :class="{'active': pickedResource && pickedResource.id == resource.id, 'show': pickedResource && pickedResource.id == resource.id}"
										    v-tippy="{placement: 'bottom', theme: 'popover'}" :title="resource.name">
											{{resource.name}}
										</a>
									</li>
								</ul>
							</div>-->
							<!-- <div class="resources-container" style="margin-right: 3px">
								<exemplar-set v-if="pickedExemplarSet" :set="pickedExemplarSet" :item="item"/>
							</div>-->

							<div
								class="d-flex flex-column align-items-center flex h100"
								:key="renderExemplarSetComponentKey"
							>
								<exemplar-set
									v-if="pickedExemplarSet && pickedExemplarSet.id && item"
									:set="pickedExemplarSet"
									:item="item"
									:numPages.sync="numExemplarPages"
									:currentPage="currentExemplarPage"
									class="resources-container scroll-x scroll-y"
									id="exemplar-container"
								/>
								<exemplar-nav
									v-if="pickedExemplarSet && pickedExemplarSet.id && item"
									:set="pickedExemplarSet"
									:numPages="numExemplarPages"
									v-model="currentExemplarPage"
								/>
							</div>
						</div>

						<div
							class="faux-scroll-border"
							:class="{ trans: relayouting }"
							:style="{
								right: resourcesScrollBorderRight,
								bottom: showExemplarSets ? '47px' : 0,
								width: resourceScrollWidth > 0 ? '3px' : 0,
							}"
							style="z-index: 3"
						></div>

						<!-- Rubric Sidenav -->
						<div
							class="viewer-sidenav trans pst-rlt"
							id="sidenav"
							:style="{ width: sidenavWidth + 'px', right: sidenavRight + 'px' }"
						>
							<div v-for="rateException in rateExceptions" :key="rateException.id" class="mr-2">
								<div class="box mb-0">
									<div class="box-header warning">
										<h3>{{ $t("Viewer.warning") }}</h3>
									</div>

									<div class="box-body">
										<p class="m-0">
											{{ rateExceptionWarning(rateException) }}
										</p>
									</div>
								</div>
							</div>

							<!-- Rubric & previous scores -->
							<div
								v-if="showRubricComponent"
								v-show="!softHideRubricComponent"
								class="h-100"
								:class="{ prev: showScores || hasFlag }"
							>
								<div class="d-flex flex-column h-100">
									<template v-if="!showGoto">
										<!-- Rangefinding comment -->
										<div v-if="showComment" style="width: 440px">
											<div class="d-flex flex-row align-items-center mb-1">
												<div class="flex">{{ $t("Viewer.comment") }}</div>
												<button class="btn btn-primary" @click="showComment = false">
													{{ $t("buttons.remove") }}
												</button>
											</div>
											<RichText
												:initial="initComment"
												:text.sync="comment"
												:options="{ height: 150 }"
												class="mb-0"
											></RichText>
										</div>

										<div v-if="annoChoices" style="width: 230px" class="w-100">
											<div>Show annotations:</div>
											<div class="btn-group" role="group" aria-label="Choose annotations">
												<button
													v-for="choice in annoChoices"
													:key="choice.score_type"
													class="btn border"
													:class="{
														'btn-success': loadedAnnos == `score-${choice.score_type}`,
													}"
													@click="clickAnnotationChoice(choice)"
												>
													{{ choice.label }}
												</button>
											</div>
											<hr class="mb-0" />
										</div>

										<div
											class="flex d-flex flex-row scroll-y justify-content-start padding-fix-for-shadows"
										>
											<div style="width: 230px" v-if="showScores">
												<score
													v-for="score in sortedScores"
													@on-message="messageUser"
													@on-reset="resetScorePrompt"
													:reset="false"
													:resetScores="
														user.role.show_reset_scores ? response.reset_scores : null
													"
													:currentScores="
														user.role.show_reset_scores ? response.scores : null
													"
													:message="user.role.mail_send_all"
													:key="score.id"
													:canShowAnnotations="user.role.show_live_annotations"
													:score="score"
													:rubric="item.rubric"
													@showAnnotations="loadScoreAnnotations(score.id, $event)"
													:showingAnnotations="loadedAnnos"
													class="my-2"
												></score>
											</div>

											<div style="width: 210px; display: inline">
												<button
													v-if="deletedScore"
													class="btn btn-danger"
													style="max-width: 210px; white-space: normal"
													@click="loadScoreToRubric(deletedScore)"
												>
													<div>{{ $t("Viewer.view_reset_score") }}</div>
													<div class="text-muted text-xs">
														{{
															$t("Viewer.view_reset_score_submessage", {
																date: fs.medDate(deletedScore.created_at),
															})
														}}
													</div>
												</button>

												<score
													v-if="viewableFirstScore"
													:score="viewableFirstScore"
													:rubric="item.rubric"
													class="my-2"
												/>

												<div v-if="item.show_word_count" class="card box mt-2 mb-3">
													<div class="text-center py-1 _600 b-b">
														{{ $t("fields.word_count") }}
													</div>
													<div class="px-2 py-2 text-center">
														{{ response.word_count }}
													</div>
												</div>

												<div
													v-if="
														displayStrictBlankResolutionWarning ||
														displayStrictBlankBackReadWarning
													"
												>
													<div class="box mb-0">
														<div class="box-header danger">
															<h3>{{ $t("Viewer.warning") }}</h3>
														</div>

														<div class="box-body">
															<p v-if="displayStrictBlankResolutionWarning" class="m-0">
																{{ $t("Viewer.strict_flag_resolution_warning") }}
															</p>
															<p v-if="displayStrictBlankBackReadWarning" class="m-0">
																{{ $t("Viewer.strict_flag_backread_warning") }}
															</p>
															<button
																class="btn theme-accent mt-2"
																v-if="user.role.page_mail"
																@click="$router.push('/mail')"
															>
																{{ $t("Viewer.email_page") }}
															</button>
														</div>
													</div>
												</div>

												<rubric
													:outer-rubric="item.rubric"
													:item="item"
													:chooseRes="item.resolution_choose_score && driver.chooseKeepScore"
													:initScore="initScore"
													:resFirstScore="resFirstScore"
													:resSecondScore="resSecondScore"
													:resScore="resScore"
													:brScore="brScore"
													:finalScore="finalScore"
													:showScores="showScores"
													:reset="reset"
													:score.sync="score"
													:complete.sync="rubricComplete"
													:pageNav.sync="pageNav"
													:disabled="showFlagUI && applyScoreFlagSelected"
													:chan="chan"
													:metadataKeys="user.client.metadata_keys"
													:meta="response.meta"
													:user="user"
													:keypressHandler.sync="rubricKeypressHandler"
													:mode="mode"
													:externalFinalScore="externalFinalScore"
													:filterStrictBlanks="filterStrictBlanks"
												></rubric>

												<template
													v-if="
														!showFlagUI && !(nav && (nav.responses ? initScore : nav.next))
													"
												>
													<!-- Continue in paged rubric instead of submit -->
													<template v-if="pageNav && pageNav.canGoNext">
														<button
															@click="pageNav.next"
															v-tippy="{ delay: [500, 0], placement: 'bottom' }"
															title="Next page"
															:class="{ 'faux-disabled': !pageNav.complete }"
															class="float-right mt-2 md-btn md-fab rubric-next-btn success mb-2"
														>
															<i class="material-icons pt-2">arrow_forward</i>
														</button>
													</template>

													<!-- Normal submit -->
													<template v-if="!pageNav || !pageNav.canGoNext">
														<!-- Disabled Submit w/ tooltip -->
														<div
															v-if="!allowViewedAll"
															style="height: 56px; width: 56px; border-radius: 50%"
															class="float-right mt-2 mb-2"
															v-tippy="{ placement: 'bottom' }"
															:title="$t('Viewer.must_view_whole_response')"
														>
															<button disabled class="md-btn md-fab success">
																<i
																	class="fal fa-2x fa-arrow-alt-to-bottom"
																	style="line-height: 1.5"
																></i>
															</button>
														</div>

														<!-- Submit -->
														<button
															v-if="allowViewedAll"
															@click="submit()"
															v-tippy="{ delay: [500, 0], placement: 'bottom' }"
															:title="$t('tooltip.submit_score')"
															:disabled="!canSubmit"
															class="float-right mt-2 md-btn md-fab success mb-2"
														>
															<i class="material-icons pt-2">check</i>
														</button>
													</template>

													<!-- Rubric page previous button if necessary -->
													<template v-if="pageNav && pageNav.canGoPrev">
														<button
															@click="pageNav.prev"
															v-tippy="{ delay: [500, 0], placement: 'top' }"
															:title="$t('tooltip.previous_page')"
															class="float-right mt-2 md-btn md-fab rubric-prev-btn primary mb-2 mr-1"
														>
															<i class="material-icons">arrow_back</i>
														</button>
													</template>
												</template>

												<button
													v-if="canComment && !showComment"
													@click="showComment = true"
													v-tippy="{ delay: [500, 0] }"
													:title="$t('tooltip.include_comment')"
													:disabled="!user.role.page_rf_setup && !rubricComplete"
													class="md-btn md-fab md-fab-sm warn mt-3"
												>
													<i class="fas fa-comment-plus" style="font-size: 1.5rem"></i>
												</button>
												<div v-if="showEscalate || showRFEscalate" class="d-flex flex-row">
													<button
														v-if="showEscalate"
														@click="escalate()"
														v-tippy="{ delay: [500, 0] }"
														:title="$t('tooltip.escalate_response')"
														:disabled="!rubricComplete"
														class="md-btn md-fab md-fab-sm info mt-3 mr-2"
													>
														<i class="material-icons pt-2">turned_in_not</i>
													</button>
													<button
														v-if="showRFEscalate"
														@click="sendRFEscalation()"
														v-tippy="{ delay: [500, 0] }"
														:title="$t('tooltip.add_to_rangefinding')"
														:disabled="!user.role.page_rf_setup && !rubricComplete"
														class="md-btn md-fab md-fab-sm warn mt-3"
													>
														<i
															class="fas fa-drafting-compass"
															style="font-size: 1.5rem"
														></i>
													</button>
													<br />
												</div>

												<button
													v-if="canFlag && item.alerts.length > 0 && !showFlagUI"
													@click="flag()"
													v-tippy="{ delay: [500, 0] }"
													:title="$t('tooltip.flag_response')"
													class="md-btn md-fab md-fab-sm danger my-3"
												>
													<i class="material-icons pt-2">warning</i>
												</button>

												<!-- Flag UI -->
												<div v-if="showFlagUI" class="mt-3 pb-3">
													<div>
														<div class="form-group">
															<label>{{ $t("fields.flagged_abr") }}</label>
															<v-select
																:options="alerts"
																v-model="flagAlert"
																class="bg-white"
																:searchable="false"
																@input="maybeScoreFlag"
															/>
														</div>
														<div v-if="!applyScoreFlagSelected" class="form-group">
															<label>{{ $t("Viewer.flag_reason") }}</label>
															<textarea
																class="form-control"
																rows="5"
																v-model="flagReason"
															></textarea>
														</div>
													</div>

													<button class="btn btn-primary" @click="showFlagUI = false">
														{{ $t("buttons.cancel") }}
													</button>

													<button
														v-if="applyScoreFlagSelected"
														class="btn theme float-right"
														:class="{ theme: canDoFlag }"
														:disabled="!canDoFlag"
														@click="submitFlagScore()"
													>
														<span
															v-tippy
															:title="cannotDoFlagReason"
															:key="cannotDoFlagReason"
															>{{ $t("buttons.score") }}</span
														>
													</button>
													<button
														v-else
														:class="{ theme: canDoFlag }"
														:disabled="!canDoFlag"
														class="btn btn-secondary float-right"
														@click="submitFlag()"
													>
														<span
															v-tippy
															:title="cannotDoFlagReason"
															:key="cannotDoFlagReason"
															>{{ $t("buttons.flag") }}</span
														>
													</button>

													<div
														v-if="flagAlert && flagAlert.requires_score && !canDoFlag"
														class="text-muted text-xs text-right mt-2"
													>
														{{ $t("Viewer.flag_requires_complete_score") }}
													</div>
													<div
														v-if="flagAlert && flagAlert.requires_score && canDoFlag"
														class="text-muted text-xs text-right mt-2"
													>
														{{ $t("Viewer.score_will_apply_with_flag") }}
													</div>
												</div>
											</div>
										</div>
									</template>

									<div v-if="showGoto" class="flex scroll-y" style="padding-right: 10px">
										<div v-if="showFinishSetMsg" class="box mb-0">
											<div class="box-header primary">
												<h3>{{ $t("Viewer.end_of_set") }}</h3>
											</div>
											<div class="box-body">
												<p>{{ $t("Viewer.reached_end_still_responses") }}</p>
												<p>
													{{ $t("Viewer.select_previous_score_more") }}
													<span class="_800 text-theme">{{ $t("Viewer.finish") }}</span>
													{{ $t("Viewer.set_and_view") }}
												</p>
												<p class="text-muted mb-0">{{ $t("Viewer.current_will_be_saved") }}</p>
											</div>
										</div>
										<h5 class="my-2">Go to response:</h5>
										<template v-for="(navResp, i) in nav.responses">
											<div
												v-if="navResp.id == response.id"
												:key="navResp.id"
												class="card flex-row align-items-center py-1 px-2 mb-1 nav-current"
											>
												<span class="flex">{{ i + 1 }}. {{ navResp.ref_id }}</span>
											</div>
											<div
												v-if="navResp.id != response.id"
												:key="navResp.id"
												class="card flex-row align-items-center py-1 px-2 mb-1 nav-clickable"
												@click="maybeSubmitThenNav(navResp.id)"
											>
												<span class="flex">{{ i + 1 }}. {{ navResp.ref_id }}</span>
												<i
													v-if="navResp.scored"
													class="ml-2 mr-1 fas fa-check fa-lg text-success"
													v-tippy
													:title="$t('tooltip.scored')"
												/>
												<i
													v-if="!navResp.scored && navResp.viewed"
													class="ml-2 mr-1 fas fa-eye fa-lg text-secondary text-muted"
													v-tippy
													:title="$t('tooltip.viewed')"
												/>
											</div>
										</template>
										<button
											v-if="showFinishSetMsg"
											class="btn theme float-right mt-2"
											type="link"
											@click="submitQCAndFinish"
										>
											{{ $t("buttons.finish_set") }}
										</button>
										<button
											v-if="!showFinishSetMsg"
											class="btn theme float-right mt-2"
											type="link"
											@click="finishRFSet"
										>
											{{ $t("buttons.finish_set") }}
										</button>
									</div>

									<!-- Navigation footer -->
									<div v-if="nav && !nav.responses" class="b-t b-l set-nav-footer p-2">
										<div class="_600">{{ nav.set_name }}</div>
										<hr class="my-2" />
										<div class="d-flex flex-row align-items-center">
											<div class="flex">
												{{ nav.current }} {{ $t("data_description.out_of") }} {{ nav.total }}
											</div>
											<button
												@click="maybeSubmitThenNav(nav.prev)"
												class="btn btn-sm ml-1"
												:class="{ theme: nav.prev }"
												:disabled="!nav.prev"
											>
												<i class="fas fa-arrow-left"></i>
											</button>
											<button
												@click="maybeSubmitThenNav(nav.next)"
												class="btn btn-sm ml-1"
												:class="{ theme: nav.next }"
												:disabled="!nav.next"
											>
												<i class="fas fa-arrow-right"></i>
											</button>
										</div>
									</div>

									<!-- Navigation footer, full navigation -->
									<div v-if="nav && nav.responses" class="b-t b-l set-nav-footer p-2">
										<div class="_600">{{ nav.set_name }}</div>
										<hr class="my-2" />
										<div class="d-flex flex-row align-items-center">
											<div class="flex">
												{{ nav.current }} {{ $t("data_description.out_of") }} {{ nav.total }}
											</div>
											<button
												class="btn btn-sm ml-1"
												v-tippy
												title="Go to Response"
												id="pagination-goto"
												@click="showGoto = !showGoto"
											>
												<i class="far fa-paper-plane" />
											</button>
											<button
												@click="maybeSubmitThenNav(nav.prev)"
												class="btn btn-sm ml-1"
												:class="{ theme: nav.prev }"
												:disabled="!nav.prev"
											>
												<i class="fas fa-arrow-left"></i>
											</button>
											<button
												@click="maybeSubmitThenNav(nav.next)"
												class="btn btn-sm ml-1"
												:class="{ theme: nav.next }"
												:disabled="!nav.next"
											>
												<i class="fas fa-arrow-right"></i>
											</button>
										</div>
									</div>
								</div>
							</div>

							<!-- Media failed messages -->
							<template v-if="mediaFailed">
								<div class="card p-2 mt-2">
									<span>
										<i class="text-danger fas fa-exclamation-triangle mr-1" />There was an error
										loading the response. You may want to try refreshing the page. If this behavior
										continues, please contact a supervisor. You can also set aside the response for
										review if it's preventing you from continuing scoring.</span
									>
									<button
										v-if="!refreshPushed"
										class="mt-3 btn btn-block btn-primary"
										@click="refreshPage"
									>
										<i class="fas fa-sync mr-1" />Refresh
									</button>
									<button v-else class="mt-3 btn btn-block btn-secondary" disabled>
										<i class="fas fa-sync mr-1" />Refreshing...<loading type="icon" class="ml-1" />
									</button>

									<button
										v-if="!flagging"
										class="mt-3 btn btn-block btn-danger"
										@click="submitErrorFlag"
									>
										<i class="fas fa-flag mr-1" />Withhold for Review
									</button>
									<button v-else class="mt-3 btn btn-block btn-secondary" disabled>
										<i class="fas fa-flag mr-1" />Flagging...<loading type="icon" class="ml-1" />
									</button>
								</div>
							</template>

							<!-- Validity Feedback -->
							<div v-if="showValFeedback" class="mr-2">
								<div class="box mb-0">
									<div v-if="valCorrect" class="box-header success">
										<h3>{{ $t("Viewer.correct_validity") }}</h3>
									</div>
									<div v-else class="box-header danger">
										<h3>{{ $t("Viewer.incorrect_validity") }}</h3>
									</div>
									<div class="box-body">
										<p class="m-0">{{ $t("Viewer.please_review_feedback") }}</p>
									</div>
								</div>

								<score
									:score="score"
									:rubric="item.rubric"
									:compareScore="response.true_score"
									:type="13"
									style="max-width: 460px"
									class="my-3"
								/>

								<div class="box h100">
									<div class="box-header light">
										<h3>{{ $t("QCUserSetReport.feedback") }}</h3>
									</div>
									<div class="box-body">
										<p class="m-0" v-html="response.feedback"></p>
									</div>
								</div>

								<button
									v-if="showValEndMsg"
									class="btn theme float-right mt-2"
									@click="showValFeedback = false"
								>
									{{ $t("buttons.ok") }}
								</button>
								<button v-else class="btn theme float-right mt-2" @click="qcContinue()">
									{{ $t("buttons.ok") }}
								</button>
							</div>

							<!-- Failed Validity MSG -->
							<div v-if="showValEndMsg && !showValFeedback" class="mr-2">
								<div class="box mb-0">
									<div class="box-header danger">
										<h3>{{ $t("Viewer.validity_lockout") }}</h3>
									</div>
									<div class="box-body">
										<p class="m-0">{{ $t("Viewer.fell_below_required_validity") }}</p>
										<p class="m-0">
											{{ $t("Viewer.temporarily_locked_out") }}
										</p>
									</div>
								</div>

								<button class="btn theme float-left mt-2" @click="doReturn()">
									{{ $t("buttons.continue") }}
								</button>
							</div>

							<!-- Cal Start MSG -->
							<div v-if="showCalStartMsg" class="mr-2">
								<div class="box mb-0">
									<div class="box-header success">
										<h3>{{ $t("Viewer.calibration_set") }}</h3>
									</div>

									<div class="box-body">
										<p class="m-0">
											{{ $t("Viewer.scoring_calibration_set") }}
										</p>
										<p class="m-0">{{ $t("Viewer.ok_to_continue") }}</p>
									</div>
								</div>

								<button class="btn theme float-right mt-2" @click="showCalStartMsg = false">
									{{ $t("buttons.ok") }}
								</button>
							</div>

							<!-- Cal End MSG -->
							<div v-if="showCalEndMsg" class="mr-2">
								<div class="box mb-0">
									<div v-if="calPassed" class="box-header success">
										<h3>{{ $t("Viewer.end_of_calibration") }}</h3>
									</div>
									<div v-if="!calPassed" class="box-header danger">
										<h3>
											{{
												calDeactivated
													? $t("Viewer.calibration_lockout")
													: $t("Viewer.calibration_fail")
											}}
										</h3>
									</div>
									<div v-if="calPassed" class="box-body">
										<p class="m-0">{{ $t("Viewer.successfully_scored_calibration") }}</p>
										<p v-if="calReviewable" class="m-0">
											{{ $t("Viewer.review_calibration_or_continue") }}
										</p>
										<p v-else class="m-0">
											{{ $t("Viewer.calibration_or_continue") }}
										</p>
									</div>
									<div v-if="!calPassed" class="box-body">
										<p class="m-0">
											{{ $t("Viewer.failed_calibration") }}
										</p>
										<p v-if="calDeactivated" class="m-0">
											{{ $t("Viewer.temporarily_locked_out") }}
										</p>
										<div v-else>
											<p v-if="calReviewable" class="m-0">
												{{ $t("Viewer.review_calibration_or_continue") }}
											</p>
											<p v-else class="m-0">
												{{ $t("Viewer.calibration_or_continue") }}
											</p>
										</div>
									</div>
								</div>

								<button
									v-if="
										!calDeactivated ||
										(item.calibration_config && item.calibration_config.continue_cal)
									"
									class="btn theme float-left mt-2"
									@click="qcContinue()"
								>
									Continue
								</button>
								<a
									v-if="calReviewable"
									class="btn primary float-right mt-2"
									type="link"
									:href="`#/qc_set_report/${this.response.qc_set_id}`"
									>{{ $t("buttons.review") }}</a
								>
							</div>

							<!-- Qual Start MSG -->
							<div v-if="showQualStartMsg" class="mr-2">
								<div class="box mb-0">
									<div class="box-header success">
										<h3>{{ $t("Viewer.qualification_set") }}</h3>
									</div>

									<div class="box-body">
										<p class="m-0">
											{{ $t("Viewer.scoring_qualification_set") }}
										</p>
										<p class="m-0">{{ $t("Viewer.ok_to_continue") }}</p>
									</div>
								</div>

								<button class="btn theme float-right mt-2" @click="showQualStartMsg = false">OK</button>
							</div>

							<!-- Qual End MSG -->
							<div v-if="showQualEndMsg && qualPassed" class="mr-2">
								<div class="box mb-0">
									<div v-if="qualPassed.passed" class="box-header success">
										<h3>{{ $t("Viewer.you_are_qualified") }}</h3>
									</div>
									<div v-if="qualPassed.passed" class="box-body">
										<p class="m-0">{{ $t("Viewer.you_successfully_qualified") }}</p>
										<p v-if="qualPassed.reviewable" class="m-0">
											{{ $t("Viewer.review_or_continue") }}
										</p>
										<p v-else class="m-0">
											{{ $t("Viewer.just_continue") }}
										</p>
									</div>
									<template v-if="!qualPassed.passed">
										<template v-if="!qualPassed.continue">
											<div class="box-header danger">
												<h3>{{ $t("Viewer.end_of_qualification") }}</h3>
											</div>
											<div class="box-body">
												<p class="m-0">{{ $t("Viewer.you_did_not_qualify") }}</p>
												<p v-if="qualPassed.reviewable && !qualOnlyTraining" class="m-0">
													{{ $t("Viewer.review_and_contact") }}
												</p>
												<p v-else class="m-0">
													{{ $t("Viewer.just_contact") }}
												</p>
											</div>
										</template>
										<template v-if="qualPassed.continue">
											<template v-if="qualPassed.locked">
												<div class="box-header warning">
													<h3>{{ $t("Viewer.end_of_qualification_set") }}</h3>
												</div>
												<div class="box-body">
													<p class="m-0">{{ $t("Viewer.you_are_not_qualified_yet") }}</p>
													<p v-if="qualPassed.reviewable && !qualOnlyTraining" class="m-0">
														{{ $t("Viewer.review_and_contact") }}
													</p>
													<p v-else class="m-0">
														{{ $t("Viewer.just_contact") }}
													</p>
												</div>
											</template>
											<template v-if="!qualPassed.locked">
												<div class="box-header warning">
													<h3>{{ $t("Viewer.end_of_qualification_set") }}</h3>
												</div>
												<div class="box-body">
													<p class="m-0">{{ $t("Viewer.you_are_not_qualified_yet") }}</p>
													<template v-if="qualPassed.reviewable">
														<p class="m-0">
															{{ $t("Viewer.review_and_continue") }}
														</p>
														<p class="m-0 text-muted">
															{{ $t("Viewer.return_to_qualification") }}
														</p>
													</template>
													<template v-else>
														<p class="m-0">
															{{ $t("Viewer.just_continue") }}
														</p>
													</template>
												</div>
											</template>
										</template>
									</template>
								</div>

								<template v-if="qualOnlyTraining">
									<a
										v-if="qualPassed.reviewable"
										class="btn primary text-white float-right mt-2"
										type="link"
										:href="`#/my_training/my_qc_portfolio?qc=2&sect=${response.section_id}&item=${item.id}&sq=true&ss=true&si=true`"
									>
										{{ $t("buttons.review") }}
									</a>
									<button
										v-if="!qualPassed.passed && qualPassed.continue && !qualPassed.locked"
										class="btn theme float-left mt-2"
										@click="qcContinue"
									>
										{{ $t("buttons.continue") }}
									</button>
									<button v-else class="btn theme float-left mt-2" @click="doReturn">
										{{ $t("buttons.continue") }}
									</button>
								</template>
								<template v-else>
									<button
										v-if="qualPassed.passed || (qualPassed.continue && !qualPassed.locked)"
										class="btn theme float-left mt-2"
										@click="qcContinue()"
									>
										{{ $t("buttons.continue") }}
									</button>
									<template v-if="response && item">
										<a
											v-if="qualPassed.reviewable"
											class="btn primary text-white float-right mt-2"
											type="link"
											:href="`#/my_qc_portfolio?qc=2&sect=${response.section_id}&item=${item.id}&sq=true&ss=true&si=true`"
										>
											{{ $t("buttons.review") }}
										</a>
										<a v-else class="btn primary text-white float-right mt-2" @click="doReturn">
											{{ $t("buttons.exit_scoring") }}
										</a>
									</template>
								</template>
							</div>

							<!-- Message UI -->
							<div v-if="showMessageUI" style="width: 440px; padding-bottom: 0.5rem">
								<div>
									<div class="form-group">
										<label v-if="!messageToMultiple">{{ $t("Viewer.to_user") }}</label>
										<label v-if="messageToMultiple">{{ $t("Viewer.to_users") }}</label>
										<config-user-search
											:clientID="user.client && user.client.id"
											v-model="messageToUsers"
											includeTeams
											multiple
											class="bg-white"
										/>
									</div>
									<div class="form-group">
										<label>{{ $t("Mail.subject") }}</label>
										<input v-model="messageSubject" class="form-control" />
									</div>
									<div class="form-group">
										<label>{{ $t("Mail.body") }}</label>
										<span class="checkbox float-right" v-if="user.role.mail_mark_important">
											<label class="md-check" v-tippy :title="$t('tooltip.mail_cannot_score')">
												<input v-model="messageImportant" type="checkbox" />
												<i class="theme-accent"></i> {{ $t("Mail.important") }}
											</label>
										</span>
										<RichText :text.sync="messageText"></RichText>
									</div>
								</div>
								<div class="my-3 d-flex flex-row align-items-start justify-content-between">
									<div>
										<div class="checkbox form-group">
											<label class="md-check pr-2">
												<input type="checkbox" v-model="messageIncludeResponse" />
												<i class="theme"></i> {{ $t("Mail.include_response") }}
											</label>
										</div>
										<div class="checkbox form-group" v-if="messageIncludeResponse && messageScore">
											<label class="md-check pr-2">
												<input type="checkbox" v-model="messageIncludeScore" />
												<i class="theme"></i> {{ $t("Mail.include_user_score") }}
											</label>
										</div>
										<div class="checkbox form-group" v-if="messageIncludeResponse && messageFlag">
											<label class="md-check pr-2">
												<input type="checkbox" v-model="messageIncludeFlag" />
												<i class="theme"></i> {{ $t("Mail.include_flag") }}
											</label>
										</div>
										<div
											class="checkbox form-group"
											v-if="
												messageIncludeResponse && messageIncludeScore && messageScore && score
											"
										>
											<label class="md-check pr-2">
												<input type="checkbox" v-model="messageIncludeCompScore" />
												<i class="theme"></i> {{ $t("Mail.include_res_score") }}
											</label>
										</div>
										<div
											class="checkbox form-group"
											v-if="
												user.role.request_rescores &&
												messageIncludeResponse &&
												((messageIncludeScore && messageScore && !messageFlag) ||
													(!isWithheld && messageIncludeFlag && messageFlag) ||
													(!isWithheld &&
														messageIncludeScore &&
														messageScore &&
														messageFlag) ||
													(isWithheld &&
														messageIncludeFlag &&
														messageFlag &&
														!alertRequiresScore(messageFlag.alert_id)))
											"
										>
											<label
												v-if="response.protecteds"
												class="md-check pr-2 text-muted"
												v-tippy
												:title="$t('Viewer.protected_tooltip')"
											>
												<input type="checkbox" disabled />
												<i class="theme"></i>
												<span class="text-muted">{{ $t("Mail.request_rescore") }}</span>
											</label>
											<label v-else class="md-check pr-2">
												<input type="checkbox" v-model="messageRequestRescore" />
												<i class="theme"></i> {{ $t("Mail.request_rescore") }}
											</label>
										</div>
									</div>
									<score
										style="max-width: 280px"
										v-if="messageScore && messageIncludeResponse && messageIncludeScore"
										:score="messageScore"
										:compareScore="messageIncludeCompScore && score"
										:rubric="item.rubric"
									></score>
								</div>

								<button class="btn btn-primary" @click="showMessageUI = false">
									{{ $t("buttons.cancel") }}
								</button>
								<button
									class="btn float-right"
									@click="sendMessage()"
									:disabled="!messageToAny"
									:class="{ [messageToAny ? 'theme' : 'btn-secondary']: true }"
								>
									{{ $t("buttons.send") }}
								</button>
							</div>

							<!-- Escalate UI -->
							<div v-if="showEscalateUI" class="pb-3" style="width: 440px">
								<div>
									<div class="form-group" v-if="!rubricComplete || escalateRangefinding">
										<label>{{ $t("buttons.escalate") }}:</label>
									</div>
									<div class="form-group" v-if="rubricComplete && !escalateRangefinding">
										<label>{{ $t("QCUserSetReport.feedback") }}</label>
										<RichText :text.sync="escalationFeedback" initial="" useEquationEditor="true" />
									</div>
									<div class="row m-0 mb-3">
										<div v-if="rubricComplete && !escalateRangefinding" class="col s6">
											<score
												class="mb-2"
												:rubric="item.rubric"
												:score="escalationScore"
												:type="20"
											></score>
										</div>
										<div class="col s6">
											<div v-if="user.role.can_escalate" class="checkbox form-group">
												<label
													class="md-check pr-2"
													:class="{ 'text-muted': !rubricComplete }"
													v-tippy="{ placement: 'right' }"
													:title="
														rubricComplete
															? ''
															: this.$i18n.t('Viewer.must_apply_score_first')
													"
												>
													<input
														type="checkbox"
														@change="updateEscalationSelections()"
														:disabled="!rubricComplete"
														v-model="escalateExemplar"
													/>
													<i class="theme"></i> {{ $t("Viewer.exemplar") }}
												</label>
											</div>
											<div v-if="user.role.can_escalate" class="checkbox form-group">
												<label
													class="md-check pr-2"
													:class="{ 'text-muted': !rubricComplete }"
													v-tippy="{ placement: 'right' }"
													:title="
														rubricComplete
															? ''
															: this.$i18n.t('Viewer.must_apply_score_first')
													"
												>
													<input
														type="checkbox"
														@change="updateEscalationSelections()"
														:disabled="!rubricComplete"
														v-model="escalateTraining"
													/>
													<i class="theme"></i> {{ $t("fs.practice") }}
												</label>
											</div>
											<div v-if="user.role.can_escalate" class="checkbox form-group">
												<label
													class="md-check pr-2"
													:class="{ 'text-muted': !rubricComplete }"
													v-tippy="{ placement: 'right' }"
													:title="
														rubricComplete
															? ''
															: this.$i18n.t('Viewer.must_apply_score_first')
													"
												>
													<input
														type="checkbox"
														@change="updateEscalationSelections()"
														:disabled="!rubricComplete"
														v-model="escalateQual"
													/>
													<i class="theme"></i> {{ $t("Viewer.qualification") }}
												</label>
											</div>
											<div v-if="user.role.can_escalate" class="checkbox form-group">
												<label
													class="md-check pr-2"
													:class="{ 'text-muted': !rubricComplete }"
													v-tippy="{ placement: 'right' }"
													:title="
														rubricComplete
															? ''
															: this.$i18n.t('Viewer.must_apply_score_first')
													"
												>
													<input
														type="checkbox"
														@change="updateEscalationSelections()"
														:disabled="!rubricComplete"
														v-model="escalateValidity"
													/>
													<i class="theme"></i> {{ $t("fs.validity") }}
												</label>
											</div>
											<div v-if="user.role.can_escalate" class="checkbox form-group">
												<label
													class="md-check pr-2"
													:class="{ 'text-muted': !rubricComplete }"
													v-tippy="{ placement: 'right' }"
													:title="
														rubricComplete
															? ''
															: this.$i18n.t('Viewer.must_apply_score_first')
													"
												>
													<input
														type="checkbox"
														@change="updateEscalationSelections()"
														:disabled="!rubricComplete"
														v-model="escalateCalibration"
													/>
													<i class="theme"></i> {{ $t("fs.calibration") }}
												</label>
											</div>
										</div>
									</div>
								</div>

								<button class="btn btn-primary" @click="showEscalateUI = false">
									{{ $t("buttons.cancel") }}
								</button>
								<button
									:disabled="!escalationDestinations.length"
									:class="{ theme: escalationDestinations.length }"
									class="btn btn-secondary float-right"
									@click="sendEscalation()"
								>
									{{ $t("buttons.escalate") }}
								</button>
							</div>

							<!-- QC True Score UI -->
							<div
								v-if="driver.sidebarType() == 'qc_true_score' && response"
								class="pb-2"
								style="width: 430px"
							>
								<div>
									<div class="form-group">
										<label>{{ $t("fields.feedback") }}:</label>
										<RichText
											:initial="response.feedback"
											:text.sync="qcTrueFeedback"
											useEquationEditor="true"
										/>
									</div>
									<rubric
										:outer-rubric="item.rubric"
										:reset="reset"
										:initScore="initScore"
										:score.sync="score"
										:complete.sync="rubricComplete"
										:pageNav.sync="pageNav"
										:keypressHandler.sync="rubricKeypressHandler"
										:user="user"
										:item="item"
										:filterStrictBlanks="filterStrictBlanks"
									></rubric>
								</div>

								<div v-if="pageNav" class="mx-3 my-2">
									<button
										class="btn btn-flat btn-primary"
										@click="pageNav.prev"
										:disabled="!pageNav.canGoPrev"
										v-tippy="{ placement: 'bottom', hideOnClick: false }"
										:title="$t('tooltip.previous_rubric_page')"
									>
										{{ $t("buttons.prev") }}
									</button>
									<button
										class="btn btn-flat btn-primary"
										@click="pageNav.next"
										:disabled="!pageNav.canGoNext"
										v-tippy="{ placement: 'bottom', hideOnClick: false }"
										:title="$t('tooltip.next_rubric_page')"
									>
										{{ $t("buttons.next") }}
									</button>
								</div>

								<button class="btn btn-primary mt-2" @click="doReturn()">
									{{ $t("buttons.cancel") }}
								</button>
								<button
									v-if="!showFlagUI"
									@click="submit()"
									title="Submit Score"
									:disabled="!canSubmit"
									class="btn success float-right mt-2"
								>
									{{ $t("buttons.save") }}
								</button>
							</div>

							<!-- Auditing Sidenav -->
							<div
								v-if="
									(driver.sidebarType() == 'auditing' || driver.sidebarType() == 'flag_queue') &&
									response &&
									!showMessageUI
								"
								style="width: 210px"
							>
								<h6 class="text-center mb-0 mt-2">
									<strong class="mr-1">State:</strong>
									<span
										v-if="response.state == 100"
										class="text-danger"
										v-tippy
										title="This response has been invalidated by an external system. It can no longer be scored."
										>{{ fs.responseState(response.state, $i18n) }}</span
									>
									<span v-else>{{ fs.responseState(response.state, $i18n) }}</span>
								</h6>
								<div v-if="showFlaggedForRel" class="text-center text-muted">
									<strong>{{ $t("Auditing.marked_reliability") }}</strong>
								</div>
								<div
									v-if="response.protected"
									class="text-center text-muted"
									v-tippy
									:title="$t('Viewer.protected_tooltip')"
								>
									<i class="fas fa-lock mr-1" />{{ $t("Viewer.protected") }}
								</div>
								<hr class="my-3" />

								<div v-if="isWithheld" class="btn-group btn-block w-100">
									<button @click="releaseFlag" class="btn btn-danger btn-block mx-auto">
										{{ $t("buttons.release") }}
									</button>
									<template v-if="couldScoreWithheld">
										<button
											type="button"
											class="btn btn-sm btn-danger dropdown-toggle dropdown-toggle-split"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
											style="
												border-top-right-radius: 0.25rem !important;
												border-bottom-right-radius: 0.25rem !important;
											"
										>
											<i class="far fa-check mr-2"></i>
										</button>

										<div class="dropdown-menu dropdown-menu-right">
											<a @click="scoreAndRelease" class="dropdown-item">
												<div class="_600">{{ $t("tooltip.score_and_release") }}</div>
												<div class="text-xxs text-muted dropdown-item-details pb-1">
													{{ $t("tooltip.score_and_release_details") }}
												</div>
											</a>
											<a @click="scoreAndKeepWithheld" class="dropdown-item">
												<div class="_600">{{ $t("tooltip.score_and_keep_withheld") }}</div>
												<div class="text-xxs text-muted dropdown-item-details pb-1">
													{{ $t("tooltip.score_and_keep_withheld_details") }}
												</div>
											</a>
										</div>
									</template>
								</div>

								<div v-if="hasFlag" :class="{ 'mt-3': isWithheld }">
									<div v-for="flag in flags" :key="flag.id">
										<!-- Flag object -->
										<flag
											v-if="updatingAuditFlag != flag.id"
											:flag="flag"
											:item="item"
											@delete="deleteFlag"
											@review="markFlagReviewed"
											@update="auditFlag(flag)"
											class="mb-2"
											@showAnnotations="loadScoreAnnotations(flag.id, $event)"
											:showingAnnotations="loadedAnnos == flag.id"
											@on-message="messageFlagUser"
											:message="user.role.mail_send_all"
										/>

										<!-- Edit flag -->
										<div v-if="updatingAuditFlag == flag.id" class="my-3">
											<div>
												<div>
													<label
														class="float-right"
														v-tippy
														:title="user.first_name + ' ' + user.last_name"
														>{{ user.scorer_id }}</label
													>
													<label>
														<i class="fas fa-lg fa-fw fa-flag"></i>
														{{ $t("fields.flagged_abr") }}:
													</label>
												</div>
												<div></div>
											</div>
											<div class="form-group">
												<label>Flag Code</label>
												<config-select
													:options="alertsScorable"
													v-model="flagAlert"
													class="bg-white"
													:searchable="false"
												/>
											</div>
											<div class="form-group">
												<label>{{ $t("Viewer.flag_reason") }}</label>
												<textarea class="form-control" rows="5" v-model="flagReason"></textarea>
											</div>

											<button class="btn btn-primary" @click="cancelAuditFlag">
												{{ $t("buttons.cancel") }}
											</button>
											<button class="btn theme float-right" @click="updateAuditFlag(flag)">
												{{ $t("buttons.update") }}
												<loading v-if="flag.updating" type="icon" />
											</button>
										</div>
									</div>
								</div>

								<button
									v-if="driver.sidebarType() == 'flag_queue'"
									class="btn btn-block mx-auto mt-3"
									@click="flagQueueAdvance"
									:disabled="!driver.canAdvance(flags, params)"
									:class="{
										[driver.canAdvance(flags, params) ? 'btn-success' : 'btn-secondary']: true,
									}"
								>
									{{ $t("buttons.next") }}
								</button>

								<hr v-if="hasFlag || isWithheld" class="my-3" />

								<p class="text-center text-muted" v-if="!response.scores.length">
									{{ $t("data_description.no_scores") }}
								</p>
								<template v-if="response.state != 100">
									<button
										v-if="canScore(response) && !limitScores"
										@click="auditScore()"
										class="btn theme btn-block mx-auto mb-3"
										style="width: 208x"
									>
										{{ $t("buttons.score_response") }}
										<i class="far fa-check"></i>
									</button>
									<button
										v-if="
											hasOwnScore &&
											((limitScores && user.role.reset_own_scores) || user.role.delete_data) &&
											response.state != 20 &&
											!response.protected
										"
										@click="resetScoreAndContinuePrompt(response)"
										class="btn btn-danger btn-block mx-auto mb-3"
										style="width: 208x"
									>
										{{ $t("buttons.reset_and_rescore") }}
										<i class="far fa-undo"></i>
									</button>

									<div
										v-if="response.locked && canUnlockAndScore"
										class="btn-group mb-3 btn-block"
										style="width: 208x"
									>
										<button @click="unlock()" class="btn theme btn-block mx-auto">
											{{ $t("buttons.unlock_response") }}
											<i class="far fa-fw fa-unlock"></i>
										</button>
										<button
											@click="auditScore()"
											class="btn theme"
											v-tippy
											:title="$t('tooltip.unlock_and_score')"
										>
											<i class="far fa-check"></i>
										</button>
									</div>
								</template>

								<score
									v-for="score in sortedScores"
									@on-message="messageUser"
									@on-reset="resetScorePrompt"
									:reset="
										!limitScores &&
										(user.role.delete_data || (user.role.reset_own_scores && ownScore(score, user)))
									"
									:responseProtected="response.protected"
									:resetScores="user.role.show_reset_scores ? response.reset_scores : null"
									:currentScores="user.role.show_reset_scores ? response.scores : null"
									:message="user.role.mail_send_all"
									:key="score.id"
									:score="score"
									canShowAnnotations
									:rubric="item.rubric"
									class="mb-2"
									@showAnnotations="loadScoreAnnotations(score.id, $event)"
									:showingAnnotations="loadedAnnos"
								></score>
							</div>
						</div>

						<!-- Rubric Toggle -->
						<div
							class="rubric-collapse-btn side-btn toggles-overlay"
							@click="toggleRubric()"
							id="rubric-toggle"
							:class="{ show: toggle_hit || resizing, trans: !resizing }"
							:style="{ right: rubricCollapseRight }"
						>
							<i
								v-if="!showRubric"
								v-tippy="{ placement: 'left' }"
								:title="$t('tooltip.show_scoring_controls')"
								class="material-icons"
								>check</i
							>
							<i
								v-if="showRubric"
								v-tippy="{ placement: 'left' }"
								:title="$t('tooltip.hide_scoring_controls')"
								class="material-icons"
								>keyboard_arrow_right</i
							>
						</div>

						<!-- Resources Toggle -->
						<div
							v-if="item.resources.length > 0"
							class="resource-collapse-btn side-btn toggles-overlay"
							@click="toggleResources()"
							id="resource-toggle"
							:class="{ show: toggle_hit || resizing, trans: !resizing }"
							:style="{ right: resourceCollapseRight }"
						>
							<i
								v-if="showResources && !showExemplarSets"
								v-tippy="{ placement: 'left' }"
								:title="$t('tooltip.hide_resources')"
								class="material-icons"
								>keyboard_arrow_right</i
							>
							<i
								v-if="!(showResources && !showExemplarSets)"
								v-tippy="{ placement: 'left' }"
								:title="$t('tooltip.show_item_resources')"
								class="material-icons"
								style="font-size: 20px; padding: 2px"
								>book</i
							>
						</div>

						<!-- Exemplars Toggle -->
						<div
							v-if="exemplarSets.length > 0"
							class="exemplar-collapse-btn side-btn toggles-overlay"
							@click="toggleExemplars()"
							id="exemplar-toggle"
							:class="{ show: toggle_hit || resizing, trans: !resizing }"
							:style="{ right: resourceCollapseRight }"
						>
							<i
								v-if="showResources && showExemplarSets"
								v-tippy="{ placement: 'left' }"
								:title="$t('tooltip.hide_exemplar_sets')"
								class="material-icons"
								>keyboard_arrow_right</i
							>
							<i
								v-if="!(showResources && showExemplarSets)"
								v-tippy="{ placement: 'left' }"
								:title="$t('tooltip.show_exemplar_sets')"
								class="fas fa-file-certificate"
								style="font-size: 16px; padding: 2px"
							></i>
						</div>

						<!-- Resource split resize -->
						<div
							class="resize-btn toggles-overlay"
							@mousedown="resourceResizeStart"
							@mouseup="resourceResizeEnd"
							@touchstart="resourceResizeStart"
							@touchend="resourceResizeEnd"
							id="resource-resize"
							:class="{ show: toggle_hit || resizing, trans: !resizing }"
							:style="{ right: resourceResizeRight }"
						>
							<i class="material-icons no-select">drag_indicator</i>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" id="forceSubmit" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header red">
						<h5 class="mb-0">{{ $t("Viewer.submit_set") }}</h5>
					</div>
					<div class="modal-body text-center">
						<span class="text-md">
							{{ $t("Viewer.force_submit_or_reset_score") }}
						</span>
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat btn-secondary" data-dismiss="modal">
							{{ $t("buttons.cancel") }}
						</button>
						<button class="btn btn-flat btn-danger" data-dismiss="modal" @click="deleteScoreAndNav">
							{{ $t("buttons.delete_score") }}
						</button>
						<button @click="forceSubmitAndNav" class="btn btn-flat btn-success" data-dismiss="modal">
							{{ $t("buttons.submit_set") }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<!-- Modals for Auditing functionality -->
		<div class="modal" id="resetConfirm" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header red">
						<h5 class="mb-0">{{ $t("Viewer.reset_score") }}</h5>
					</div>
					<div class="modal-body text-center" v-if="resetting_score">
						<score class="mx-auto mb-2" :score="resetting_score" :rubric="item.rubric"></score>
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat" data-dismiss="modal">{{ $t("buttons.cancel") }}</button>

						<button
							v-if="continue_to_score"
							@click="resetAndRescore(resetting_score)"
							class="btn btn-flat red"
							data-dismiss="modal"
						>
							{{ $t("buttons.reset_and_rescore") }}
						</button>
						<button
							v-else
							@click="resetScore(resetting_score)"
							class="btn btn-flat red"
							data-dismiss="modal"
						>
							{{ $t("buttons.reset") }}
						</button>
						<!-- <b-dropdown :text="$t('buttons.reset')" toggle-class="btn btn-flat red" menu-class="red">
							<b-dropdown-item @click="resetScore(resetting_score)" data-dismiss="modal">
								{{ $t("buttons.reset") }}
							</b-dropdown-item>
							<b-dropdown-item @click="resetAndRescore(resetting_score)" data-dismiss="modal">
								{{ $t("buttons.reset_and_rescore") }}
							</b-dropdown-item>
						</b-dropdown> -->
					</div>
				</div>
			</div>
		</div>

		<div class="modal" id="alreadyScoredWarning" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="mb-0">{{ $t("Viewer.warning") }}</h5>
					</div>
					<div class="modal-body text-center">
						<span class="text-md" v-if="response">
							{{ $t("Viewer.already_scored_response") }}
							<strong>{{ response.ref_id }}</strong>
						</span>
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat" data-dismiss="modal">{{ $t("buttons.cancel") }}</button>
						<button
							@click="continueAfterAlreadyScoredWarning"
							class="btn btn-flat theme"
							data-dismiss="modal"
						>
							{{ $t("buttons.score") }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<!-- <mzfooter></mzfooter> -->
	</div>
</template>

<script>
//Libraries
import _ from "lodash";
import Vue from "vue";
import BB from "bluebird";
import Notie from "../../services/NotieService";
import $ from "jquery";
import moment from "moment";

//Services
import AuthService from "@/services/AuthService";
import ScoringService from "../../services/ScoringService";
import ResponseMediaService from "../../services/ResponseMediaService";
import Utils from "../../services/Utils";
import MailService from "../../services/MailService";
import TimeoutService from "../../services/TimeoutService";
import ResourceService from "../../services/ResourceService";
import ActivityService from "../../services/ActivityService";
import RAS from "../../services/ResponseAuditService";
import fs from "../../services/FormatService";
import store from "../../services/Store";

//Components
import Rubric from "../../components/Rubric";
import Score from "../../components/Score";
import Flag from "../../components/Flag";
import Media from "../../components/viewer/Media";
import Resource from "../../components/viewer/Resource";
import ExemplarSet from "../../components/ExemplarSet";
import ExemplarNav from "../../components/ExemplarNav";
import ConfigSelect from "../../components/ConfigSelect";
import ViewerControls from "../../components/viewer/ViewerControls";
import RichText from "../../components/RichText";
import PersonalNote from "../../components/PersonalNote";
import ConfigUserSearch from "@/components/ConfigUserSearch";
import Calculator from "@/components/Calculator";
import CopyleaksResults from "@/components/CopyleaksResults";

//Scoring Drivers
import NormalScoringDriver from "../../services/ScoringDrivers/NormalScoringDriver";
import ResolutionDriver from "../../services/ScoringDrivers/ResolutionDriver";
import ReviewScoringDriver from "../../services/ScoringDrivers/ReviewScoringDriver";
import AuditDriver from "../../services/ScoringDrivers/AuditDriver";
import ScoreAuditDriver from "../../services/ScoringDrivers/ScoreAuditDriver";
import BackreadDriver from "../../services/ScoringDrivers/BackreadDriver";
import QueueBackreadDriver from "../../services/ScoringDrivers/QueueBackreadDriver";
import FlagQueueDriver from "../../services/ScoringDrivers/FlagQueueDriver";
import QCTrueScoreDriver from "../../services/ScoringDrivers/QCTrueScoreDriver";
import QCService from "../../services/QCService";
import QCConfigService from "../../services/QCConfigService";
import PracticeDriver from "../../services/ScoringDrivers/PracticeDriver";
import RFDriver from "../../services/ScoringDrivers/RFDriver";
import RFTrueScoreDriver from "../../services/ScoringDrivers/RFTrueScoreDriver";
import RescoreDriver from "../../services/ScoringDrivers/RescoreDriver";
import AdjudicationDriver from "@/services/ScoringDrivers/AdjudicationDriver";
import AppealDriver from "@/services/ScoringDrivers/AppealDriver";
import CompensationQueueTimeService from "../../services/CompensationQueueTimeService";

import {
	SCORE_TYPES,
	SCORE_MODES,
	RATE_EXCEPTION_TYPES,
	EXCEPTION_ERROR_MSGS,
	FLAG_ACTION_TYPES,
	isInvalidedScore,
} from "@/services/Constants";

const MESSAGE = "only_one_active_tab_or_window_allowed_for_training";

export default {
	name: "Viewer",
	data() {
		var should_return = false;
		if (this.$route.query.return) {
			should_return = true;
		}
		var should_keep = false;
		if (this.$route.query.keep) {
			should_keep = true;
		}
		var forced_repeat_score = false;
		if (this.$route.query.r) {
			forced_repeat_score = true;
		}
		if (this.$route.query.skipqc) {
			this.$route.params.skipqc = true;
		}

		let params = _.extend(this.$route.params, this.$route.query);

		return {
			broadcastChannel: null,
			scrCnt: 0,

			fs: fs,
			Utils: Utils,
			should_return: should_return,
			should_keep: should_keep,
			forced_repeat_score: forced_repeat_score,
			params: params,
			scoringRecord: {},

			loading: true,
			submitting: false,
			releasing: false,
			flagging: false,

			qc_type: 0,
			score_count: 0,
			reset: "",
			section: null,
			item: null,

			//Response rendering
			response0: null,
			response1: null,
			responseReady: [false, false],
			view: 0,
			buffer: 1,
			next_response: null,

			// showRubric: store.getDefault(this, "viewer.showRubric", true),
			// showResources: store.getDefault(
			// 	this,
			// 	"viewer.showResources",
			// 	false
			// ),
			showRubric: true,
			showResources: false,
			showNotes: false,
			showCalc: false,
			showPrint: false,
			splitRatio: store.getDefault(this, "viewer.splitRatio", 0.5),
			notesSplitRatio: store.getDefault(this, "viewer.notesSplitRatio", 0.2),
			showMessageUI: false,
			showEscalateUI: false,
			pickedResource: null,
			exemplarSets: [],
			showExemplarSets: false,
			pickedExemplarSet: {},
			currentExemplarPage: 1,
			numExemplarPages: 0,
			toggle_hit: false,
			resizing: false,
			relayouting: false,
			scrollWidth: 0,
			resourceScrollWidth: 0,
			viewerControlsPositionRight: false,

			score: null,
			initScore: null,
			deletedScore: null,
			resFirstScore: null,
			resSecondScore: null,
			resScore: null,
			brScore: null,
			finalScore: null,
			rubricComplete: false,
			pageNav: null,

			chan: new Vue(),

			user: this.$route.meta.user,
			mode: this.$route.params.mode,

			driver: null,

			flags: [],
			flagAlert: null,
			flagReason: "",
			showFlagUI: false,
			updatingAuditFlag: -1,

			flagReviewed: false,

			messageToUsers: null,
			messageText: "",
			messageSubject: "",
			messageImportant: false,
			messageIncludeResponse: true,
			messageIncludeScore: true,
			messageIncludeCompScore: true,
			messageIncludeFlag: true,
			messageFlag: null,
			messageRequestRescore: false,

			escalationFeedback: "",
			escalationDestinations: [],
			escalateExemplar: false,
			escalateTraining: false,
			escalateValidity: false,
			escalateQual: false,
			escalateCalibration: false,
			escalateRangefinding: false,
			escalationScore: null,
			showEscalateUI: false,

			resetting_score: null,
			continue_to_score: null,

			caching: false,
			cachedResponse: null,
			previousCached: false,

			timeoutTimer: null,
			timeout: 1000,

			qcTrueFeedback: "",
			valCorrect: false,
			showValFeedback: false,
			showValEndMsg: false,
			showCalStartMsg: false,
			showCalEndMsg: false,
			calMsgRead: false,
			showComment: false,
			initComment: null,
			comment: null,
			existingCommentID: null,

			qualMsgRead: false,
			showQualStartMsg: false,
			showQualEndMsg: false,
			scrollEnd: false,
			qualOnlyTraining: false,

			showGoto: false,
			showFinishSetMsg: false,
			doFinishSet: false,
			gotoResponse: 1,

			forceSubmitOnNav: false,
			ignoreScoreOnNav: false,

			previousAnnos: [],
			workingAnnos: [],
			loadedAnnos: "score-0",
			highlighter: null,
			annosToLoad: null,

			mediaToLoad: 0,
			mediaLoaded: 0,
			mediaFailed: 0,

			nav: null,

			oldConsole: null,

			viewerControlsKeypressHandler: null,
			rubricKeypressHandler: null,

			backReadingBlindScoring: false,
			renderExemplarSetComponentKey: 0,
			getNextTimeUnix: null,
			alreadyScoredWarningCallback: null,

			exceptionStat: {},

			viewedAll: false,
			isForTraining: false,
			externalFinalScore: null,

			refreshPushed: false,
		};
	},
	components: {
		Rubric,
		Media,
		Resource,
		ExemplarSet,
		ExemplarNav,
		Score,
		Flag,
		ViewerControls,
		RichText,
		ConfigSelect,
		ConfigUserSearch,
		PersonalNote,
		Calculator,
		CopyleaksResults,
	},
	created() {
		let _this = this;
		// define a new console
		var console = (function (oldCons) {
			return {
				log: function () {
					oldCons.log(...arguments);
					// Your code
				},
				info: function () {
					oldCons.info(...arguments);
					// Your code
				},
				warn: function () {
					oldCons.warn(...arguments);
					// Your code
				},
				error: function () {
					if (_LTracker && _LTracker.key) {
						RAS.sendPartialLog();
						_LTracker.push({
							userID: _this.user ? _this.user.id : "(unknown)",
							responseID: _this.responseID,
							msg: [...arguments],
						});
					} else {
						oldCons.warn("[Would send this error message to Loggly in non-dev environments]");
					}
					oldCons.error(...arguments);
				},
			};
		})(window.console);

		//Then redefine the old console
		this.oldConsole = window.console;
		window.console = console;

		this.timeoutTimer = setInterval(() => {
			let timeout = Math.floor(TimeoutService.getTimeout() / 1000);
			this.$set(this, "timeout", timeout);
		}, 1000);

		this.qualOnlyTraining = this.$route.query.qualonly;

		//if (this.$route.path.includes("my_training")){
		this.broadcastChannel = new BroadcastChannel("oscar_broadcast_channel");
		this.broadcastChannel.postMessage(MESSAGE);
		this.broadcastChannel.onmessage = (event) => {
			if (event.data == MESSAGE) {
				console.log(event.data, "received, Go to Landing");
				this.$router.push("/landing");
			}
		};
		//}

		this.resetView();
		BB.props({
			itemResp: ScoringService.getItem(this.params.item_id),
			exemplarResp: QCConfigService.listQCSets(this.params.section_id, this.params.item_id, 6),
		}).then((results) => {
			this.item = results.itemResp.data;
			this.item.rubric.reset = new Date().toString();
			if (!(this.item.resources && this.item.resources.length > 0)) {
				this.showResources = false;
			}

			this.exemplarSets = _.filter(results.exemplarResp.data, {
				active: true,
			});
			if (this.exemplarSets && this.exemplarSets.length > 0) {
				this.pickExemplarSet(this.exemplarSets[0]);
			}

			this.scoringRecord = {
				section_id: this.params.section_id,
				item_id: this.params.item_id,
				scoring_mode: this.mode,
				time: moment(),
				count: 0,
			};

			switch (this.mode) {
				case "SCORING":
					this.driver = NormalScoringDriver;
					break;
				case "RESOLUTION":
					this.driver = ResolutionDriver;
					break;
				case "ADJUDICATION":
					this.driver = AdjudicationDriver;
					break;
				case "APPEAL":
					this.driver = AppealDriver;
					break;
				case "REVIEW":
					this.driver = ReviewScoringDriver;
					break;
				case "BACKREAD":
					this.driver = BackreadDriver;
					break;
				case "QUEUE BACKREAD":
					this.driver = QueueBackreadDriver;
					break;
				case "AUDITING":
					this.driver = AuditDriver;
					break;
				case "SCORE_AUDITING":
					this.driver = ScoreAuditDriver;
					break;
				case "FLAG QUEUE":
					this.driver = FlagQueueDriver;
					break;
				case "QC_TRUE_SCORE":
					this.driver = QCTrueScoreDriver;
					break;
				case "PRACTICE":
					this.driver = PracticeDriver;
					break;
				case "RANGEFINDING":
					this.driver = RFDriver;
					break;
				case "RF_TRUE_SCORE":
					this.driver = RFTrueScoreDriver;
					break;
				case "RESCORE":
					this.driver = RescoreDriver;
					break;
				default:
					break;
			}
			this.getNext();

			this.$nextTick(this.viewerScrollHook);
		});
		document.addEventListener("mouseup", this.resourceResizeEnd);
		document.addEventListener("touchend", this.resourceResizeEnd);
		document.addEventListener("mouseup", this.notesResizeEnd);
		document.addEventListener("touchend", this.notesResizeEnd);
		document.addEventListener("mousemove", this.checkToggleHitbox);

		if (!this.user.role.allow_print) {
			document.oncontextmenu = new Function("return false"); // Disabling right click
		}

		this.stopResize = _.debounce(() => {
			this.resizing = false;
		}, 250);
		this.startResize = () => {
			this.resizing = true;
			this.stopResize();
		};
		window.addEventListener("resize", this.startResize);
		window.addEventListener("resize", this.viewerScrollCheck);

		window.addEventListener("keydown", this.handleKeypress);
		window.addEventListener("beforeunload", this.updateCompensationQueueTime);
		window.onpopstate = function () {
			_this.updateCompensationQueueTime();
		};

		window.addEventListener("beforeunload", this.maybeUnlockResponse);

		this.chan.$on("increment-media-to-load", this.incrementMediaToLoad);
		this.chan.$on("increment-media-loaded", this.incrementMediaLoaded);
		this.chan.$on("increment-media-failed", this.incrementMediaFailed);
		this.chan.$on("toggle-notes", this.toggleNotes);

		//Quick but inefficient, fix later
		this.scrollCheckRepeat = setInterval(this.scrollWidthCheck, 50);
		//this.fullscreenOn();

		// When annotation selection is turned on, ensure that the user is always viewing their own annotation set
		this.chan.$on("annotation-on", () => {
			this.switchToCurrentAnnos();
		});
	},

	beforeDestroy() {
		document.removeEventListener("mouseup", this.resourceResizeEnd);
		document.removeEventListener("touchend", this.resourceResizeEnd);
		document.removeEventListener("mousemove", this.checkToggleHitbox);
		if (this.scoringRecord && this.scoringRecord.count > 0) {
			ActivityService.logScoringRecord(this.scoringRecord);
		}
		window.removeEventListener("resize", this.startResize);
		window.removeEventListener("resize", this.viewerScrollCheck);

		window.removeEventListener("keydown", this.handleKeypress);
		window.removeEventListener("beforeunload", this.updateCompensationQueueTime);
		clearInterval(this.scrollCheckRepeat);
		//this.fullscreenOff();

		window.console = this.oldConsole;

		window.onpopstate = function () {};
		if (this.response) {
			this.updateCompensationQueueTime();
		}

		if (this.broadcastChannel) {
			console.log("CLOSE BROADCAST CHANNEL");
			this.broadcastChannel.close();
		}
	},

	computed: {
		viewportScrollOffset() {
			if (this.scrollWidth > 0) {
				return this.scrollWidth + 2;
			} else {
				return 0;
			}
		},
		resourceScrollOffset() {
			if (this.resourceScrollWidth > 0) {
				return this.resourceScrollWidth + 2;
			} else {
				return 0;
			}
		},

		showScores() {
			const { driver, response, item, backReadingBlindScoring } = this;
			return (
				driver.showScores(item, backReadingBlindScoring) &&
				response &&
				response.scores &&
				response.scores.length > 0
			);
		},

		showRes() {
			if (!this.driver) {
				return false;
			}
			return (
				this.showScores ||
				this.showMessageUI ||
				this.showEscalateUI ||
				this.driver.sidebarType() == "qc_true_score" ||
				this.showValFeedback ||
				this.showQualStartMsg ||
				this.showQualEndMsg ||
				this.showValEndMsg ||
				this.showCalStartMsg ||
				this.showCalEndMsg ||
				this.showFinishSetMsg ||
				this.showComment
			);
		},
		showRubricComponent() {
			return (
				this.item &&
				this.driver.sidebarType() == "normal" &&
				this.response &&
				!this.showValFeedback &&
				!this.showQualStartMsg &&
				!this.showQualEndMsg &&
				!this.showValEndMsg &&
				!this.showCalStartMsg &&
				!this.showCalEndMsg &&
				this.mediaFailed == 0
			);
		},
		showFlaggedForRel() {
			const { driver, response } = this;
			if (!(driver && response)) return false;
			return driver.showFlaggedForRel && response.reliability_score;
		},
		softHideRubricComponent() {
			return this.showMessageUI || this.showEscalateUI;
		},
		showFullDoc() {
			return (
				this.mode == "REVIEW" ||
				this.mode == "BACKREAD" ||
				this.mode == "QUEUE BACKREAD" ||
				this.mode == "AUDITING" ||
				this.mode == "SCORE_AUDITING" ||
				this.mode == "FLAG QUEUE"
			);
		},
		limitScores() {
			return (
				this.mode == "SCORE_AUDITING" &&
				this.user.role.limit_score_auditing_self &&
				!(this.user.role.page_auditing || this.user.role.page_candidate_auditing)
			);
		},
		hasOwnScore() {
			if (!this.response) return false;

			return _.find(this.response.scores, { user_id: this.user.id });
		},
		splitPercent() {
			return this.splitRatio * 100;
		},
		sidenavWidth() {
			if (this.showRes) {
				return 460;
			} else {
				return 230;
			}
		},
		sidenavOffset() {
			if (this.showRubric) {
				return this.sidenavWidth;
			} else {
				return 0;
			}
		},
		sidenavRight() {
			if (this.showRubric) {
				return 0;
			} else {
				return this.sidenavWidth * -1;
			}
		},
		viewportRight() {
			if (this.showResources) {
				let offset = (1 - this.splitRatio) * this.sidenavOffset;
				return `calc(${this.splitPercent}vw + ${offset}px)`;
			} else {
				return this.sidenavOffset + "px";
			}
		},
		viewportScrollBorderRight() {
			if (this.showResources) {
				let offset = (1 - this.splitRatio) * this.sidenavOffset + this.scrollWidth;
				return `calc(${this.splitPercent}vw + ${offset}px)`;
			} else {
				return this.sidenavOffset + this.scrollWidth + "px";
			}
		},
		resourcesRight() {
			if (this.showResources) {
				return this.sidenavOffset + "px";
			} else {
				return `${this.splitPercent * -1}vw`;
			}
		},
		resourcesScrollBorderRight() {
			if (this.showResources) {
				return this.sidenavOffset + this.resourceScrollWidth + "px";
			} else {
				return `calc(${this.splitPercent * -1}vw + ${this.resourceScrollWidth}px)`;
			}
		},
		resourcesWidth() {
			if (this.showResources) {
				let offset = this.sidenavOffset * this.splitRatio;
				return `calc(${this.splitPercent}vw - ${offset}px)`;
			} else {
				return `calc(${this.splitPercent}vw - 1px)`;
			}
		},
		rubricCollapseRight() {
			let scrollOffset = this.viewportScrollOffset;
			if (this.showResources) {
				scrollOffset = this.resourceScrollOffset;
			}
			return this.sidenavOffset + scrollOffset + "px";
		},
		resourceCollapseRight() {
			if (this.showResources) {
				let offset = (1 - this.splitRatio) * this.sidenavOffset;
				if (this.viewportScrollOffset) {
					offset = offset + this.viewportScrollOffset;
				} else {
					offset = offset + 1;
				}
				return `calc(${this.splitPercent}vw + ${offset}px)`;
			} else {
				return this.sidenavOffset + this.viewportScrollOffset + "px";
			}
		},
		resourceResizeRight() {
			if (this.showResources) {
				let offset = (1 - this.splitRatio) * this.sidenavOffset - 7;
				return `calc(${this.splitPercent}vw + ${offset}px)`;
			} else {
				return this.sidenavOffset - 7 + "px";
			}
		},
		notesSplitPercent() {
			return this.notesSplitRatio * 100;
		},
		notesBottom() {
			if (this.showNotes) {
				return `0`;
			} else {
				return `calc((100vh - 112px) * ${-1 * this.notesSplitRatio})`;
			}
		},
		notesHeight() {
			return `calc((100vh - 112px) * ${this.notesSplitRatio})`;
		},
		viewportBottom() {
			if (this.showNotes) {
				return this.notesHeight;
			} else {
				return `0`;
			}
		},
		calcRight() {
			if (this.showResources) {
				let offset = (1 - this.splitRatio) * (this.sidenavOffset + 40);
				return `calc(${this.splitPercent}vw + ${offset}px)`;
			} else {
				return this.sidenavOffset + 40 + "px";
			}
		},
		notesResizeBottom() {
			if (this.showNotes) {
				return `calc(((100vh - 112px) * ${this.notesSplitRatio}) - 7px)`;
			} else {
				return `-7px`;
			}
		},
		notesResizeLeft() {
			if (this.showResources) {
				let offset = (1 - this.splitRatio) * this.sidenavOffset;
				return `calc(((100vw - (${this.splitPercent}vw + ${offset}px)) * 0.5) - 14px)`;
			} else {
				return `calc(((100vw - ${this.sidenavOffset}px) * 0.5) - 14px)`;
			}
		},
		response() {
			return this[this.viewResponse()];
		},
		alerts() {
			return ((this.item && this.item.alerts) || []).map((alert) => ({
				label: alert.description || alert.code,
				...alert,
			}));
		},
		alertName() {
			if (this.params.alert_id) {
				let alert = _.find(this.alerts, { id: this.params.alert_id });
				if (alert) {
					return alert.label;
				}
			}

			return null;
		},
		alertsScorable() {
			let alerts = [];
			if (this.item && this.item.alerts) {
				_.each(this.item.alerts, (alert) => {
					if (!alert.admin_only || this.user.role.admin_flags) {
						alerts.push({
							id: alert.id,
							name: alert.description || alert.code,
							code: alert.code,
							requires_score: alert.requires_score,
							action: alert.action,
							disabled: alert.requires_score,
						});
					}
				});
			}
			return alerts;
		},

		canFlag() {
			return !(this.mode == "QC_TRUE_SCORE" || this.mode == "RANGEFINDING" || this.mode == "TRAINING");
		},

		canDoFlag() {
			const { flagAlert, cannotFlagDueToRequiredScore, cannotFlagDueToStrictBlank } = this;
			return flagAlert && !cannotFlagDueToRequiredScore && !cannotFlagDueToStrictBlank;
		},

		withheldFlagSelected() {
			const { flagAlert } = this;
			return flagAlert && flagAlert.action === FLAG_ACTION_TYPES.WITHHOLD.id;
		},

		applyScoreFlagSelected() {
			const { flagAlert } = this;
			return flagAlert && flagAlert.action === FLAG_ACTION_TYPES.APPLY_SCORE.id;
		},

		dontWithholdFlagSelected() {
			const { flagAlert } = this;
			return flagAlert && flagAlert.action === FLAG_ACTION_TYPES.DONT_WITHHOLD.id;
		},

		displayStrictBlankBackReadWarning() {
			const { mode, sortedScores, rubricComplete, score } = this;
			const { BACKREAD, QUEUE_BACKREAD } = SCORE_MODES;
			if (![BACKREAD.id, QUEUE_BACKREAD.id].includes(mode) || !score) return false;
			return (
				rubricComplete &&
				!this.hasStrictBlankFlag(score) &&
				sortedScores.some((score) => this.hasStrictBlankFlag(score))
			);
		},

		displayStrictBlankResolutionWarning() {
			const { mode, resFirstScore, resSecondScore } = this;
			const { RESOLUTION } = SCORE_MODES;
			if (!(resFirstScore && resSecondScore) || mode !== RESOLUTION.id) return false;
			return this.hasStrictBlankFlag(resFirstScore) != this.hasStrictBlankFlag(resSecondScore);
		},

		cannotDoFlagReason() {
			const { $i18n, cannotFlagDueToStrictBlank } = this;
			if (cannotFlagDueToStrictBlank) return $i18n.t("Viewer.strict_flag_tooltip");
			return "";
		},

		cannotFlagDueToRequiredScore() {
			const { flagAlert, rubricComplete } = this;
			if (!flagAlert) return false;
			return flagAlert.requires_score && !rubricComplete;
		},

		cannotFlagDueToStrictBlank() {
			const { flagAlert, filterStrictBlanks } = this;
			if (!(flagAlert && filterStrictBlanks)) return false;
			return flagAlert.strict_blank;
		},

		filterStrictBlanks() {
			const { wordCountIsZero, mode, isAuditing } = this;
			const { SCORING } = SCORE_MODES;
			return mode === SCORING.id && !isAuditing && !wordCountIsZero;
		},

		wordCountIsZero() {
			const { response } = this;
			if (!response) return false;
			return response.word_count === 0;
		},

		hasFlag() {
			return this.response && this.response.flags && this.response.flags.length > 0;
		},
		showEscalate() {
			return this.driver.canBeEscalated && this.user.role.can_escalate && !this.showFlagUI;
		},
		showRFEscalate() {
			return this.driver.canBeEscalated && this.user.role.page_rf_setup && !this.showFlagUI;
		},
		isWithheld() {
			return this.response && this.response.state == 20;
		},
		dirtyFlag() {
			let initId = this.response && this.response.flag_alert_id;
			let currentId = this.flagAlert && this.flagAlert.id;
			let initReason = this.response && this.response.flag_reason;
			return initId != currentId || initReason != this.flagReason;
		},
		canUnlockAndScore() {
			let complete = this.response && this.response.state == 10;
			let hasBackread = this.response && this.response.has_backread;
			return !(complete && hasBackread);
		},
		couldScoreWithheld() {
			let willBeComplete = this.response && this.response.release_state == 10;
			let hasBackread = this.response && this.response.has_backread;
			if (this.isWithheld) {
				return !(willBeComplete && hasBackread);
			} else {
				return false;
			}
		},

		messageToMultiple() {
			return this.messageToUsers && this.messageToUsers.length && this.messageToUsers.length > 1;
		},
		messageToAny() {
			return this.messageToUsers && this.messageToUsers.length && this.messageToUsers.length > 0;
		},
		messageScore() {
			if (!(this.messageToUsers && this.messageToUsers.length && this.messageToUsers.length == 1)) {
				return null;
			}
			let findUser = this.messageToUsers[0];
			let score = _.find(this.response.scores, { user_id: findUser.id });
			return score;
		},
		allowViewedAll() {
			if (!(this.item && this.item.require_view_all)) {
				return true;
			}
			return this.scrollEnd;
		},
		canComment() {
			return this.driver.canComment;
		},
		annoChoices() {
			if (!(this.previousAnnos && this.previousAnnos.length > 0)) {
				return null;
			}

			let choices = [];
			_.each(this.previousAnnos, (pa) => {
				let choice = _.cloneDeep(pa);
				choice.label = fs.scoreTypeName(pa.score_type, this.$i18n);
				choices.push(choice);
			});
			choices.push({
				score_type: 0,
				label: this.$i18n.t("Viewer.mine"),
				annotations: this.workingAnnos,
			});

			return choices;
		},
		viewableFirstScore() {
			console.log("VIEWABLE FIRST SCORE", this.item, this.item.show_first_score);
			if (!(this.item && this.item.show_first_score)) return null;
			console.log("1");
			if (this.mode != "SCORING") return null;
			console.log("2");

			let firstScore = null;
			_.each(this.response.scores, (score) => {
				console.log("try", score);
				if (score.score_type == 1) {
					firstScore = score;
				}
			});

			console.log("firstScore", firstScore);

			return firstScore;
		},

		rateExceptions() {
			const { exceptionStat } = this;
			return (exceptionStat && exceptionStat.rate_exceptions) || [];
		},

		selectBrInitScore() {
			const { backReadingBlindScoring, driver, user } = this;
			return (
				user &&
				user.role &&
				user.role.backread_auto_populate &&
				!backReadingBlindScoring &&
				driver &&
				driver.selectUserScore
			);
		},

		waitingForMedia() {
			return this.mediaToLoad > this.mediaLoaded;
		},

		canSubmit() {
			const {
				rubricComplete,
				item: { require_view_all },
				viewedAll,
				waitingForMedia,
			} = this;

			return rubricComplete && (!require_view_all || viewedAll) && !waitingForMedia;
		},

		sortedScores() {
			return (this.response.scores || []).sort((score1, score2) => {
				const score1Invalid = isInvalidedScore(score1.score_type);
				const score2Invalid = isInvalidedScore(score2.score_type);
				if (score1Invalid && !score2Invalid) {
					return 1;
				}
				if (!score1Invalid && score2Invalid) {
					return -1;
				}
				return score1.created_at - score2.created_at;
			});
		},

		showResponsesRef() {
			const {
				item: { hide_response_id },
				response,
				user: {
					role: { view_hidden_resp_id },
				},
			} = this;
			return (!hide_response_id || view_hidden_resp_id) && response && response.ref_id;
		},

		isAuditing() {
			return this.params.isAuditing === "true";
		},
	},

	watch: {},

	methods: {
		rateExceptionWarning(rateExc) {
			const { too_slow, rate_type, rate } = rateExc;
			const { $i18n } = this;
			return rate_type == RATE_EXCEPTION_TYPES.AVG_RESP_TIME.ID
				? too_slow
					? $i18n.t("Viewer.rate_exception_warning_too_slow_avg_time", { rate })
					: $i18n.t("Viewer.rate_exception_warning_too_fast_avg_time", { rate })
				: too_slow
					? $i18n.t("Viewer.rate_exception_warning_too_slow_wpm", { rate })
					: $i18n.t("Viewer.rate_exception_warning_too_fast_wpm", { rate });
		},

		updateCompensationQueueTime() {
			// Can only queue compensation if the page holds non-null references to all dependencies
			if (this.user && this.item && this.response && this.getNextTimeUnix) {
				CompensationQueueTimeService.updateCompensationQueueTime(
					this.user.id,
					this.item.id,
					this.mode,
					this.getNextTimeUnix,
					this.response.id,
					this.response.qc_type
				);
			}
		},

		async maybeUnlockResponse() {
			if (this.driver.sidebarType() == "flag_queue" && this.response) {
				await ScoringService.selfUnlockResponse(this.response.id);
			}
		},

		reRenderExemplarSetComponent() {
			this.renderExemplarSetComponentKey++;
		},

		async doReturn() {
			if (this.driver.sidebarType() == "flag_queue" && this.response) {
				console.log("IN FLAG QUEUE");
				await ScoringService.selfUnlockResponse(this.response.id);
			}

			this.$router.go(-1);
		},

		getNext() {
			this.scrCnt++;
			// debugger;
			if (this.user.client.refresh_int > 0 && this.scrCnt >= this.user.client.refresh_int) {
				location.reload(true);
				return;
			}

			this.loading = true;

			this.mediaToLoad++;
			this.driver
				.getNext(this.params)
				.then((resp) => {
					this.gotResponse(resp.data);
				})
				.catch((err) => {
					console.log(err);
					if (this.driver.onError) {
						this.driver.onError(err, this.params, this);
						return;
					}

					if (err.response.status == 404 || err.response.status == 403 || err.response.status == 412) {
						Notie.info(err.response.data.message);
						this.doReturn();
					} else if (
						[
							"User is not qualified",
							"You are not qualified to score this item.",
							"You have not completed the required training",
						].includes(err.response.data.message)
					) {
						Notie.error(err.response.data.message);
					} else if (err.response.status == 402 && err.response.data.message === "User locked out!") {
						let errMsg = err.response.data.data;
						if (errMsg === EXCEPTION_ERROR_MSGS.SCORING_RATE.id) {
							errMsg = this.$i18n.t("Viewer.rate_exception_lockout");
						}
						Notie.error("You have been locked out", errMsg);
						this.doReturn();
					} else {
						Notie.error("Failed to load response", err);
					}
				});
		},

		gotResponse(data) {
			var response = data.response;
			this.exceptionStat = data.exception_stat;
			this.next_response = data.next_response;
			this.nav = data.nav;
			if (this.nav && this.nav.responses) {
				for (let i = 0; i < this.nav.responses.length; i++) {
					if (response.id == this.nav.responses[i].id) {
						if (i - 1 >= 0) {
							this.nav.prev = this.nav.responses[i - 1].id;
						}
						if (i + 1 < this.nav.responses.length) {
							this.nav.next = this.nav.responses[i + 1].id;
						}
					}
				}
			}
			console.log("previousAnnos", this.driver.canChoosePreviousAnnotations, data.previous_annotations);
			if (this.driver.canChoosePreviousAnnotations) {
				this.previousAnnos = data.previous_annotations;
			}

			if (this[this.bufferResponse()] && this[this.bufferResponse()].id == response.id) {
				console.log("Response was prefetched, switching to it");
				this.switchBuffer();
			} else {
				console.log("Prefetch failed");
				this.responseReady[this.view] = false;
				this[this.viewResponse()] = response;
			}
			this.flags = _.cloneDeep(response.flags);
			this.qc_type = data.type;
			this.score_count = data.score_count;
			this.loading = false;

			if (response.flag_alert_id != "") {
				this.flagAlert = _.find(this.alerts, {
					id: response.flag_alert_id,
				});
				this.flagReason = response.flag_reason;
			}

			//QC true score special case
			if (response.feedback) {
				this.qcTrueFeedback = _.clone(response.feedback);
				Utils.throttleMathJAX();
			}

			if (response.qc_type == 2 && !this.qualMsgRead && this.mode != "QC_TRUE_SCORE") {
				//Show qual starting message
				this.showQualStartMsg = true;
				this.qualMsgRead = true;
			}

			if (response.qc_type == 4 && !this.calMsgRead && !data.is_blind && this.mode != "QC_TRUE_SCORE") {
				//Show cal starting message
				this.showCalStartMsg = true;
				this.calMsgRead = true;
			}

			//Load an initial score for modes that allow score pre-fill/updates
			this.initScore = null;
			if (this.driver.allowUpdateScore) {
				_.each(response.qc_scores, (qcs) => {
					if (qcs.user_id == this.user.id) {
						this.initScore = qcs;
					}
				});

				if (this.driver.canComment && this.initScore) {
					let comment = _.find(this.response.comments, {
						score_id: this.initScore.id,
					});
					if (comment) {
						this.showComment = true;
						this.initComment = comment.text;
						this.existingCommentID = comment.id;
					}
				}
			} else if (this.driver.preloadTrueScore && response.true_score) {
				this.initScore = response.true_score;
				if (this.initScore.annotations) {
					_.each(this.initScore.annotations, (anno) => {
						anno.editable = true;
					});
					this.$nextTick(() => {
						if (this.highlighter) {
							this.loadAnnotations(this.initScore.annotations);
						} else {
							console.error("No highlighter interface");
						}
					});
				}
			}

			this.deletedScore = this.getResetScore(response);

			this.backReadingBlindScoring = data.is_backreading_blind_scoring;

			//Load resolution marks for resolution scoring
			if (this.driver.chooseKeepScore && this.response.scores && this.response.scores.length >= 2) {
				const { FIRST, SECOND, RESOLUTION, BACKREAD, FINAL } = SCORE_TYPES;
				const getScoreType = (type) => this.response.scores.find(({ score_type }) => score_type === type);
				this.resFirstScore = getScoreType(FIRST.id);
				this.resSecondScore = getScoreType(SECOND.id);
				this.resScore = getScoreType(RESOLUTION.id);
				this.brScore = getScoreType(BACKREAD.id);
				this.finalScore = getScoreType(FINAL.id);
			}

			if (this.selectBrInitScore) {
				this.initScore = this.getBrInitScore();
			}

			this.reset = Utils.generateUUID();

			this.scrollEnd = false;
			const { external_final_score } = response;
			if (external_final_score && external_final_score.length > 0) {
				this.externalFinalScore = this.response.external_final_score[0];
			}
			this.getNextTimeUnix = data.get_next_time_unix;
			console.log("help");
			console.log(data);

			//Init logging this response for annotation/action auditing
			RAS.initLog(response, this.user, this.item);
			RAS.recordAction("Got response", { ref_id: response.ref_id });

			this.mediaLoaded++;
		},

		setupFinished(responseIndex) {
			this.responseReady[responseIndex] = true;
			this.$nextTick(this.viewerScrollCheck);
			// this.$forceUpdate();
			if (responseIndex == this.view) {
				this.prefetchNext();
			}
		},

		submit() {
			if (this.submitting) {
				console.log("already submitting");
				return;
			}

			if (this.qc_type != 0 && this.mode != "QC_TRUE_SCORE") {
				console.log("SUING QC");
				this.submitQC();
				return;
			}

			if (this.score.doFlag) {
				this.flagAlert = _.find(this.alerts, { id: this.score.doFlag });
				if (!this.flagAlert) {
					Notie.error(this.$i18n.t("notie.submit_score_fail"), this.$i18n.t("notie.flag_code_dne"));
				}
				this.flagReason = "";
				this.submitFlag();
				return;
			}

			let respEl = document.querySelector(".highlighter-context");
			if (respEl) {
				RAS.recordAction("Current HTML", { html: respEl.innerHTML });
			} else {
				RAS.recordAction("Current HTML", { html: "not found" });
			}

			this.submitting = true;
			this.score.user_id = this.user.id;
			let action = {};
			if (this.loadedAnnos == "score-0" && this.highlighter) {
				this.score.annotations = this.highlighter.getAnnotations();
				action.from = "getAnnotations";
				action.origNum = this.score.annotations.length;
				action.loadedAnnos = this.loadedAnnos;
			} else {
				RAS.recordAction("Submit score", { from: "workingAnnos" });
				this.score.annotations = this.workingAnnos;
				action.from = "workingAnnos";
				action.origNum = this.score.annotations.length;
				action.loadedAnnos = this.loadedAnnos;
			}
			let dedupe = _.uniqBy(this.score.annotations, "id");
			if (dedupe.length != this.score.annotations.length) {
				console.warn("Serialized highlights contained duplicate(s)", this.score.annotations, dedupe);
			}
			this.score.annotations = dedupe;
			action.dedupNum = this.score.annotations.length;
			RAS.recordAction("Submit score", action);

			if (this.mode == "RESCORE") {
				console.log("ROUTE PARAMS", this.$route.params);
				if (this.$route.params.score_id) {
					this.score.id = this.$route.params.score_id;
				}
				if (this.$route.params.flag_id) {
					this.score.rescoringFlag = true;
					this.score.id = this.$route.params.flag_id;
				}
			}

			let submitResponse = _.cloneDeep(this.response);
			submitResponse.feedback = this.qcTrueFeedback;
			this.score.get_next_time_unix = this.getNextTimeUnix;
			this.driver
				.submitScore(
					this.score,
					submitResponse,
					this.should_return && this.should_keep,
					this.forced_repeat_score
				)
				.then((resp) => {
					this.submitting = false;
					this.scoringRecord.count++;
					this.resetView();
					Notie.success(this.$i18n.t("notie.score_saved"));
					RAS.sendLog();
					if (this.should_return) {
						this.doReturn();
					} else {
						this.getNext();
					}
				})
				.catch((err) => {
					this.submitting = false;
					console.log(err);
					Notie.error(this.$i18n.t("notie.submit_score_fail"), err);
				});
		},

		forceSubmitAndNav() {
			this.forceSubmitOnNav = true;
			this.maybeSubmitThenNav(this.navingTo);
		},

		deleteScoreAndNav() {
			this.ignoreScoreOnNav = true;
			this.maybeSubmitThenNav(this.navingTo);
		},

		maybeSubmitThenNav(responseID) {
			if (!this.rubricComplete || this.ignoreScoreOnNav) {
				this.resetView();
				this.navToResponse(responseID);
				return;
			}

			let lastScore =
				this.nav.num_scored + 1 >= this.nav.total && !this.nav.scored_ids.includes(this.response.id);
			if (lastScore && !this.forceSubmitOnNav) {
				this.navingTo = responseID;
				$("#forceSubmit").modal("show");
				return;
			}

			this.submitting = true;
			this.score.user_id = this.user.id;

			//Putting the current ID on the score to submit causes it to update instead of creating a new score
			if (this.initScore && this.initScore.id) {
				this.score.id = this.initScore.id;
			}

			var comment;
			if (this.driver.canComment) {
				comment = {
					text: this.comment,
					id: this.existingCommentID,
				};
			}
			this.score.get_next_time_unix = this.getNextTimeUnix;
			QCService.submitScore(this.score, this.response, comment)
				.then((resp) => {
					this.submitting = false;
					this.resetView();
					Notie.info(this.$i18n.t("notie.score_updated"));
					RAS.sendLog();
					if (this.should_return) {
						this.doReturn();
					} else {
						this.navToResponse(responseID);
					}
				})
				.catch((err) => {
					this.submitting = false;
					console.log(err);
					Notie.error(this.$i18n.t("notie.update_score_fail"), err);
				});
		},

		navToResponse(responseID) {
			console.log("NAV TO RESPONSE", responseID);
			this.loading = true;

			this.driver
				.getResponse(this.params, responseID)
				.then((resp) => {
					this.gotResponse(resp.data);
				})
				.catch((err) => {
					if (this.driver.onError) {
						this.driver.onError(err, this.params, this);
						return;
					}

					if (err.response.status == 404 || err.response.status == 403 || err.response.status == 412) {
						Notie.info(err.response.data.message);
						this.doReturn();
					} else {
						Notie.error(err);
					}
				});
		},

		qcContinue() {
			this.resetView();
			this.getNext();
		},

		submitQC() {
			this.submitting = true;
			this.score.user_id = this.user.id;
			// if (this.highlighter) {
			// 	this.score.annotations = this.highlighter.getAnnotations();
			// } else {
			// 	console.log("No highlighter");
			// }
			let finishSet = false; //Only for rangefinding, other modes finish automatically

			if (this.mode == "RANGEFINDING" && this.nav && this.nav.responses) {
				if (this.doFinishSet) {
					finishSet = true;
				} else {
					let i = 0;
					//Check if every previous response is scored
					let allPrev = true;
					for (; i < this.nav.responses.length; i++) {
						console.log(
							"Check prev",
							i,
							this.nav.responses[i].id == this.response.id,
							this.nav.responses[i].scored
						);
						if (this.nav.responses[i].id == this.response.id) {
							break;
						}
						if (!this.nav.responses[i].scored) {
							allPrev = false;
						}
					}
					console.log("All prev:", allPrev);

					//Check if every subsequent response is scored
					i++;
					let allNext = true;
					for (; i < this.nav.responses.length; i++) {
						if (!this.nav.responses[i].scored) {
							allNext = false;
						}
					}
					console.log("All next:", allNext);

					if (allNext) {
						if (allPrev) {
							finishSet = true;
						} else {
							this.showFinishSetMsg = true;
							this.showGoto = true;
							return;
						}
					}
				}
			}

			var comment;
			if (this.driver.canComment) {
				comment = {
					text: this.comment,
					id: this.existingCommentID,
				};
			}
			this.score.get_next_time_unix = this.getNextTimeUnix;
			console.log("submit - this.getNextTimeUnix");
			console.log(this.getNextTimeUnix);
			console.log(this.score);
			//TODO Handle showing feedback
			QCService.submitScore(this.score, this.response, comment, finishSet, this.getNextTimeUnix)
				.then((resp) => {
					this.submitting = false;
					if (this.qc_type == 4) {
						if (resp.data.was_set_complete) {
							this.calPassed = resp.data.was_correct;
							this.calDeactivated = resp.data.was_deactivated;
							this.calReviewable = resp.data.reviewable;
							console.log(this.calReviewable);
							if (!(this.calPassed && resp.data.is_blind)) {
								this.showCalEndMsg = true;
								return;
							}
						}
					}

					Utils.throttleMathJAX();

					if (this.qc_type == 3) {
						this.valCorrect = resp.data.was_correct;

						if (resp.data.was_deactivated) {
							this.showValEndMsg = true;
							this.showValFeedback = resp.data.show_negative;
							return;
						}

						if (
							(resp.data.was_correct && resp.data.show_positive) ||
							(!resp.data.was_correct && resp.data.show_negative)
						) {
							if (this.response && this.response.true_score && this.response.true_score.annotations) {
								this.loadScoreAnnotations("feedback", this.response.true_score.annotations);
							}
							this.showValFeedback = true;
							return;
						}
					}
					if (this.qc_type == 2) {
						if (resp.data.was_set_complete) {
							this.qualPassed = {
								locked: resp.data.was_deactivated,
								passed: resp.data.qual_passed,
								continue: resp.data.has_next_set,
								reviewable: resp.data.reviewable,
							};
							this.showQualEndMsg = true;
							return;
						}
					}
					this.resetView();
					Notie.success(this.$i18n.t("notie.score_saved"));
					RAS.sendLog();
					if (this.should_return) {
						this.doReturn();
					} else {
						this.getNext();
					}
				})
				.catch((err) => {
					this.submitting = false;
					console.log(err);
					Notie.error(this.$i18n.t("notie.submit_score_fail"), err);
				});
		},

		flagQueueAdvance() {
			if (this.submitting) {
				return;
			}
			this.submitting = true;
			this.driver
				.advance(this.response)
				.then((resp) => {
					this.submitting = false;
					this.scoringRecord.count++;
					this.resetView();
					if (this.should_return) {
						this.doReturn();
					} else {
						this.getNext();
					}
				})
				.catch((err) => {
					this.submitting = false;
					console.log(err);
					Notie.error(this.$i18n.t("notie.advance_flag_queue_fail"), err);
				});
		},

		resetView() {
			let viewport = document.getElementById("media");
			if (viewport) {
				viewport.scrollTop = 0;
			}
			this.loading = true;
			this[this.viewResponse()] = null;
			this.showCalStartMsg = false;
			this.showCalEndMsg = false;
			this.showValFeedback = false;
			this.showQualStartMsg = false;
			this.showQualEndMsg = false;
			this.showComment = false;
			this.showFinishSetMsg = false;
			this.showGoto = false;
			this.doFinishSet = false;
			this.initComment = "";
			this.comment = "";
			this.existingCommentID = null;
			this.reset = Utils.generateUUID();
			this.forceSubmitOnNav = false;
			this.ignoreScoreOnNav = false;

			this.flagAlert = null;
			this.flagReason = "";
			this.showFlagUI = false;
			this.showPrint = false;

			this.previousAnnos = [];
			this.workingAnnos = [];
			this.loadedAnnos = "score-0";
			this.highlighter = null;
			this.annosToLoad = null;

			this.mediaToLoad = 0;
			this.mediaLoaded = 0;
			this.mediaFailed = 0;

			document.querySelector("body").focus();
		},

		toggleRubric() {
			this.showRubric = !this.showRubric;
			// store.set(this, "viewer.showRubric", this.showRubric);
			this.reloayouting = true;
			setTimeout(() => {
				this.relayouting = false;
				this.$forceUpdate;
			}, 201);
		},

		toggleResources() {
			if (!this.pickedResource && this.item.resources && this.item.resources.length > 0) {
				this.pickedResource = this.item.resources[0];
			}
			if (this.showResources && this.showExemplarSets) {
				//Switch from exemplar sets to resources
				this.showExemplarSets = false;
			} else {
				this.showExemplarSets = false;
				if (this.showRubric && this.showResources) {
					//If they are both open, just close the resources
					this.showResources = false;
				} else if (!this.showRubric && !this.showResources) {
					//If they are both closed just open the resources
					this.showResources = true;
				} else {
					//Otherwise toggle both
					this.showRubric = !this.showRubric;
					this.showResources = !this.showResources;
				}
				if (this.showResources) {
					$(".tabs-overlay").addClass("show");
				} else {
					$(".toggles-overlay").removeClass("show");
				}
			}

			// setTimeout(() => {
			// 	this.viewerScrollCheck();
			// }, 201);

			// store.set(this, "viewer.showResources", this.showResources);
			// store.set(this, "viewer.showRubric", this.showRubric);
			this.reloayouting = true;
			setTimeout(() => {
				this.relayouting = false;
				this.$forceUpdate;
			}, 201);
		},

		toggleExemplars() {
			if (this.showResources && !this.showExemplarSets) {
				//Switch from resources to exemplar sets
				this.showExemplarSets = true;
			} else {
				this.showExemplarSets = true;
				if (this.showRubric && this.showResources) {
					//If they are both open, just close the resources
					this.showResources = false;
				} else if (!this.showRubric && !this.showResources) {
					//If they are both closed just open the resources
					this.showResources = true;
				} else {
					//Otherwise toggle both
					this.showRubric = !this.showRubric;
					this.showResources = !this.showResources;
				}
				if (this.showResources) {
					$(".tabs-overlay").addClass("show");
				} else {
					$(".toggles-overlay").removeClass("show");
				}
			}

			setTimeout(() => {
				this.viewerScrollCheck, 201;
			});

			// store.set(this, "viewer.showResources", this.showResources);
			// store.set(this, "viewer.showRubric", this.showRubric);

			this.reloayouting = true;
			setTimeout(() => {
				this.relayouting = false;
				this.$forceUpdate;
			}, 201);

			if (this.renderExemplarSetComponentKey == 0) {
				this.reRenderExemplarSetComponent();
			}
		},

		openFlagModal() {},

		messageUser(score) {
			this.messageToUsers = [
				{
					id: score.user_id,
					name: score.user.full_name,
				},
			];
			this.messageSubject = "RE: " + this.response.ref_id;
			this.showMessageUI = true;
		},

		messageFlagUser(flag) {
			this.messageToUsers = [
				{
					id: flag.user_id,
					name: flag.user.full_name,
				},
			];
			this.messageFlag = flag;
			this.messageSubject = "RE: " + this.response.ref_id;
			this.showMessageUI = true;
		},

		sendMessage() {
			var message = {
				subject: this.messageSubject,
				body: this.messageText,
				important: this.messageImportant,
			};
			if (this.messageIncludeResponse) {
				message.response_id = this.response.id;
				if (this.messageIncludeScore && this.messageScore) {
					message.show_score = true;
				}
				if (this.messageIncludeCompScore && this.messageScore && this.score) {
					message.show_res_score = true;
				}
				if (this.messageIncludeScore && this.messageScore && this.messageRequestRescore) {
					message.rescore_score_id = this.messageScore.id;
				}

				if (this.messageIncludeFlag && this.messageFlag) {
					message.show_flag = true;
					message.flag_id = this.messageFlag.id;
				}
				if (this.messageIncludeFlag && this.messageFlag && this.messageRequestRescore) {
					message.rescore_flag_id = this.messageFlag.id;
				}
			}

			//Convert teams to individuals
			let seenBefore = {};
			let users = [];
			_.each(this.messageToUsers, (val) => {
				if (val.users) {
					let team = val;
					_.each(team.users, (user) => {
						if (!seenBefore[user.id]) {
							seenBefore[user.id] = true;
							users.push(user);
						}
					});
				} else {
					let user = val;
					if (!seenBefore[user.id]) {
						seenBefore[user.id] = true;
						users.push(user);
					}
				}
			});

			let advance = this.messageRequestRescore && this.mode == "QUEUE BACKREAD";
			message.unlock_response = advance;
			MailService.sendMail(message, users)
				.then(() => {
					if (this.messageFlag && !this.messageFlag.reviewed) {
						this.markFlagReviewed(this.messageFlag);
					}

					this.showMessageUI = false;
					this.messageToUsers = [];
					this.messageText = "";
					this.messageSubject = "";
					this.messageIncludeResponse = true;
					this.messageIncludeScore = true;
					this.messageInclueFlag = true;
					this.messageFlag = null;
					this.messageIncludeCompScore = true;
					this.messageRequestRescore = false;
					Notie.success(this.$i18n.t("notie.mail_sent"));

					if (advance) {
						this.getNext();
					}
				})
				.catch((err) => {
					this.showMessageUI = false;
					this.messageToUsers = [];
					this.messageText = "";
					this.messageSubject = "";
					this.messageIncludeResponse = true;
					this.messageIncludeScore = true;
					this.messageInclueFlag = true;
					this.messageFlag = null;
					this.messageIncludeCompScore = true;
					this.messageRequestRescore = false;
					Notie.error(this.$i18n.t("notie.send_mail_fail"), err);
				});
		},

		escalate() {
			this.escalationFeedback = "";
			this.escalationDestinations = [];
			this.escalateExemplar = false;
			this.escalateTraining = false;
			this.escalateValidity = false;
			this.escalateQual = false;
			this.escalateCalibration = false;
			this.escalationScore = _.clone(this.score);
			this.showEscalateUI = true;
			this.switchToCurrentAnnos();
		},

		flag() {
			this.flagAlert = null;
			this.flagReason = "";
			this.showFlagUI = true;
		},

		updateEscalationSelections() {
			this.escalationDestinations = [];
			if (this.escalateExemplar) {
				this.escalationDestinations.push(6);
			}
			if (this.escalateTraining) {
				this.escalationDestinations.push(1);
			}
			if (this.escalateQual) {
				this.escalationDestinations.push(2);
			}
			if (this.escalateValidity) {
				this.escalationDestinations.push(3);
			}
			if (this.escalateCalibration) {
				this.escalationDestinations.push(4);
			}
		},

		sendEscalation() {
			var body = {
				response_id: this.response.id,
				destinations: this.escalationDestinations,
			};

			if (this.loadedAnnos == "score-0" && this.highlighter) {
				this.escalationScore.annotations = this.highlighter.getAnnotations();
			} else {
				this.escalationScore.annotations = this.workingAnnos;
			}
			let dedupe = _.uniqBy(this.escalationScore.annotations, "id");
			if (dedupe.length != this.escalationScore.annotations.length) {
				console.warn("Serialized highlights contained duplicate(s)", this.escalationScore.annotations);
			}
			this.escalationScore.annotations = dedupe;

			//Don't include feedback and score with rangefinding
			if (!this.escalationDestinations.includes(5)) {
				body.score = this.escalationScore;
				body.feedback = this.escalationFeedback;
			}

			ScoringService.escalate(body)
				.then(() => {
					Notie.success(this.$i18n.t("notie.response_escalated"));
					this.showEscalateUI = false;
				})
				.catch((e) => {
					console.log(e);
					Notie.error(this.$i18n.t("notie.escalate_fail"));
					this.showEscalateUI = false;
				});
		},

		sendRFEscalation() {
			var body = {
				response_id: this.response.id,
				destinations: [5],
			};

			ScoringService.escalate(body)
				.then(() => {
					Notie.success(this.$i18n.t("notie.response_added_to_rangefinding"));
					this.showEscalateUI = false;
				})
				.catch((e) => {
					console.log(e);
					Notie.error(this.$i18n.t("notie.add_to_rangefinding_fail"));
					this.showEscalateUI = false;
				});
		},

		submitErrorFlag() {
			if (this.flagging) {
				return;
			}
			if (this.response.qc_type) {
				Notie.info(this.$i18n.t("notie.response_cannot_be_flagged"));
				return;
			}

			var body = {
				reason: "Response failed to load",
				alert_id: "system_error",
				response_id: this.response.id,
				get_next_time_unix: this.getNextTimeUnix,
			};

			this.$set(this, "flagging", true);
			ScoringService.flagResponse(body)
				.then((resp) => {
					this.scoringRecord.count++;
					this.resetView();
					Notie.success(this.$i18n.t("notie.response_flagged"));
					if (this.should_return) {
						this.doReturn();
					} else {
						this.getNext();
					}
					this.$set(this, "flagging", false);
				})
				.catch((err) => {
					console.log(err);
					Notie.error(this.$i18n.t("notie.flag_response_fail"), err);
					this.$set(this, "flagging", false);
				});
		},

		submitFlag() {
			if (this.flagging) {
				return;
			}
			if (this.response.qc_type) {
				Notie.info(this.$i18n.t("notie.response_cannot_be_flagged"));
				return;
			}

			let annos = [];
			if (this.loadedAnnos == "score-0" && this.highlighter) {
				annos = this.highlighter.getAnnotations();
			} else {
				annos = this.workingAnnos;
			}
			let dedupe = _.uniqBy(annos, "id");
			if (dedupe.length != annos.length) {
				console.warn("Serialized highlights contained duplicate(s)", annos);
			}
			annos = dedupe;

			var body = {
				reason: this.flagReason,
				alert_id: this.flagAlert.id,
				response_id: this.response.id,
				annotations: annos,
				get_next_time_unix: this.getNextTimeUnix,
			};

			if (this.flagAlert.requires_score && this.score) {
				this.score.response_id = this.response.id;
				this.score.get_next_time_unix = this.getNextTimeUnix;
				body.score = this.score;
				body.score.annotations = annos;
			}

			if (this.dontWithholdFlagSelected) {
				body.dont_withhold = true;
			}

			this.$set(this, "flagging", true);
			ScoringService.flagResponse(body)
				.then((resp) => {
					this.scoringRecord.count++;
					this.resetView();
					Notie.success(this.$i18n.t("notie.response_flagged"));
					if (this.should_return) {
						this.doReturn();
					} else {
						this.getNext();
					}
					this.$set(this, "flagging", false);
				})
				.catch((err) => {
					console.log(err);
					Notie.error(this.$i18n.t("notie.flag_response_fail"), err);
					this.$set(this, "flagging", false);
				});
		},

		maybeScoreFlag() {
			console.log("maybeScoreFlag");
			if (!this.applyScoreFlagSelected) {
				return;
			}

			this.reset = Utils.generateUUID();
			console.log("SCORE CHANGED");
		},

		submitFlagScore() {
			if (this.flagging) {
				return;
			}
			if (this.response.qc_type) {
				Notie.info(this.$i18n.t("notie.response_cannot_be_flagged"));
				return;
			}

			this.score = {
				trait_scores: [],
			};
			_.each(this.item.rubric.traits, (trait) => {
				this.score.trait_scores.push({
					trait_id: trait.id,
					condition: this.flagAlert.code,
					evidence: "",
					score: -1,
				});
			});

			this.submit();
		},

		prefetchNext() {
			if (!this.should_return) {
				console.log("Prefetching next response");
				this.caching = true;
				this.responseReady[this.buffer] = false;
				this[this.bufferResponse()] = this.next_response;
				this.caching = false;
			}
		},

		switchBuffer() {
			this.view = (this.view + 1) % 2;
			this.buffer = (this.buffer + 1) % 2;
			if (this.responseReady[this.view]) {
				this.prefetchNext();
			}
		},

		viewResponse() {
			return `response${this.view}`;
		},

		bufferResponse() {
			return `response${this.buffer}`;
		},

		//Auditing Stuff
		canScore() {
			return ScoringService.canScore(this.user, this.response);
		},

		auditScore() {
			if (!this.user.active) {
				Notie.error(this.$i18n.t("notie.cannot_score_response"), this.$i18n.t("notie.user_not_active"));
				return;
			}

			var scr = _.find(this.response.scores, { user_id: this.user.id });
			if (scr) {
				this.showAlreadyScoredModal(this.scoreResponse);
				return;
			}
			this.scoreResponse();
		},

		scoreResponse() {
			if (this.response.locked) {
				let keepLock = false;
				//Check if we're in a queue that wants to keep responses until some condition (flag queue scoring)
				if (this.driver.canAdvance) {
					keepLock = !this.driver.canAdvance(this.flags, this.params);
				}
				ScoringService.unlockResponse(this.response)
					.then(() => {
						ScoringService.auditScore(this.response, this.$router, keepLock);
					})
					.catch((e) => {
						Notie.error(this.$i18n.t("notie.unlock_response_fail"));
						this.getNext();
					});
			} else {
				ScoringService.auditScore(this.response, this.$router);
			}
		},

		showAlreadyScoredModal(callback) {
			$("#alreadyScoredWarning").modal("show");

			this.alreadyScoredWarningCallback = callback;
		},

		unlock() {
			ScoringService.unlockResponse(this.response)
				.then(() => {
					Notie.info(this.$i18n.t("notie.response_unlocked"));
					this.getNext();
				})
				.catch((e) => {
					Notie.error(this.$i18n.t("notie.unlock_response_fail"));
					this.getNext();
				});
		},

		resetScorePrompt(score) {
			this.resetting_score = score;
			this.continue_to_score = false;
			$("#resetConfirm").modal("show");
		},

		resetScoreAndContinuePrompt(response) {
			let ownScore = _.find(response.scores, { user_id: this.user.id });
			if (!ownScore) {
				Notie.error("Failed to reset score", "No self-score found on response");
				return;
			}
			this.resetting_score = ownScore;
			this.continue_to_score = true;
			$("#resetConfirm").modal("show");
		},

		resetScore(score) {
			ScoringService.resetScore(this.response.id, score.id)
				.then(() => {
					Notie.success(this.$i18n.t("notie.score_reset"));
					this.resetView();
					this.getNext();
				})
				.catch((e) => {
					console.log(e);
					Notie.error(this.$i18n.t("notie.score_reset_fail"));
				});
		},

		resetAndRescore(score) {
			if (!this.user.active) {
				Notie.error(this.$i18n.t("notie.cannot_score_response"), this.$i18n.t("notie.user_not_active"));
				return;
			}

			let scr = _.find(this.response.scores, (o) => {
				return o.user_id == this.user.id && o.id != score.id;
			});
			if (scr) {
				this.showAlreadyScoredModal(() => {
					this.doResetAndScore(score.id);
				});
				return;
			}

			this.doResetAndScore(score.id);
		},

		hasStrictBlankFlag(score) {
			const strictBlankCodes = (this.alerts || [])
				.filter(({ strict_blank }) => strict_blank)
				.map(({ code }) => code);
			return score.trait_scores.every(({ condition }) => strictBlankCodes.includes(condition));
		},

		// async resetAndRescore(score) {
		// 	const { response, flags, params, driver, $router } = this;

		// 	if (response.locked) {
		// 		//Check if we're in a queue that wants to keep responses until some condition (flag queue scoring)
		// 		if (driver.canAdvance) {
		// 			keepLock = !driver.canAdvance(flags, params);
		// 		}
		// 		try {
		// 			await ScoringService.unlockResponse(response);
		// 		} catch (e) {
		// 			Notie.error(this.$i18n.t("notie.unlock_response_fail"));
		// 			this.getNext();
		// 			return;
		// 		}
		// 	}
		// 	ScoringService.rescore(response, score, $router);
		// },

		doResetAndScore(scoreID) {
			ScoringService.resetScore(this.response.id, scoreID)
				.then((r) => {
					this.response.state = r.data.state;
					this.scoreResponse();
				})
				.catch((e) => {
					console.log(e);
					Notie.error(this.$i18n.t("notie.score_reset_fail"));
				});
		},

		timeoutColor(timeout) {
			let warning_time = 120;
			let danger_time = 60;
			return {
				"text-danger": timeout < danger_time,
				"text-warning": timeout < warning_time && timeout >= danger_time,
			};
		},

		viewResource(resource) {
			ResourceService.viewResource(this.item, resource);
			if (this.showResources) {
				this.toggleResources();
			}
		},

		viewExternalResource(resource) {
			window.open(`#/resource/${this.item.id}/${resource.id}`);
		},

		downloadResource(resource) {
			ResourceService.downloadResource(this.item, resource);
		},

		markFlagReviewed(flag) {
			ScoringService.markReviewed(this.response.id, flag.id, flag.reviewing)
				.then((resp) => {
					if (flag.reviewed == flag.reviewing) {
						flag.reviewed = null;
					} else {
						flag.reviewed = flag.reviewing;
					}
					this.$set(flag, "reviewing", null);
				})
				.catch((err) => {
					console.error(err);
					Notie.error(this.$i18n.t("notie.mark_flag_as_reviewed_fail"), err);
					this.$set(flag, "reviewing", null);
				});
		},

		auditFlag(flag) {
			this.updatingAuditFlag = flag.id;
			this.flagReason = flag.reason;
			this.flagAlert = _.find(this.alerts, { id: flag.alert_id });
		},

		cancelAuditFlag() {
			this.flagAlert = null;
			this.flagReason = "";
			this.updatingAuditFlag = null;
		},

		updateAuditFlag(flag) {
			if (flag.updating) {
				return;
			}

			let newFlag = {
				id: flag.id,
				reason: this.flagReason,
				alert_id: this.flagAlert && this.flagAlert.id,
			};

			this.$set(flag, "updating", true);
			ScoringService.updateFlag(this.response.id, newFlag)
				.then((resp) => {
					let i = _.findIndex(this.flags, { id: resp.data.id });
					if (i != -1) {
						this.flags.splice(i, 1, resp.data);
					}

					this.flagAlert = null;
					this.flagReason = "";
					this.updatingAuditFlag = null;
					this.$forceUpdate();
					this.$set(flag, "updating", false);
				})
				.catch(function (err) {
					console.error(err);
					Notie.error(this.$i18n.t("notie.flag_response_fail"), err);
					this.$set(flag, "updating", false);
				});
		},

		deleteFlag(flag) {
			this.$set(flag, "deleting", true);
			ScoringService.deleteFlag(this.response.id, flag.id)
				.then((resp) => {
					_.remove(this.flags, { id: flag.id });
					this.$forceUpdate();
					this.$set(flag, "deleting", false);
				})
				.catch((err) => {
					console.error(err);
					Notie.error(this.$i18n.t("notie.delete_flag_fail"), err);
					this.$set(flag, "deleting", false);
				});
		},

		releaseFlag() {
			if (this.releasing) {
				return;
			}

			this.$set(this, "releasing", true);
			ScoringService.releaseFromFlagged(this.response.id)
				.then((resp) => {
					this.getNext();
					this.$set(this, "releasing", false);
				})
				.catch((err) => {
					console.error(err);
					Notie.error(this.$i18n.t("notie.release_flag_fail"), err);
					this.$set(this, "releasing", false);
				});
		},

		scoreAndRelease() {
			if (this.releasing) {
				return;
			}

			var scr = _.find(this.response.scores, { user_id: this.user.id });
			if (scr) {
				this.showAlreadyScoredModal(this.doScoreAndRelease);
				return;
			}
			this.doScoreAndRelease();
		},

		doScoreAndRelease() {
			this.$set(this, "releasing", true);
			ScoringService.releaseFromFlagged(this.response.id)
				.then((resp) => {
					let keepLock = false;
					//Check if we're in a queue that wants to keep responses until some condition (flag queue scoring)
					if (this.driver.canAdvance) {
						keepLock = !this.driver.canAdvance(this.flags, this.params);
					}
					ScoringService.auditScore(resp.data, this.$router, keepLock, false, true);
					this.$set(this, "releasing", false);
				})
				.catch((err) => {
					console.error(err);
					Notie.error(this.$i18n.t("notie.release_flag_fail"), err);
					this.$set(this, "releasing", false);
				});
		},

		scoreAndKeepWithheld() {
			if (this.releasing) {
				return;
			}

			var scr = _.find(this.response.scores, { user_id: this.user.id });
			if (scr) {
				this.showAlreadyScoredModal(this.doScoreAndKeepWithheld);
				return;
			}
			this.doScoreAndKeepWithheld();
		},

		doScoreAndKeepWithheld() {
			this.$set(this, "releasing", true);
			ScoringService.releaseToRewithhold(this.response.id)
				.then((resp) => {
					let keepLock = false;
					//Check if we're in a queue that wants to keep responses until some condition (flag queue scoring)
					if (this.driver.canAdvance) {
						keepLock = !this.driver.canAdvance(this.flags, this.params);
					}
					ScoringService.auditScore(resp.data, this.$router, keepLock, false);
					this.$set(this, "releasing", false);
				})
				.catch((err) => {
					console.error(err);
					Notie.error(this.$i18n.t("notie.release_flag_fail"), err);
					this.$set(this, "releasing", false);
				});
		},

		pickResource(resource) {
			this.pickedResource = resource;
		},

		pickExemplarSet(set) {
			QCConfigService.listSetResponses(set.id).then((resp) => {
				set.responses = resp.data;
				this.pickedExemplarSet = set;
			});
		},

		resourceResizeCalculate(event) {
			let x = event.pageX;
			if (event.touches && event.touches[0]) {
				x = event.touches[0].clientX;
			}
			let viewportWidth = window.innerWidth - this.sidenavOffset;
			let splitRatio = x / viewportWidth;
			if (splitRatio < 0.25) {
				splitRatio = 0.25;
			}
			if (splitRatio > 0.75) {
				splitRatio = 0.75;
			}
			this.splitRatio = 1 - splitRatio;

			this.viewerScrollCheck();
		},

		resourceResizeStart(event) {
			document.addEventListener("mousemove", this.resourceResizeCalculate);
			document.addEventListener("touchmove", this.resourceResizeCalculate);
			this.resizing = true;
		},

		resourceResizeEnd() {
			document.removeEventListener("mousemove", this.resourceResizeCalculate);
			document.removeEventListener("touchmove", this.resourceResizeCalculate);
			this.resizing = false;
			store.set(this, "viewer.splitRatio", this.splitRatio);
		},

		notesResizeCalculate(event) {
			let y = event.pageY;
			if (event.touches && event.touches[0]) {
				x = event.touches[0].clientY;
			}
			let viewportHeight = window.innerHeight - 112;
			let splitRatio = (y - 112) / viewportHeight;
			if (splitRatio < 0.1) {
				splitRatio = 0.1;
			}
			if (splitRatio > 0.9) {
				splitRatio = 0.9;
			}
			this.notesSplitRatio = 1 - splitRatio;
			store.set(this, "viewer.notesSplitRatio", this.notesSplitRatio);

			this.viewerScrollCheck();
		},

		notesResizeStart(event) {
			document.addEventListener("mousemove", this.notesResizeCalculate);
			document.addEventListener("touchmove", this.notesResizeCalculate);
			this.resizing = true;
		},

		notesResizeEnd() {
			document.removeEventListener("mousemove", this.notesResizeCalculate);
			document.removeEventListener("touchmove", this.notesResizeCalculate);
			this.resizing = false;
			store.set(this, "viewer.splitRatio", this.splitRatio);
		},

		checkToggleHitbox(event) {
			let x = event.pageX;
			let y = event.pageY;
			if (event.touches && event.touches[0]) {
				x = event.touches[0].clientX;
				y = event.touches[0].clientY;
			}

			let sidenavAndResources = this.sidenavOffset;
			let notes = 0;
			if (this.showResources) {
				let viewportWidth = window.innerWidth - this.sidenavOffset;
				sidenavAndResources += viewportWidth * this.splitRatio;
			}
			if (this.showNotes) {
				let viewportHeight = window.innerHeight - 112;
				notes += viewportHeight * this.notesSplitRatio;
			}

			let xThreshold = window.innerWidth - (sidenavAndResources + 50);
			let yThreshold = window.innerHeight - (notes + 50);

			if (x > xThreshold || y > yThreshold) {
				this.toggle_hit = true;
			} else {
				this.toggle_hit = false;
			}
		},

		viewerScrollHook() {
			let viewer = document.getElementById("media");
			if (!viewer) {
				//Shouldn't happen, but it does
				return;
			}
			viewer.addEventListener("scroll", this.viewerScrollCheck);
			//Need to do one initial check in case the div isn't scrollable to begin with
			this.viewerScrollCheck();
		},

		viewerScrollCheck() {
			let viewer = document.getElementById("media");
			if (!viewer) {
				//Shouldn't happen, but it does
				return;
			}

			//Check if scrolled to end
			if (viewer.scrollHeight - (viewer.scrollTop + viewer.clientHeight) < 55) {
				this.scrollEnd = true;
			}
		},

		scrollWidthCheck() {
			let viewer = document.getElementById("media");
			let newVal = 0;
			let oldVal = 0;
			if (viewer) {
				//Update scrollbar width
				let newVal = viewer.offsetWidth - viewer.clientWidth;
				let oldVal = this.scrollWidth;
				this.scrollWidth = newVal;
			}

			let resources = document.getElementById("resources-container");
			if (this.showExemplarSets) {
				resources = document.getElementById("exemplar-container");
			}
			let rNewVal = 0;
			let rOldVal = 0;
			if (resources) {
				//Update resource scrollbar width
				rNewVal = resources.offsetWidth - resources.clientWidth;
				rOldVal = this.resourceScrollWidth;
				this.resourceScrollWidth = rNewVal;
			}

			if (newVal != oldVal || rNewVal != rOldVal) {
				this.$forceUpdate();
			}
		},

		incrementMediaToLoad() {
			this.mediaToLoad++;
		},

		incrementMediaLoaded() {
			this.mediaLoaded++;
		},

		incrementMediaFailed() {
			this.mediaFailed++;
		},

		toggleNotes() {
			this.showNotes = !this.showNotes;
		},

		toggleCalc() {
			this.showCalc = !this.showCalc;
		},

		downloadMedia() {},

		print(response) {
			if (response.media.length <= 1) {
				this.printWholeResponse(response);
			} else {
				this.showPrint = !this.showPrint;
			}
		},

		printStyled() {
			let el = document.querySelector(".showing-media");
			if (!el) {
				console.log("Could not find media element");
				Notie.error("Failed to print page", "Page not found");
				return;
			}
			Utils.printElement(el);
		},

		printWholeResponse(response) {
			var windowRef = window.open();
			if (response.qc_type) {
				windowRef.location = `/v1/print/qc/${response.id}`;
			} else {
				windowRef.location = `/v1/print/response/${response.id}`;
			}
		},

		downloadResponse(response) {
			ResponseMediaService.downloadResponse(this.item, response);
		},

		scrolldown() {
			$("#media")
				.stop()
				.animate(
					{
						scrollTop: $("#media")[0].scrollHeight,
					},
					500
				);
		},

		isFullscreen() {
			var doc = window.document;

			if (
				!doc.fullscreenElement &&
				!doc.mozFullScreenElement &&
				!doc.webkitFullscreenElement &&
				!doc.msFullscreenElement
			) {
				return false;
			} else {
				return true;
			}
		},

		fullscreenOn() {
			var doc = window.document;
			var docEl = doc.documentElement;

			var requestFullScreen =
				docEl.requestFullscreen ||
				docEl.mozRequestFullScreen ||
				docEl.webkitRequestFullScreen ||
				docEl.msRequestFullscreen;
			requestFullScreen.call(docEl);
		},

		fullscreenOff() {
			var doc = window.document;

			var cancelFullScreen =
				doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;
			cancelFullScreen.call(doc);
		},

		doGotoResponse() {
			if (!this.nav && this.nav.responses) {
				return;
			}
			this.showGoto = false;
			if (this.gotoResponse < 1) {
				this.gotoResponse = 1;
			}
			if (this.gotoResponse > this.totalRows) {
				this.gotoResponse = this.totalRows;
			}
			this.$emit("input", this.gotoPage);
		},

		finishRFSet() {
			if (!this.response.qc_set_id) {
				Notie.error(this.$i18n.t("notie.finish_rf_set_fail"), this.$i18n.t("notie.set_id_not_found"));
				return;
			}
			QCService.finishRFSet(this.response.qc_set_id)
				.then((r) => {
					this.$router.push(`/rf_set_report/${this.response.qc_set_id}`);
				})
				.catch((e) => {
					console.log(e);
					Notie.error(this.$i18n.t("notie.finish_rf_set_fail"), e);
				});
		},

		submitQCAndFinish() {
			this.doFinishSet = true;
			this.submitQC();
		},

		goBackRF() {
			if (!(this.nav && this.nav.responses)) {
				Notie.error(this.$i18n.t("notie.navigation_error"));
				return;
			}
			for (let i = 0; i < this.nav.responses.length; i++) {
				let navResp = this.nav.responses[i];
				if (!navResp.scored) {
					this.maybeSubmitThenNav(navResp.id);
					return;
				}
			}
		},

		clearTextSelection() {
			let sel = window.getSelection ? window.getSelection() : document.selection;
			if (sel) {
				if (sel.removeAllRanges) {
					sel.removeAllRanges();
				} else if (sel.empty) {
					sel.empty();
				}
			}
		},

		loadAnnotations(annos) {
			if (!this.highlighter) {
				Notie.error("Can't load annotations", "An error occurred");
				return;
			}
			this.highlighter.setAnnotations(annos);
		},

		clickAnnotationChoice(choice) {
			RAS.recordAction("Click button", { button: "Annotation choice", choice: choice.label });

			this.loadAnnotationChoice(choice);
		},

		loadAnnotationChoice(choice) {
			// Save the set of working annotations if we're currently selecting away from "mine"
			if (this.loadedAnnos == "score-0" && this.highlighter) {
				this.workingAnnos = this.highlighter.getAnnotations();
			}
			this.chan.$emit("annotation-interrupt", true);
			this.loadAnnotations(choice.annotations);
			this.loadedAnnos = `score-${choice.score_type}`;
		},

		loadScoreAnnotations(id, annos) {
			if (annos.length == 0 && this.loadedAnnos != "score-0") {
				id = "score-0";
				annos = this.workingAnnos;
			} else if (this.loadedAnnos == "score-0") {
				this.workingAnnos = this.highlighter.getAnnotations();
			}

			this.loadAnnotations(annos);
			if (annos.length) {
				this.loadedAnnos = id;
			} else {
				this.loadedAnnos = null;
			}
		},

		switchToCurrentAnnos() {
			const { annoChoices, mode } = this;
			const { SCORING } = SCORE_MODES;
			if (!annoChoices && mode !== SCORING.id) {
				this.loadedAnnos = `score-0`;
			}

			let mine = _.find(annoChoices, { label: "Mine" });
			if (!mine) return;

			if (this.loadedAnnos != "score-0") {
				this.clearTextSelection();
				this.loadAnnotationChoice(mine);
			}
		},

		ownScore(score, user) {
			return score.user_id == user.id;
		},

		getResetScore(response) {
			let previousScore = null;
			_.each(response.reset_scores, (rs) => {
				if (rs.user_id == this.user.id) {
					if (!previousScore) {
						previousScore = rs;
					} else {
						let prevScoreTime = moment(previousScore.created_at);
						let newScoreTime = moment(rs.created_at);
						if (newScoreTime.isAfter(prevScoreTime)) {
							previousScore = rs;
						}
					}
				}
			});

			return previousScore;
		},

		loadScoreToRubric(score) {
			this.initScore = score;
			this.reset = Utils.generateUUID();
			this.deletedScore = null;
		},

		alertRequiresScore(alertID) {
			let alert = _.find(this.item.alerts, { id: alertID });
			if (alert && alert.requires_score) {
				return true;
			}
			return false;
		},

		handleKeypress(e) {
			console.log("keypress", e.keyCode);
			if (e.keyCode == 18) return; // Alt key press

			if (e.altKey == true && this.viewerControlsKeypressHandler) {
				this.viewerControlsKeypressHandler(e);
			} else if (this.rubricKeypressHandler) {
				this.rubricKeypressHandler(e);
			}
		},

		anyCopyleaksResults(response) {
			for (let media of response.media) {
				if (media.copyleaks) {
					return true;
				}
			}
			return false;
		},

		getHighestCopyleaksScore(response) {
			let anyResults = false;
			let highest = 0;
			for (let media of response.media) {
				if (media.copyleaks) {
					if (media.copyleaks.status >= 4 && media.copyleaks.match_percent > highest) {
						anyResults = true;
						highest = Math.round(media.copyleaks.match_percent);
					}
				}
			}
			if (!anyResults) {
				return "?";
			}
			return highest;
		},

		getBrInitScore() {
			const {
				response,
				$route: {
					params: { queue_user_id },
				},
			} = this;
			if (!(response && queue_user_id)) return;
			return (response.scores || []).find(({ user_id }) => user_id === queue_user_id);
		},

		continueAfterAlreadyScoredWarning() {
			if (!this.alreadyScoredWarningCallback) {
				Notie.error("No next action defined");
			}

			this.alreadyScoredWarningCallback();
		},

		refreshPage() {
			this.refreshPushed = true;
			window.location.reload(true);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.rubric-col {
	background-color: white;
	width: 230px;
}

.viewer-container {
	border-top: 1px solid #d9d9d9;
	position: fixed;
	top: 112px;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #f5f5f5;
}

/* Default state, viewer is full width, rubric and resources are right:(their width) */

.viewer-viewport {
	position: absolute;
	display: inline;
	overflow-y: auto;
	overflow-x: auto;
	left: 0;
	top: 0;
	z-index: 1;
	/* background-color: #f5f5f5; */
}

.faux-scroll-border {
	position: fixed;
	top: 113px;
	width: 3px;
	background-color: #eeeeee;
	border-left: 1px solid rgba(120, 130, 140, 0.13);
	pointer-events: none;
	z-index: 1;
}

.viewport-border {
	margin-right: 2px;
	border-right: 1px solid rgba(120, 130, 140, 0.13);
}

.viewport-media {
	margin-bottom: 20px;
}

.resources {
	position: absolute;
	height: 100%;
	bottom: 0;
	top: 0;
	width: calc(50vw);
	background-color: #eeeeee;
	border-left: 1px solid #d9d9d9;
	box-shadow: inset 6px 0px 10px -4px rgba(184, 182, 184, 1);
}

.resources > .resources-container {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
}

.resources-container {
	z-index: 2;
}

.viewer-sidenav {
	position: absolute;
	width: 230px;
	height: 100%;
	bottom: 0;
	right: -230px;
	top: 0;
	padding-left: 10px;
	padding-right: 0px;
	padding-top: 5px;

	overflow: auto;
	background-color: #eeeeee;
}

.side-btn {
	height: 25px;
	width: 25px;
	background-color: #eeeeee;
	border-left: 1px solid #d9d9d9;
	border-top: 1px solid #d9d9d9;
	border-bottom: 1px solid #d9d9d9;
	-webkit-border-top-left-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-topleft: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	position: absolute;
	cursor: pointer;
	z-index: 1000000000000000;
}

.bottom-btn {
	margin-bottom: -1px;
	margin-right: 25px;
	height: 25px;
	width: 25px;
	background-color: #eeeeee;
	border-left: 1px solid #d9d9d9;
	border-right: 1px solid #d9d9d9;
	border-top: 1px solid #d9d9d9;
	-webkit-border-top-left-radius: 5px;
	-webkit-border-top-right-radius: 5px;
	-moz-border-radius-topleft: 5px;
	-moz-border-radius-topright: 5px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	position: absolute;
	cursor: pointer;
	z-index: 19;
}

.resize-btn {
	height: 28px;
	top: calc(50% - 14px);
	width: 12px;
	background-color: #eeeeee;
	border-left: 1px solid #d9d9d9;
	border-right: 1px solid #d9d9d9;
	border-top: 1px solid #d9d9d9;
	border-bottom: 1px solid #d9d9d9;
	-webkit-border-top-left-radius: 3px;
	-webkit-border-bottom-left-radius: 3px;
	-webkit-border-top-right-radius: 3px;
	-webkit-border-bottom-right-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-bottomleft: 3px;
	-moz-border-radius-topright: 3px;
	-moz-border-radius-bottomright: 3px;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	position: absolute;
	cursor: grab;
	z-index: 1000000000000000;
	visibility: hidden;
}

.resize-btn > i {
	font-size: 16px;
	margin-left: -3px;
	line-height: 26px;
}

.show-resources > .resize-btn {
	visibility: visible;
}

.resize-btn-h {
	height: 12px;
	left: calc(50% - 14px);
	width: 28px;
	background-color: #eeeeee;
	border-left: 1px solid #d9d9d9;
	border-right: 1px solid #d9d9d9;
	border-top: 1px solid #d9d9d9;
	border-bottom: 1px solid #d9d9d9;
	-webkit-border-top-left-radius: 3px;
	-webkit-border-bottom-left-radius: 3px;
	-webkit-border-top-right-radius: 3px;
	-webkit-border-bottom-right-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-bottomleft: 3px;
	-moz-border-radius-topright: 3px;
	-moz-border-radius-bottomright: 3px;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	position: absolute;
	cursor: grab;
	z-index: 1000000000000000;
	visibility: hidden;
}

.resize-btn-h > i {
	font-size: 16px;
	margin-left: 5px;
	margin-top: -3px;
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
	position: absolute;
}

.show-notes > .resize-btn-h {
	visibility: visible;
}

.rubric-collapse-btn {
	right: 0;
	top: 10px;
}

.resource-collapse-btn {
	right: 0;
	top: 42px;
}

.exemplar-collapse-btn {
	right: 0;
	top: 74px;
}

.toggles-hitbox {
	position: absolute;
	right: 0;
	top: 0;
	width: 45px;
	height: 100%;
	opacity: 0;
	/* This was breaking the scrollbar */
	/* z-index: 1000000000000001; */
	z-index: -1;
}

.notes-container {
	position: absolute;
	height: 100%;
	left: 0;
	height: 200px;
	max-height: calc(100vh - 112px);

	border-top: 1px solid #d9d9d9;
	background-color: #eeeeee;
	box-shadow: inset 0px -6px 10px -4px rgba(184, 182, 184, 1);
}

/* When rubric is showing */

/* .show-sidenav > .viewer-sidenav {
	right: 0;
}
.show-sidenav.show-res > .viewer-sidenav {
	width: 460px;
}
.viewer-sidenav.show-res {
	bottom: 0;
	right: -460px;
} */

.trans {
	-webkit-transition:
		all 200ms cubic-bezier(0.25, 0.1, 0.25, 1),
		opacity 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
	-moz-transition:
		all 200ms cubic-bezier(0.25, 0.1, 0.25, 1),
		opacity 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
	-o-transition:
		all 200ms cubic-bezier(0.25, 0.1, 0.25, 1),
		opacity 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
	transition:
		all 200ms cubic-bezier(0.25, 0.1, 0.25, 1),
		opacity 500ms cubic-bezier(0.25, 0.1, 0.25, 1);

	-webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
	-moz-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
	-o-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
	transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.viewer-viewport::-webkit-scrollbar,
.resources-container::-webkit-scrollbar {
	position: absolute;
	z-index: -1;
	width: 13px;
	height: 13px;
}

.viewer-viewport::-webkit-scrollbar-thumb,
.resources-container::-webkit-scrollbar-thumb {
	border-radius: 10px;
}

.viewer-viewport > .essay,
.resources > .essay {
	margin-left: auto;
	margin-right: auto;
	/*margin-bottom: 70px;*/

	border: none;
}

.padding-fix-for-shadows {
	margin-left: -10px;
	padding-left: 10px;
}

/* .prevScores {
	overflow: scroll;
	margin-top: 20px;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	max-width: 800px;
	width: 80%;

	padding: 10px;
	-webkit-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	-moz-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	background-color: white;
} */
.prevScores {
	/* position: absolute;
	top: 10px;
	left: 10px; */
}

/*background-color: #78909c;*/
/* } */

.viewer-viewport::-webkit-scrollbar {
	background-color: #eeeeee;
}

::-webkit-scrollbar {
	width: 7px;
}

::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.5);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
	padding-left: 4px;
}

::-webkit-scrollbar-corner {
	background-color: #eeeeee;
}

.loader {
	font-size: 128px;
	text-align: center;
}

.md-fab-sm {
	height: 40px !important;
	width: 40px !important;
	padding: 0px;
}

.exit-button {
	margin: -8px -16px;
	width: 56px;
	height: 56px;
	text-align: center;
	cursor: pointer;
}

.exit-button:hover,
.exit-button.show-print {
	background-color: rgba(0, 0, 0, 0.075);
}

.exit-button a {
	line-height: 56px;
}

.slow-color {
	transition: color 10s;
}

.dropdown-toggle {
	border-top-right-radius: 4px !important;
	border-bottom-right-radius: 4px !important;
}

.r-controls-overlay {
	/* height: 0; */
	position: absolute;
	left: 20px;
	top: 20px;
	opacity: 0;
	z-index: 20;
}

.e-controls-overlay {
	/* height: 0; */
	position: absolute;
	left: 10px;
	top: 60px;
	opacity: 0;
	z-index: 20;
}

.resources:hover .r-controls-overlay,
.resources:hover .e-controls-overlay {
	opacity: 1;
	height: auto;
}

.r-controls-overlay button,
.e-controls-overlay button {
	display: block;
	font-size: 20px;
	line-height: 2;
}

.r-controls-overlay .dropdown-item.active,
.e-controls-overlay .dropdown-item.active {
	color: inherit;
	background-color: rgba(0, 0, 0, 0.1);
}

.controls-overlay {
	opacity: 0;
	z-index: 20;
}

.viewer-viewport:hover ~ .controls-overlay,
.controls-overlay:hover {
	opacity: 1;
}

.toggles-overlay {
	opacity: 0;
}

.viewer-sidenav:hover ~ .toggles-overlay,
.resources:hover ~ .toggles-overlay,
.viewer-container:not(.show-sidenav):not(.show-resources) .toggles-overlay,
.toggles-overlay.show {
	opacity: 1;
}

@media (hover: none) {
	.toggles-overlay,
	.controls-overlay,
	.tabs-overlay {
		opacity: 1;
	}
}

/* .nav-tabs {
	flex-wrap: nowrap;
} */
.nav-item {
	background-color: #ffffff;
	min-width: 50px;
	overflow: hidden;
	white-space: nowrap;
	border-bottom: 1px solid rgba(120, 130, 140, 0.13);
	border-right: 1px solid rgba(120, 130, 140, 0.13);
	flex: 1 1 50px;
	text-align: center;
}

.nav-item > a {
	min-width: 0;
	overflow: hidden;
	text-overflow: ellipsis;
}

.set-nav-footer {
	margin-left: -8px;
	/* margin-right: -10px; */
	background-color: rgb(231, 231, 231);
	border-top-left-radius: 0.5rem;
}

.calc-box {
	padding-top: auto;
	position: absolute;
	top: 0px;
	pointer-events: none;
	z-index: 2;
}

.nav-current {
	border: 3px solid lightblue;
}

.nav-clickable {
	cursor: pointer;
}

.nav-clickable:hover {
	background-color: #eeeeee;
}

.nav-clickable:active {
	box-shadow: 0 0 0 2px lightblue inset;
}

.rubric-next-btn.faux-disabled {
	color: rgba(0, 0, 0, 0.26);
	background-color: transparent;
}

.rubric-prev-btn {
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
}

.rubric-prev-btn > i {
	font-size: 18px;
	margin-top: -3px;
}

.md-btn.md-fab:not([disabled]):focus {
	outline: 2px solid rgba(81, 203, 238, 1) !important;
}

.copyleaks-icon {
	width: 45px;
	height: 45px;
	border: 4px solid #3f97f5;
	border-radius: 50%;
	font-weight: 800;
	line-height: 14px;
}
</style>
