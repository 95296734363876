"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseAndProcessCSV = exports.VALID_QC_TYPES = exports.processScoresCSV = void 0;
var wasm_utils_js_1 = require("../wasm/pkg/wasm_utils.js");
var WASMUtils_js_1 = require("./WASMUtils.js");
function processScoresCSV(scoreCsv, scoresOnly, skipHeaderLine) {
    var output = {
        requests: [],
        messages: [],
        num: 0,
        proceed: false,
    };
    var anyErrors = false;
    if (skipHeaderLine) {
        if (scoreCsv.length == 1) {
            output.messages.push({
                message: "Could not parse input CSV",
                result: "",
                type: "error",
            });
            anyErrors = true;
        }
    }
    else {
        if (scoreCsv.length == 0) {
            output.messages.push({
                message: "Could not parse input CSV",
                result: "",
                type: "error",
            });
            anyErrors = true;
        }
    }
    // Ignore the first row, it should be a header
    if (skipHeaderLine) {
        scoreCsv.shift();
    }
    scoreCsv.forEach(function (row, index) {
        var _a;
        var out = processRow(row, index + 1, scoresOnly);
        if (out.req) {
            output.num++;
            output.requests.push(out.req);
        }
        else {
            anyErrors = true;
        }
        (_a = output.messages).push.apply(_a, out.messages); // Could contain errors
    });
    // Figure out, based on the processed output, if the import requests should proceed to import or not
    output.proceed = output.requests.length > 0 && !anyErrors;
    return output;
}
exports.processScoresCSV = processScoresCSV;
var ResponseCSVElementMap = {
    section_ref_id: 0,
    item_ref_id: 1,
    set_name: 2,
    set_type: 3,
    response_ref_id: 4,
    set_template: 5,
    active: 6,
    response_text: 7,
    feedback: 8,
    trait_scores: 9, // starting index of 2+ trait scores
};
var MIN_RESPONSE_N_COLS = 10;
var ScoresCSVElementMap = {
    section_ref_id: 0,
    item_ref_id: 1,
    set_name: 2,
    set_type: 3,
    response_ref_id: 4,
    feedback: 5,
    trait_scores: 6, // Starting index of 2+ trait scores
};
var MIN_SCORES_N_COLS = 7;
exports.VALID_QC_TYPES = [
    "non_qc",
    "practice",
    "qualification",
    "validity",
    "calibration",
    "rangefinding",
    "exemplar",
];
function validateScoresOnlyRow(row, rowNum) {
    var output = {
        valid: true,
        messages: [],
    };
    // Check if the min number of cols are present
    if (row.length < MIN_SCORES_N_COLS) {
        output.messages.push({
            message: "Line ".concat(rowNum, ": Not enough columns for QC scores only import. "),
            result: "Response not processed",
            type: "error",
        });
        output.valid = false;
    }
    else {
        // min colums satisfied
        // Check if vals conform to what is expected
        var sectionRefID = row[ScoresCSVElementMap["section_ref_id"]];
        var itemRefID = row[ScoresCSVElementMap["item_ref_id"]];
        var setType = row[ScoresCSVElementMap["set_type"]];
        var responseRefID = row[ScoresCSVElementMap["response_ref_id"]];
        if (!sectionRefID) {
            output.messages.push({
                message: "Line ".concat(rowNum, ": A non-blank Section Ref ID must be provided. "),
                result: "Response not processed",
                type: "error",
            });
            output.valid = false;
        }
        if (!itemRefID) {
            output.messages.push({
                message: "Line ".concat(rowNum, ": A non-blank Item Ref ID must be provided. "),
                result: "Response not processed",
                type: "error",
            });
            output.valid = false;
        }
        if (!setType) {
            output.messages.push({
                message: "Line ".concat(rowNum, ": A non-blank Set Type must be provided. "),
                result: "Response not processed",
                type: "error",
            });
            output.valid = false;
        }
        if (!responseRefID) {
            output.messages.push({
                message: "Line ".concat(rowNum, ": A non-blank Response Ref ID must be provided. "),
                result: "Response not processed",
                type: "error",
            });
            output.valid = false;
        }
        // Check the feedback for s3 paths
        // make sure it is valid s3 paths if specified
        var feedback = row[ScoresCSVElementMap["feedback"]].trim().toLowerCase();
        if (feedback.startsWith("s3://")) {
            // The s3 path must be a valid s3 path
            var s3PathSplit = feedback.split("/");
            if (s3PathSplit.length < 3 ||
                s3PathSplit[s3PathSplit.length - 1] == "" ||
                s3PathSplit[s3PathSplit.length - 1].split(".").length < 2) {
                output.messages.push({
                    message: "Line ".concat(rowNum, ": Feedback s3 path is not valid. "),
                    result: "Response not processed",
                    type: "error",
                });
                output.valid = false;
            }
        }
        // Check if set type is an acceptable value
        var lowerTrimmedSetType_1 = setType.trim().toLowerCase();
        var isQCTypeValid = exports.VALID_QC_TYPES.some(function (value) {
            return value === lowerTrimmedSetType_1;
        });
        if (!isQCTypeValid) {
            output.messages.push({
                message: "Line ".concat(rowNum, ": Specifies an invalid set type of ").concat(setType, ". "),
                result: "Response not processed",
                type: "error",
            });
            output.valid = false;
        }
        // check that all traitscores are valid numbers
        var rowLength = row.length;
        for (var i = ScoresCSVElementMap["trait_scores"]; i < rowLength; i++) {
            var traitScore = parseFloat(row[i]);
            if (isNaN(traitScore)) {
                // Check if the value is a blank string, if so assume 0 for the score, otherwise, push error message
                var traitString = row[i];
                if (traitString != "") {
                    output.messages.push({
                        message: "Line ".concat(rowNum, ": Trait Score in column ").concat(i, " is invalid. "),
                        result: "Trait Scores must either be blank or a valid number. Response not processed",
                        type: "error",
                    });
                    output.valid = false;
                }
            }
        }
    }
    return output;
}
function validateResponseRow(row, rowNum) {
    var output = {
        valid: true,
        messages: [],
    };
    // Check if the min number of cols are present
    if (row.length < MIN_RESPONSE_N_COLS) {
        output.messages.push({
            message: "Line ".concat(rowNum, ": Not enough columns for QC response import. "),
            result: "Response not processed",
            type: "error",
        });
        output.valid = false;
    }
    else {
        // min colums satisfied
        // Check if vals conform to what is expected
        var sectionRefID = row[ResponseCSVElementMap["section_ref_id"]];
        var itemRefID = row[ResponseCSVElementMap["item_ref_id"]];
        var setType = row[ResponseCSVElementMap["set_type"]];
        var responseRefID = row[ResponseCSVElementMap["response_ref_id"]];
        if (!sectionRefID) {
            output.messages.push({
                message: "Line ".concat(rowNum, ": A non-blank Section Ref ID must be provided. "),
                result: "Response not processed",
                type: "error",
            });
            output.valid = false;
        }
        if (!itemRefID) {
            output.messages.push({
                message: "Line ".concat(rowNum, ": A non-blank Item Ref ID must be provided. "),
                result: "Response not processed",
                type: "error",
            });
            output.valid = false;
        }
        if (!setType) {
            output.messages.push({
                message: "Line ".concat(rowNum, ": A non-blank Set Type must be provided. "),
                result: "Response not processed",
                type: "error",
            });
            output.valid = false;
        }
        if (!responseRefID) {
            output.messages.push({
                message: "Line ".concat(rowNum, ": A non-blank Response Ref ID must be provided. "),
                result: "Response not processed",
                type: "error",
            });
            output.valid = false;
        }
        // Check the response and feedback for s3 paths
        // make sure they are valid s3 paths if specified
        var responseText = row[ResponseCSVElementMap["response_text"]].trim().toLowerCase();
        var feedback = row[ResponseCSVElementMap["feedback"]].trim().toLowerCase();
        if (responseText.startsWith("s3://")) {
            // The s3 path must be a valid s3 path
            var s3PathSplit = responseText.split("/");
            if (s3PathSplit.length < 3 ||
                s3PathSplit[s3PathSplit.length - 1] == "" ||
                s3PathSplit[s3PathSplit.length - 1].split(".").length < 2) {
                output.messages.push({
                    message: "Line ".concat(rowNum, ": Feedback s3 path is not valid. "),
                    result: "Response not processed",
                    type: "error",
                });
                output.valid = false;
            }
        }
        if (feedback.startsWith("s3://")) {
            // The s3 path must be a valid s3 path
            var s3PathSplit = feedback.split("/");
            if (s3PathSplit.length < 3 ||
                s3PathSplit[s3PathSplit.length - 1] == "" ||
                s3PathSplit[s3PathSplit.length - 1].split(".").length < 2) {
                output.messages.push({
                    message: "Line ".concat(rowNum, ": Feedback s3 path is not valid. "),
                    result: "Response not processed",
                    type: "error",
                });
                output.valid = false;
            }
        }
        // Check if set type is an acceptable value
        var lowerTrimmedSetType_2 = setType.trim().toLowerCase();
        var isQCTypeValid = exports.VALID_QC_TYPES.some(function (value) {
            return value === lowerTrimmedSetType_2;
        });
        // Warn the user if set template is empty, but only for types where templates are supported
        if (isQCTypeValid) {
            if (lowerTrimmedSetType_2 === "exemplar" ||
                lowerTrimmedSetType_2 === "calibration" ||
                lowerTrimmedSetType_2 === "validity" ||
                lowerTrimmedSetType_2 === "qualification") {
                var setTemplate = row[ResponseCSVElementMap["set_template"]];
                if (setTemplate === "") {
                    output.messages.push({
                        message: "Line ".concat(rowNum, ": No Set Template was provided. "),
                        result: "Unless another row specifies a template default settings will be configured",
                        type: "warning",
                    });
                }
            }
        }
        else {
            output.messages.push({
                message: "Line ".concat(rowNum, ": Specifies an invalid set type of ").concat(setType, "."),
                result: "Response not processed",
                type: "error",
            });
            output.valid = false;
        }
        // check that all traitscores are valid numbers
        var rowLength = row.length;
        for (var i = ResponseCSVElementMap["trait_scores"]; i < rowLength; i++) {
            var traitScore = parseFloat(row[i]);
            if (isNaN(traitScore)) {
                // Check if the value is a blank string, if so assume 0 for the score, otherwise, push error message
                var traitString = row[i];
                if (traitString != "") {
                    output.messages.push({
                        message: "Line ".concat(rowNum, ": Trait Score in column ").concat(i, " is invalid. Trait Scores must either be blank or a valid number. "),
                        result: "Response not processed",
                        type: "error",
                    });
                    output.valid = false;
                }
            }
        }
    }
    return output;
}
function validateRow(row, rowNum, scoresOnly) {
    var _a, _b;
    var output = {
        valid: true,
        messages: [],
    };
    // Does the row have enough data?
    if (row.length == 0) {
        output.messages.push({
            message: "Line ".concat(rowNum, ": Not enough columns. "),
            result: "Response not processed",
            type: "error",
        });
        output.valid = false;
    }
    else {
        if (scoresOnly) {
            var validationOutput = validateScoresOnlyRow(row, rowNum);
            (_a = output.messages).push.apply(_a, validationOutput.messages);
            if (!validationOutput.valid) {
                output.valid = false;
            }
        }
        else {
            // !scoresOnly
            var validationOutput = validateResponseRow(row, rowNum);
            (_b = output.messages).push.apply(_b, validationOutput.messages);
            if (!validationOutput.valid) {
                output.valid = false;
            }
        }
    }
    return output;
}
function processScoresOnlyRow(row) {
    var request = {};
    // Expect 6+ elements in line if this is a scores only import (minimum of 2 traits needed otherwise everyone gets a 100!)
    request.section_ref_id = row[ScoresCSVElementMap["section_ref_id"]];
    request.item_ref_id = row[ScoresCSVElementMap["item_ref_id"]];
    request.set_name = row[ScoresCSVElementMap["set_name"]];
    request.set_type = row[ScoresCSVElementMap["set_type"]];
    request.response_ref_id = row[ScoresCSVElementMap["response_ref_id"]];
    request.feedback = row[ScoresCSVElementMap["feedback"]];
    var rowLength = row.length;
    request.trait_scores = [];
    for (var i = ScoresCSVElementMap["trait_scores"]; i < rowLength; i++) {
        // If the row is blank, assume the score is zero
        if (row[i] == "") {
            request.trait_scores.push(0);
        }
        else {
            var traitScore = parseFloat(row[i]);
            request.trait_scores.push(traitScore);
        }
    }
    return request;
}
function processResponseRow(row) {
    var request = {};
    // Expect 15+ elements in line if this is a response import (minimum of 2 traits needed otherwise everyone gets a 100!)
    request.section_ref_id = row[ResponseCSVElementMap["section_ref_id"]];
    request.item_ref_id = row[ResponseCSVElementMap["item_ref_id"]];
    request.set_name = row[ResponseCSVElementMap["set_name"]];
    request.set_type = row[ResponseCSVElementMap["set_type"]];
    request.response_ref_id = row[ResponseCSVElementMap["response_ref_id"]];
    request.set_template = row[ResponseCSVElementMap["set_template"]];
    request.active = row[ResponseCSVElementMap["active"]];
    request.response_text = row[ResponseCSVElementMap["response_text"]];
    request.feedback = row[ResponseCSVElementMap["feedback"]];
    var rowLength = row.length;
    request.trait_scores = [];
    for (var i = ResponseCSVElementMap["trait_scores"]; i < rowLength; i++) {
        // If the row is blank, assume the score is zero
        if (row[i] == "") {
            request.trait_scores.push(0);
        }
        else {
            var traitScore = parseFloat(row[i]);
            request.trait_scores.push(traitScore);
        }
    }
    return request;
}
function doProcessRow(row, scoresOnly) {
    var processedRequest = {};
    if (scoresOnly) {
        var request = processScoresOnlyRow(row);
        processedRequest = request;
    }
    else {
        var request = processResponseRow(row);
        processedRequest = request;
    }
    return processedRequest;
}
function processRow(row, rowNum, scoresOnly) {
    var _a;
    var output = {
        req: {},
        messages: [],
    };
    // Validate the row first. If not valid, return only messages
    var validationOutput = validateRow(row, rowNum, scoresOnly);
    (_a = output.messages).push.apply(_a, validationOutput.messages);
    if (!validationOutput.valid) {
        output.req = null;
        return output;
    }
    // If validation succeeds, process the row
    var request = doProcessRow(row, scoresOnly);
    request.line = rowNum;
    output.req = request;
    return output;
}
function parseAndProcessCSV(fileData, scoresOnly, jsParsingFunction) {
    return __awaiter(this, void 0, void 0, function () {
        var processed, csvData, parseOutput, output;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    processed = {
                        requests: [],
                        messages: [],
                        num: 0,
                        proceed: false,
                    };
                    csvData = [];
                    // Initialize the WASM runtime
                    return [4 /*yield*/, (0, wasm_utils_js_1.default)()];
                case 1:
                    // Initialize the WASM runtime
                    _a.sent();
                    // If the browser supports WASM (almost all do), call the WASM file parser, otherwise
                    // call the original JS-based file parser that does not file validation.
                    if ((0, WASMUtils_js_1.default)()) {
                        parseOutput = (0, wasm_utils_js_1.read_csv)(fileData, scoresOnly);
                        // Get all errors and warnings emitted by the WASM file parser.
                        // If any errors occur, the importer will fail.
                        parseOutput
                            .get_messages()
                            .get_warnings()
                            .forEach(function (message) {
                            processed.messages.push({
                                message: "Line ".concat(message.get_line(), ": ").concat(message.get_message()),
                                result: message.get_result(),
                                type: "warning",
                            });
                            console.log("QC import file parsing warning: Message: ", message.get_message(), ", Line:", message.get_line());
                        });
                        parseOutput
                            .get_messages()
                            .get_errors()
                            .forEach(function (message) {
                            processed.messages.push({
                                message: "Line ".concat(message.get_line(), ": ").concat(message.get_message()),
                                result: message.get_result(),
                                type: "error",
                            });
                            console.log("QC import file parsing error: Message: ", message.get_message(), ", Line:", message.get_line());
                        });
                        // If errors occur, downstream import steps won't be allowed
                        // to proceed.
                        processed.proceed = parseOutput.has_errors();
                        if (parseOutput.has_errors()) {
                            processed.proceed = false;
                        }
                        else {
                            processed.proceed = true;
                        }
                        // get all the row data for subsequent processing/validation/import
                        parseOutput.get_rows().forEach(function (row) {
                            var rowData = [];
                            row.get_fields().forEach(function (rowValue) {
                                rowData.push(rowValue);
                            });
                            csvData.push(rowData);
                        });
                    }
                    else {
                        // WASM isn't supported for some reason, call the original
                        // JS-based file parser
                        csvData = jsParsingFunction(fileData, ",");
                    }
                    output = processScoresCSV(csvData, scoresOnly, false);
                    processed.requests = output.requests;
                    if (output.messages) {
                        output.messages.forEach(function (message) {
                            processed.messages.push(message);
                        });
                    }
                    processed.num = output.num;
                    processed.proceed = output.proceed;
                    return [2 /*return*/, processed];
            }
        });
    });
}
exports.parseAndProcessCSV = parseAndProcessCSV;
