<template>
	<div class="video-box">
		<div v-if="mediaLoaded == false" class="grid-center padding">
			<div class="text-center" style="line-height: 1.4">
				<i class="fas fa-file-code fa-2x text-extra-muted" />
				<div class="text-extra-muted font-initial">Video failed to load</div>
			</div>
		</div>

		<video
			v-else
			ref="video"
			class="mzd-video"
			preload="metadata"
			controls
			controlsList="nodownload noplaybackrate"
			@loadeddata="loadedData"
			@error="loadedError"
			@timeupdate="timeupdate"
			@seeking="timeupdate"
			@ended="ended"
			:key="canSeek"
		>
			<source :src="page.url" />
			Your browser does not support the video tag.
		</video>
	</div>
</template>

<script>
import Notie from "@/services/NotieService";
import _ from "lodash";

export default {
	name: "VideoMedia",
	props: {
		page: Object,
		chan: Object,
		item: {
			type: Object,
			required: false,
		},
	},

	data() {
		return {
			events: [],
			pastTime: 0,
			canSeek: !this.item || !this.item.require_view_all,
			mediaLoaded: null,
		};
	},

	created() {
		if (!this.chan) {
			return;
		}
		this.events.push({ key: "set-playback-speed", val: this.setPlaybackSpeed });
		this.chan.$on("set-playback-speed", this.setPlaybackSpeed);
		this.chan.$emit("increment-media-to-load");
	},

	mounted() {
		const { playback_speed } = this.$route.meta.user;
		if (playback_speed) {
			this.setPlaybackSpeed(playback_speed);
		}
	},

	destroyed() {
		const _this = this;
		_.each(this.events, (e) => {
			_this.chan.$off(e.key, e.val);
		});
	},

	methods: {
		setPlaybackSpeed(playbackRate) {
			this.$refs.video.playbackRate = playbackRate;
		},

		timeupdate() {
			const {
				pastTime,
				canSeek,
				$refs: { video },
			} = this;
			if (!canSeek && video.seeking && Math.abs(pastTime - video.currentTime) > 0.01) {
				this.$refs.video.currentTime = pastTime;
				this.throttledNotieUpdate();
			}
			this.pastTime = video.currentTime;
		},

		throttledNotieUpdate: _.throttle(
			() => {
				Notie.info("Must watch the complete video");
			},
			5000,
			{ trailing: false }
		),

		ended() {
			this.canSeek = true;
			this.$emit("viewedAll");
		},

		loadedData(ev) {
			if (this.mediaLoaded) {
				// Needed because this seems to fire a second time when the media finished playback
				return;
			}

			this.mediaLoaded = true;
			if (this.chan) {
				this.chan.$emit("increment-media-loaded");
			}
		},

		loadedError(ev) {
			this.mediaLoaded = false;
			if (this.chan) {
				this.chan.$emit("increment-media-failed");
			}
		},
	},
};
</script>

<style scoped>
.mzd-video {
	width: 100%;
}

.video-box {
	margin-top: 20px;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	/*min-height:1250px;*/
	/*width:1000px;*/
	/*font-size: 17pt;*/

	/*min-height:1000px;*/
	max-width: 800px;
	min-width: 200px;
	font-size: 13pt;

	/*padding:80px;*/
	-webkit-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	-moz-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	background-color: white;
	color: black;
	font-family: "Times New Roman", Times, Georgia, serif;
	line-height: 0;
}
</style>
