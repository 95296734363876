<template>
	<b-modal
		v-if="editingTrait"
		:title="`${$t('RubricEdit.trait_edit_modal.title')} - ${editingTrait.name}`"
		size="xl"
		visible
		scrollable
		hide-footer
		hide-header-close
		no-close-on-esc
		no-close-on-backdrop
	>
		<div class="modal-body">
			<div class="row">
				<div class="col-md-8">
					<div class="row mb-3">
						<div class="col-12 form-group">
							<h5 class="d-inline-block" v-tippy :title="$t('tooltip.leave_blank_for_same_trait_name')">
								{{ $t("RubricEdit.trait_edit_modal.reported_name") }}
							</h5>
							<input
								class="form-control"
								v-model="editingTrait.reported_name"
								type="text"
								:placeholder="editingTrait.name"
							/>
						</div>
						<div class="col-6">
							<label>{{ $t("RubricEdit.external_trait_id") }}</label>
							<input class="form-control" v-model="editingTrait.external_trait_id" type="text" />
							<small class="form-text text-muted">Preferably between 2 and 4 letters/digits</small>
						</div>
						<div class="col-6">
							<label>{{ $t("RubricEdit.external_item_id") }}</label>
							<input class="form-control" v-model="editingTrait.external_item_id" type="text" />
						</div>
					</div>
				</div>
				<div v-if="!editingTrait.linked_to" class="col-md-4">
					<h5>{{ $t("RubricEdit.trait_edit_modal.masked_score_points") }}</h5>
					<config-trait
						:trait="editingTrait"
						:rubric="rubric"
						:selected.sync="editingTrait.masked_score_points"
						:single="false"
					></config-trait>
				</div>
			</div>
			<div>
				<div v-if="!editingTrait.linked_to" class="row mb-2 col-12">
					<div class="col-4">
						<h5>{{ $t("RubricEdit.trait_edit_modal.condition_codes") }}</h5>
					</div>
					<div class="col-4">
						<div class="checkbox">
							<label class="md-check" :class="{ disabled: rubric.isScored }">
								<input v-model="editingTrait.inline_condition_codes" type="checkbox" />
								<i class="theme"></i> {{ $t("RubricEdit.inline_condition_codes") }}
							</label>
						</div>
					</div>
					<div class="col-4">
						<div class="checkbox">
							<label class="md-check" :class="{ disabled: rubric.isScored }">
								<input v-model="editingTrait.condition_codes_only" type="checkbox" />
								<i class="theme"></i> {{ $t("RubricEdit.condition_codes_only") }}
							</label>
						</div>
					</div>
				</div>
				<table v-if="!editingTrait.linked_to" class="table">
					<thead>
						<tr>
							<th>{{ $t("fields.symbol") }}</th>
							<th style="width: 100px">{{ $t("fields.value") }}</th>
							<th style="width: 100px">{{ $t("fields.sequence") }}</th>
							<th style="width: 220px">{{ $t("fields.action") }}</th>
							<th>{{ $t("fields.admin_only") }}</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr v-if="!editingTrait.condition_codes.length">
							<td colspan="4" class="text-center">
								{{ $t("data_description.no_condition_codes") }}
							</td>
						</tr>

						<tr v-for="code in editingTrait.condition_codes" :key="code.sequence">
							<td>
								<input
									:disabled="rubric.isScored"
									type="text"
									v-model="code.symbol"
									class="form-control"
								/>
							</td>
							<td>
								<div
									v-if="
										code.action == CONDITION_CODE_ACTIONS.SYMBOL.id ||
										code.action == CONDITION_CODE_ACTIONS.SYMBOL_LINKED.id
									"
									class="text-center"
								>
									{{ code.symbol }}
								</div>
								<div v-if="code.action == CONDITION_CODE_ACTIONS.FLAG.id" class="text-center">
									{{ $t("fields.flag") }}
								</div>
								<b-input
									v-if="showValueInput(code)"
									type="number"
									number
									:min="editingTrait.min"
									:max="editingTrait.max"
									:formatter="
										(input) => {
											const [whole, decimals] = input.split('.');
											if (decimals && decimals.length > 2) {
												return;
												`${whole}.${decimals.slice(0, 2)}`;
											}
											return input;
										}
									"
									:disabled="rubric.isScored"
									v-model.number="code.score"
									:class="{
										'is-invalid': valueWaringText(editingTrait, code.score) !== '',
									}"
									class="form-control"
									v-tippy="{ show: valueWaringText(editingTrait, code.score) !== '' }"
									:title="valueWaringText(editingTrait, code.score)"
								/>
							</td>
							<td>
								<input
									:disabled="rubric.isScored"
									type="number"
									number
									v-model.number="code.sequence"
									class="form-control"
								/>
							</td>
							<td class="col-sm-4">
								<select
									:disabled="rubric.isScored"
									class="form-control"
									:value="getSelectedCCAction(code)"
									@change="setCodeAction(code, $event)"
								>
									<option
										v-for="ccAction in selectableCCActions"
										:key="ccAction.id"
										:value="ccAction.id"
									>
										{{ ccAction.name }}
									</option>
									<template v-for="alert in alerts">
										<option v-if="!alert.requires_score" :key="alert.id" :value="alert.id">
											Flag as "{{ alert.code | replaceIfExists(alert.description) }}"
										</option>
									</template>
								</select>
							</td>
							<td>
								<div class="d-flex justify-content-center">
									<label
										class="ui-switch ui-switch-md"
										:class="[rubric.isScored ? 'theme-lighten-4' : 'theme-accent']"
									>
										<input type="checkbox" v-model="code.admin_only" :disabled="rubric.isScored" />
										<i></i>
									</label>
								</div>
							</td>
							<td>
								<button
									:disabled="rubric.isScored"
									@click="removeConditionCode(code)"
									class="btn btn-sm btn-icon btn-rounded red text-white m-0"
									:title="$t('buttons.remove_trait')"
								>
									<i class="fa fa-trash"></i>
								</button>
							</td>
						</tr>

						<tr>
							<td colspan="3">
								<button
									:disabled="rubric.isScored"
									@click="
										editingTrait.condition_codes.push({
											symbol: 'A',
											sequence: editingTrait.condition_codes.length,
											action: 1,
											score: 0,
										})
									"
									class="btn-block btn theme-accent"
								>
									{{ $t("buttons.add_condition_code") }}
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div v-if="!editingTrait.linked_to" class="row">
				<div class="col-md-12">
					<h5>{{ $t("RubricEdit.trait_edit_modal.score_point_comments") }}</h5>
					<b-row>
						<config-trait
							:trait="editingTrait"
							:rubric="rubric"
							:selected.sync="spcodeselected"
							:single="true"
							:autoselect="true"
						/>
						<b-col cols="2" class="pl-5">
							<b-row> {{ $t("RubricEdit.trait_edit_modal.set_min_max") }}</b-row>
							<b-row class="pt-3 pl-2"
								><label class="ui-switch ui-switch-md theme-accent">
									<input
										:key="
											spcodeselected && spcodeselected.length > 0 && spcodeselected[0].mask_value
										"
										type="checkbox"
										@click="toggleSpMinMax"
										:checked="currentSpCommentLimits"
									/>
									<i></i> </label
							></b-row>
						</b-col>
						<b-col v-if="currentSpCommentLimits" cols="1">
							<b-row>Min</b-row>
							<b-row class="pt-2"
								><input
									class="form-control"
									type="number"
									v-model.number="currentSpCommentLimits.min"
									min="0"
									:max="currentSpCommentLimits.max"
									style="max-width: 60px"
								/>
							</b-row>
						</b-col>
						<b-col v-if="currentSpCommentLimits">
							<b-row>Max</b-row>
							<b-row class="pt-2"
								><input
									class="form-control"
									type="number"
									v-model.number="currentSpCommentLimits.max"
									:min="currentSpCommentLimits.min"
									:max="currentSpComments.length"
									style="max-width: 60px"
								/>
							</b-row>
						</b-col>
					</b-row>
					<table class="table">
						<thead>
							<tr>
								<th class="w100">{{ $t("RubricEdit.trait_edit_modal.code") }}</th>
								<th>{{ $t("RubricEdit.trait_edit_modal.text") }}</th>
								<th>{{ $t("RubricEdit.trait_edit_modal.linked") }}</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td v-if="!editingComments.length" colspan="3" class="text-center">
									{{ $t("data_description.no_score_point_codes") }}
								</td>
							</tr>

							<tr v-for="comment in editingComments" :key="comment.code">
								<td>
									<input
										:disabled="rubric.isScored && comment.locked"
										type="text"
										v-model="comment.code"
										class="form-control"
									/>
								</td>
								<td>
									<input
										type="text"
										:placeholder="$t('RubricEdit.comment_text_placeholder')"
										v-model="comment.text"
										class="form-control"
									/>
								</td>
								<td>
									<div class="checkbox form-group">
										<label class="md-check pl-4">
											<input type="checkbox" v-model="comment.linked" />
											<i class="theme-accent" />
										</label>
									</div>
								</td>
								<td>
									<button
										:disabled="rubric.isScored && comment.locked"
										@click="removeScorePointCommentCode(comment)"
										class="btn btn-sm btn-icon btn-rounded red text-white m-0"
										:title="$t('buttons.remove_trait')"
									>
										<i class="fa fa-trash"></i>
									</button>
								</td>
							</tr>

							<tr>
								<td colspan="2">
									<button @click="addCommentCode()" class="btn btn-block theme-accent">
										{{ $t("RubricEdit.trait_edit_modal.add_comment") }}
									</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div v-if="availableToLink(editingTrait).length" class="row">
				<h5 class="col-12">{{ $t("RubricEdit.trait_edit_modal.linked_trait") }}</h5>
				<div class="col-6">
					<config-select
						:options="availableToLink(editingTrait)"
						v-model="editingTrait.linked_to"
						byField="id"
						:nullOption="true"
						:nullText="$t('data_description.none')"
						@input="linkedChanged(editingTrait.id, $event)"
						:disabled="rubric.isScored"
					/>
				</div>
				<div v-if="editingTrait.linked_to" class="col-6" style="padding-top: 6px">
					<b-row>
						<div class="checkbox">
							<label class="md-check" :class="{ disabled: rubric.isScored }">
								<input v-model="editingTrait.hidden" type="checkbox" />
								<i class="theme"></i>
								{{ $t("RubricEdit.trait_edit_modal.hidden") }}
							</label>
						</div>
						<div class="checkbox pl-2">
							<label class="md-check" :class="{ disabled: rubric.isScored }">
								<input v-model="editingTrait.link_comments" type="checkbox" />
								<i class="theme"></i>
								{{ $t("RubricEdit.trait_edit_modal.link_comments") }}
							</label>
						</div>
					</b-row>
				</div>
			</div>
		</div>

		<div class="modal-footer">
			<button @click="close" class="btn btn-flat" data-dismiss="modal">
				{{ $t("buttons.done") }}
			</button>
		</div>
	</b-modal>
</template>

<style scoped>
.w100 {
	width: 90px;
}
</style>

<script>
import ConfigTrait from "../../components/ConfigTrait";
import ConfigSelect from "../../components/ConfigSelect";
import { CONDITION_CODE_ACTIONS } from "@/services/Constants.js";

export default {
	name: "TraitEditModal",

	components: {
		ConfigTrait,
		ConfigSelect,
	},

	props: {
		editingTrait: Object,
		rubric: Object,
		alerts: Array,
	},

	data() {
		return {
			spcodeselected: [],
			editingComments: [],
			CONDITION_CODE_ACTIONS,
		};
	},

	computed: {
		selectableValues() {
			const { min, max } = this.editingTrait;
			return _.range(min, max + 1);
		},

		selectableCCActions() {
			const { SYMBOL, VALUE, SYMBOL_LINKED, VALUE_LINKED, SYMBOL_AND_VALUE, SYMBOL_AND_VALUE_LINKED } =
				CONDITION_CODE_ACTIONS;
			return [SYMBOL, VALUE, SYMBOL_LINKED, VALUE_LINKED, SYMBOL_AND_VALUE, SYMBOL_AND_VALUE_LINKED];
		},

		currentSpCommentLimits() {
			const { spcodeselected, editingTrait } = this;
			if (spcodeselected.length === 0) return null;
			const { mask_value } = spcodeselected[0];
			return editingTrait.score_point_code_limits.find(({ score_point }) => score_point == mask_value);
		},

		currentSpComments() {
			const { spcodeselected, editingTrait } = this;
			if (spcodeselected.length === 0) return [];
			const { mask_value } = spcodeselected[0];
			return editingTrait.score_point_codes.filter(({ score_point }) => score_point == mask_value);
		},

		limitWatcher() {
			const { currentSpCommentLimits } = this;
			if (!currentSpCommentLimits) return "";
			return `${currentSpCommentLimits.min}-${currentSpCommentLimits.max}`;
		},
	},

	watch: {
		spcodeselected() {
			if (this.spcodeselected.length > 0) {
				this.updateEditingCommentList();
			}
		},

		limitWatcher() {
			this.checkSPMinMax();
		},
	},

	methods: {
		close() {
			this.spcodeselected = [];
			this.$emit("close");
		},

		setCodeAction(code, event) {
			const selectedVal = event.target.value;
			const isDigit = /^\d+$/;

			if (isDigit.test(selectedVal)) {
				code.action = parseInt(selectedVal);
			} else {
				code.alert_id = selectedVal;
				code.action = CONDITION_CODE_ACTIONS.FLAG.id;
			}
		},

		addCommentCode() {
			var sp = this.spcodeselected[0].mask_value;

			this.editingTrait.score_point_codes.push({
				score_point: sp,
				code: this.nextCommentCodeLetter(this.editingTrait),
				text: "",
			});
			this.updateEditingCommentList();
		},

		nextCommentCodeLetter(trait) {
			let existingCodes = {};
			_.each(trait.score_point_codes, (spc) => {
				existingCodes[spc.code] = true;
			});

			let i = 0;
			let newCode = this.intToCode(i);
			while (existingCodes[newCode]) {
				i++;
				newCode = this.intToCode(i);
			}

			return newCode;
		},

		showValueInput(code) {
			const { VALUE, VALUE_LINKED, SYMBOL_AND_VALUE, SYMBOL_AND_VALUE_LINKED } = CONDITION_CODE_ACTIONS;
			return [VALUE, VALUE_LINKED, SYMBOL_AND_VALUE, SYMBOL_AND_VALUE_LINKED]
				.map(({ id }) => id)
				.includes(code.action);
		},

		intToCode(num) {
			let codes = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
			let code = "";
			while (num != -1) {
				let i = num % 26;
				code = codes[i] + code;
				num = Math.floor(num / 26) - 1;
			}
			return code;
		},

		availableToLink(trait) {
			let availTraits = [];

			let alreadyLinked = {};
			_.each(this.rubric.traits, (t) => {
				if (trait.id != t.id && t.linked_to) {
					console.log(t.id, "invalidate");
					console.log(t.linked_to);
					alreadyLinked[t.linked_to] = true;
					alreadyLinked[t.id] = true;
				}
			});

			if (alreadyLinked[trait.id]) {
				return [];
			}

			_.each(this.rubric.traits, (t) => {
				if (!alreadyLinked[t.id] && t.id != trait.id) {
					availTraits.push(t);
				}
			});

			console.log("Avail traits", availTraits);
			return availTraits;
		},

		removeScorePointCommentCode(code) {
			this.editingTrait.score_point_codes = _.without(this.editingTrait.score_point_codes, code);
			this.updateEditingCommentList();
			this.checkSPMinMax();
		},

		removeConditionCode(code) {
			this.editingTrait.condition_codes = _.without(this.editingTrait.condition_codes, code);
		},

		updateEditingCommentList() {
			var selected = this.spcodeselected[0];
			if (this.editingTrait) {
				this.editingComments = _.filter(this.editingTrait.score_point_codes, {
					score_point: selected.mask_value,
				});
			}
		},

		valueWaringText(trait, val) {
			if (!this.isInScoreRange(trait, val))
				return "Invalid: This value is outside the score point range for this trait";
			if (!this.matchesStep(trait, val))
				return "Warning: This value does not match the trait's step. This could cause issues with certain reports.";
			return "";
		},

		isInScoreRange(trait, val) {
			if (val < trait.min) {
				return false;
			}
			if (val > trait.max) {
				return false;
			}

			return true;
		},

		matchesStep(trait, val) {
			return val % trait.step === 0;
		},

		linkedChanged(traitID, linkedToID) {
			console.log("Link", traitID, "to", linkedToID);
		},

		getSelectedCCAction(code) {
			const { action, alert_id } = code;
			if (action === CONDITION_CODE_ACTIONS.FLAG.id) return alert_id;
			return action;
		},

		toggleSpMinMax() {
			const { spcodeselected, currentSpCommentLimits } = this;
			if (!spcodeselected || spcodeselected.length === 0) {
				return;
			}
			const { mask_value } = spcodeselected[0];

			if (currentSpCommentLimits) {
				this.editingTrait.score_point_code_limits = this.editingTrait.score_point_code_limits.filter(
					({ score_point }) => score_point !== mask_value
				);
			} else {
				this.editingTrait.score_point_code_limits.push({
					score_point: mask_value,
					min: 0,
					max: this.currentSpComments.length,
				});
			}
		},

		checkSPMinMax() {
			const { currentSpCommentLimits, currentSpComments } = this;
			if (!currentSpCommentLimits) return;
			if (!Number.isInteger(currentSpCommentLimits.min)) {
				currentSpCommentLimits.min = 0;
			}
			if (
				!Number.isInteger(currentSpCommentLimits.max) ||
				currentSpCommentLimits.max > currentSpComments.length
			) {
				currentSpCommentLimits.max = currentSpComments.length;
			}
			if (currentSpCommentLimits.min < 0) {
				currentSpCommentLimits.min = 0;
			}

			if (currentSpCommentLimits.min > currentSpCommentLimits.max) {
				currentSpCommentLimits.min = Math.min(currentSpCommentLimits.min, currentSpCommentLimits.max);
				currentSpCommentLimits.min = currentSpCommentLimits.max;
			}
		},
	},
};
</script>
