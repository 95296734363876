import vm from "../main.js";
import {
	TRAINING_ELEMENT_TYPES,
	RESPONSE_STATES,
	SCORE_TYPES,
	ITEM_TYPES,
	FLAG_ACTION_TYPES,
	idToName,
	idToObject,
} from "@/services/Constants";
import i18n from "@/i18n/setup";
import sanitizeHtml from "sanitize-html";

const {
	FIRST,
	SECOND,
	RESOLUTION,
	BACKREAD,
	ADJUDICATION,
	APPEAL,
	APPEAL2,
	FINAL,
	EXTERNAL_FINAL_SCORE,
	INVALIDATED_RESOLUTION,
	INVALIDATED_ADJUDICATION,
} = SCORE_TYPES;

var moment = require("moment-timezone");
var now = moment();

export default {
	refIDRegExp: /([!#%^*()\\/\[\]{}<>?|'"&:]|[^\x00-\x7F])/g,
	isGoodRefID(refID) {
		if (!refID) {
			return false;
		}
		return !(refID.match(this.refIDRegExp) || refID.match(/\s/g));
	},
	toGoodRefID(refID) {
		return refID.replace(this.refIDRegExp, "-").replace(/\s/g, "_").toLowerCase();
	},

	hasUnicodeChars(str) {
		return str.match(/[^\x00-\x7F]/g);
	},

	isNaturalNumberStr(numStr) {
		const num = Number.parseInt(numStr);
		return num && !Number.isNaN(num) && num > 0;
	},

	scorerID(user) {
		if (user.scorer_id && user.scorer_id != "") {
			return user.scorer_id;
		} else if (user.full_name && user.full_name != "") {
			return user.full_name;
		} else {
			return `${user.first_name} ${user.last_name}`;
		}
	},

	nameWithScorerID(user) {
		var name = "";
		if (user) {
			if (user.first_name && user.last_name && user.first_name != "" && user.last_name != "") {
				name += user.first_name + " " + user.last_name;
			} else {
				if (user.first_name && user.first_name != "") {
					name = user.first_name;
				} else if (user.last_name && user.last_name != "") {
					name = user.last_name;
				}
			}
		}

		if (user.scorer_id && user.scorer_id != "") {
			if (name == "") {
				name = user.scorer_id;
			} else {
				name += " (" + user.scorer_id + ")";
			}
		}

		return name;
	},

	nameWithEmail(user) {
		var name = "";
		if (user) {
			if (user.first_name && user.last_name && user.first_name != "" && user.last_name != "") {
				name += user.first_name + " " + user.last_name;
			} else {
				if (user.first_name && user.first_name != "") {
					name = user.first_name;
				} else if (user.last_name && user.last_name != "") {
					name = user.last_name;
				}
			}
		}

		if (user.email && user.email != "") {
			if (name == "") {
				name = user.email;
			} else {
				name += " (" + user.email + ")";
			}
		}

		return name;
	},

	initials(userOrName) {
		if (typeof userOrName == "string") {
			return this.initialsFromName(userOrName);
		} else {
			return this.initialsFromUser(userOrName);
		}
	},

	initialsFromUser(user) {
		if (user.full_name) {
			return this.initialsFromName(user.full_name);
		} else if (user.name) {
			return this.initialsFromName(user.name);
		} else if (user.first_name && user.last_name) {
			return this.initialsFromName(`${user.first_name} ${user.last_name}`);
		} else if (user.first_name) {
			return this.initialsFromName(user.first_name);
		} else if (user.last_name) {
			return this.initialsFromName(user.last_name);
		} else {
			return "";
		}
	},

	initialsFromName(name) {
		let initials = "";
		let nameParts = name.split(" ");
		for (let part of nameParts) {
			if (part) {
				initials += part[0];
			}
		}
		return initials.slice(0, 3).toUpperCase();
	},

	responseStates() {
		const options = Object.values(RESPONSE_STATES).map(({ id, name }) => ({
			value: id,
			label: name,
		}));
		const any = { value: 0, label: "　" };
		return [any].concat(options);
	},

	responseState(state) {
		return idToName(RESPONSE_STATES, state) || i18n.t("fs.unknown");
	},

	scoreTypeIcon(type) {
		switch (type) {
			case FIRST.id:
				return "done";
			case SECOND.id:
				return "done";
			case RESOLUTION.id:
			case INVALIDATED_RESOLUTION.id:
				return "done_all";
			case BACKREAD.id:
				return "verified_user";
			case ADJUDICATION.id:
			case INVALIDATED_ADJUDICATION.id:
				return "gavel";
			case APPEAL.id:
			case APPEAL2.id:
				return "account_balance";
			case FINAL.id:
				return "flag_checkered";
			case EXTERNAL_FINAL_SCORE.id:
				return "done";
			default:
				console.error("Unknown score type", type);
				return "help_outline";
		}
	},

	scoreTypeIconClass(type) {
		switch (type) {
			case 11:
				return "fa-user-graduate";
			case 12:
			case 13:
			case 14:
			case 20:
				return "fa-balance-scale";
			case 15:
				return "fa-drafting-compass";
			case 21:
				return "fa-hands-helping";
			case 30:
				return "fa-bullseye";
			case 50:
				return "fa-file-export";
			default:
				return "";
		}
	},

	scoreTypeName(id) {
		return idToName(SCORE_TYPES, id);
	},

	scoreTypeAbr(type) {
		switch (type) {
			case FIRST.id:
				return i18n.t("fs.first_abr");
			case SECOND.id:
				return i18n.t("fs.second_abr");
			case RESOLUTION.id:
				return i18n.t("fs.resolution_abr");
			case BACKREAD.id:
				return i18n.t("fs.backread_abr");
			case ADJUDICATION.id:
				return i18n.t("fs.adjudication_abr");
			case APPEAL.id:
				return i18n.t("fs.appeal_abr");
			case APPEAL2.id:
				return i18n.t("fs.appeal2_abr");
			case INVALIDATED_RESOLUTION.id:
				return i18n.t("fs.invalidated_resolution_abr");
			case INVALIDATED_ADJUDICATION.id:
				return i18n.t("fs.invalidated_adjudication_abr");
			default:
				console.error("Unknown score type", type);
				return i18n.t("fs.unknown_abr");
		}
	},

	completeState(state, i18n) {
		switch (state) {
			case 0:
				return i18n.t("fs.complete_state_none");
			case 1:
				return i18n.t("fs.complete_state_incomplete");
			case 2:
				return i18n.t("fs.complete_state_partial");
			case 3:
				return i18n.t("fs.complete_state_complete");
			default:
				console.error("Unknown complete state type", type);
				return i18n.t("fs.unknown_abr");
		}
	},

	itemTypeName(type) {
		const obj = idToObject(ITEM_TYPES, type);
		if (!obj) return "";
		return obj.shortName;
	},

	itemTypes() {
		return Object.values(ITEM_TYPES);
	},

	shortDuration(seconds) {
		if (seconds == 0) {
			return "——";
		} else if (!seconds) {
			return "——";
		} else {
			return moment().startOf("day").seconds(seconds).format("m:ss");
		}
	},

	shortDurationMinutes(seconds) {
		if (seconds == 0) {
			return "—";
		} else if (!seconds) {
			return "—";
		} else {
			return moment().startOf("day").seconds(seconds).format("m");
		}
	},

	shortDurationSeconds(seconds) {
		if (seconds == 0) {
			return "—";
		} else if (!seconds) {
			return "—";
		} else {
			return moment().startOf("day").seconds(seconds).format("ss");
		}
	},

	medDuration(duration) {
		if (duration == 0) {
			return "——";
		} else if (!duration) {
			return "——";
		} else {
			//duration+=86400000
			let seconds = parseInt(duration % 60);
			let minutes = parseInt((duration / 60) % 60);
			let hours = parseInt(duration / (60 * 60));

			minutes = minutes < 10 ? "0" + minutes : minutes;
			seconds = seconds < 10 ? "0" + seconds : seconds;

			return hours + ":" + minutes + ":" + seconds;
		}
	},

	medDurationLetters(duration) {
		if (duration == 0) {
			return "——";
		} else if (!duration) {
			return "——";
		} else {
			//duration+=86400000
			let seconds = parseInt(duration % 60);
			let minutes = parseInt((duration / 60) % 60);
			let hours = parseInt(duration / (60 * 60));

			seconds = seconds < 10 ? "0" + seconds : seconds;

			if (hours > 0) {
				minutes = minutes < 10 ? "0" + minutes : minutes;
				return `${hours}h${minutes}m${seconds}s`;
			} else {
				return `${minutes}m${seconds}s`;
			}
		}
	},

	dateString(date) {
		return moment(date).format("LL");
	},

	fullDate(date) {
		return moment(date).format("l LTS");
	},

	medDate(date) {
		if (moment(date).year() === 0) {
			// null date
			return "——";
		}
		return moment(date).format("l LT");
	},

	shortDate(date) {
		return moment(date).format("l");
	},

	time(date) {
		return moment(date).format("LT");
	},

	miniDate(date) {
		console.log(date, "to mini date");
		return moment(date).format("MMM D");
	},

	sinceString(date) {
		return moment(date).fromNow();
	},

	scoringMode(mode) {
		switch (mode) {
			case "SCORING":
				return vm.$i18n.t("fs.live_scoring");
			case "RESOLUTION":
				return vm.$i18n.t("fs.resolution");
			case "BACKREAD":
				return vm.$i18n.t("fs.backreading");
			case "QUEUE BACKREAD":
				return vm.$i18n.t("fs.backreading");
			case "AUDITING":
				return vm.$i18n.t("fs.auditing");
			case "FLAG QUEUE":
				return vm.$i18n.t("fs.flag_queue");
			case "QC_TRUE_SCORE":
				return vm.$i18n.t("fs.qc");
			default:
				return vm.$i18n.t("fs.unknown");
		}
	},

	getQcRuleText(rule) {
		const found = this.getQcRule(rule.rule);
		if (!found) {
			return "Invalid rule";
		}
		let text = `${found.name}, Overall ${rule.pass_percent}%`;
		if (found.uses_fields.includes("trait_overall_pass_percent")) {
			text += `, Trait: ${rule.trait_pass_percent}%`;
		}
		return text;
	},

	getQcRule(id) {
		return this.qcRules().find((rule) => id === rule.id);
	},

	qcRules() {
		if (!vm) {
			return [];
		}
		return [
			{
				name: vm.$i18n.t("QCSetup.rules.exact_agreement_by_response"),
				id: "exact_agreement_by_response",
				uses_fields: ["score_agreement", "exact", "full_response", "pass_percent"],
			},
			{
				name: vm.$i18n.t("QCSetup.rules.exact_agreement_by_trait"),
				id: "exact_agreement_by_trait",
				uses_fields: ["score_agreement", "exact", "trait_overall_pass_percent", "trait_pass_percent"],
			},
			{
				name: vm.$i18n.t("QCSetup.rules.exact_adjacent_by_response"),
				id: "exact_adjacent_by_response",
				uses_fields: ["score_agreement", "exact_adjacent", "full_response", "pass_percent"],
			},
			{
				name: vm.$i18n.t("QCSetup.rules.exact_adjacent_by_trait"),
				id: "exact_adjacent_by_trait",
				uses_fields: ["score_agreement", "exact_adjacent", "trait_overall_pass_percent", "trait_pass_percent"],
			},
			{
				name: vm.$i18n.t("QCSetup.rules.omaha"),
				id: "omaha",
				uses_fields: [
					"response_agreement",
					"omaha_min_max",
					"omaha_max_non_adj",
					"omaha_max_weighted_score_diff",
					"pass_percent",
				],
			},
			// {
			// 	name: vm.$i18n.t('QCSetup.rules.gca'),
			// 	id: "gca",
			// 	uses_fields: [
			// 		"score_agreement",
			// 		"exact",
			// 		"trait_overall_pass_percent",
			// 		"trait_pass_percent",
			// 		"maximum_non_adjacent",
			// 		"splits"
			// 	]
			// },
			// {
			// 	name: vm.$i18n.t('QCSetup.rules.gcps'),
			// 	id: "gcps",
			// 	uses_fields: [
			// 		"response_agreement",
			// 		"weighted_threshold",
			// 		"weighted_range_min",
			// 		"weighted_range_max",
			// 		"pass_percent",
			// 		"maximum_non_adjacent"
			// 	]
			// },
			// {
			// 	name: vm.$i18n.t('QCSetup.rules.omaha'),
			// 	id: "omaha",
			// 	uses_fields: [
			// 		"response_agreement",
			// 		"weighted_threshold",
			// 		"weighted_range_min",
			// 		"weighted_range_max",
			// 		"pass_percent"
			// 	]
			// },
		];
	},

	qcRuleIdToName(ruleID) {
		const rule = this.qcRules().find(({ id }) => id === ruleID);
		if (rule) return rule.name;
		return ruleID;
	},

	qualRequirements() {
		return [
			{
				name: vm.$i18n.t("ItemEdit.Quality.qualification.exact"),
				id: "exact",
			},
			{
				name: vm.$i18n.t("ItemEdit.Quality.qualification.exact_adj"),
				id: "exact_adj",
			},
			{
				name: vm.$i18n.t("ItemEdit.Quality.qualification.trait_exact"),
				id: "trait_exact",
			},
			{
				name: vm.$i18n.t("ItemEdit.Quality.qualification.trait_exact_adj"),
				id: "trait_exact_adj",
			},
			{
				name: vm.$i18n.t("ItemEdit.Quality.qualification.max_discrepant"),
				id: "max_discrepant",
			},
			{
				name: vm.$i18n.t("ItemEdit.Quality.qualification.trait_max_discrepant"),
				id: "trait_max_discrepant",
			},
			{
				name: vm.$i18n.t("ItemEdit.Quality.qualification.drop_a_set"),
				id: "drop_set",
			},
			{
				name: vm.$i18n.t("ItemEdit.Quality.qualification.keep_best_trait"),
				id: "keep_best_trait",
			},
			{
				name: vm.$i18n.t("ItemEdit.Quality.qualification.trait_pass"),
				id: "trait_pass",
			},
		];
	},

	valRequirements() {
		return [
			{
				name: vm.$i18n.t("ItemEdit.Quality.qualification.trait_exact"),
				id: "trait_exact",
			},
			{
				name: vm.$i18n.t("ItemEdit.Quality.qualification.trait_exact_adj"),
				id: "trait_exact_adj",
			},
		];
	},

	qualDropSetOptions() {
		return [
			{
				name: vm.$i18n.t("ItemEdit.Quality.qualification.lowest_exact"),
				id: 0,
			},
			{
				name: vm.$i18n.t("ItemEdit.Quality.qualification.highest_exact"),
				id: 1,
			},
			{
				name: vm.$i18n.t("ItemEdit.Quality.qualification.lowest_exact_adj"),
				id: 2,
			},
			{
				name: vm.$i18n.t("ItemEdit.Quality.qualification.highest_exact_adj"),
				id: 3,
			},
			{
				name: vm.$i18n.t("ItemEdit.Quality.qualification.lowest_discrepant"),
				id: 4,
			},
			{
				name: vm.$i18n.t("ItemEdit.Quality.qualification.highest_discrepant"),
				id: 5,
			},
		];
	},

	qcType(type) {
		switch (type) {
			case 1:
				return vm.$i18n.t("fs.practice");
			case 2:
				return vm.$i18n.t("fs.qualification");
			case 3:
				return vm.$i18n.t("fs.validity");
			case 4:
				return vm.$i18n.t("fs.calibration");
			case 5:
				return vm.$i18n.t("fs.rangefinding");
			case 6:
				return vm.$i18n.t("fs.anchor");
			default:
				return vm.$i18n.t("fs.unknown_abr");
		}
	},

	fixed1d(num) {
		if (num == Number.POSITIVE_INFINITY || isNaN(num) || num == null) {
			return "——";
		} else {
			return num.toFixed(1);
		}
	},

	fixedPercent2d(percent) {
		if (percent == Number.POSITIVE_INFINITY || isNaN(percent) || percent == null) {
			return "——";
		} else {
			return `${percent.toFixed(2)}%`;
		}
	},

	fixedPercent1d(percent) {
		if (percent == Number.POSITIVE_INFINITY || isNaN(percent) || percent == null) {
			return "——";
		} else {
			return `${percent.toFixed(1)}%`;
		}
	},

	calcPercent1d(num, den) {
		return this.fixedPercent1d((num * 100) / den);
	},

	mimeTypeIcon(mimeType) {
		mimeType = mimeType.split(";")[0];
		switch (mimeType) {
			case "audio/aac":
				return "fa-file-audio";
			case "video/x-msvideo":
				return "fa-file-video";
			case "image/bmp":
				return "fa-file-image";
			case "application/x-bzip":
				return "fa-file-archive";
			case "application/x-bzip2":
				return "fa-file-archive";
			case "text/csv":
				return "fa-file-excel";
			case "application/msword":
				return "fa-file-word";
			case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
				return "fa-file-word";
			case "image/gif":
				return "fa-file-image";
			case "text/html":
				return "fa-file-code";
			case "image/jpeg":
				return "fa-file-image";
			case "video/mpeg":
				return "fa-file-video";
			case "application/vnd.oasis.opendocument.presentation":
				return "fa-file-powerpoint";
			case "application/vnd.oasis.opendocument.spreadsheet":
				return "fa-file-excel";
			case "application/vnd.oasis.opendocument.text":
				return "fa-file-word";
			case "audio/ogg":
				return "fa-file-audio";
			case "video/ogg":
				return "fa-file-video";
			case "image/png":
				return "fa-file-image";
			case "application/pdf":
				return "fa-file-pdf";
			case "application/vnd.ms-powerpoint":
				return "fa-file-powerpoint";
			case "application/vnd.ms-excel":
				return "fa-file-excel";
			case "application/vnd.ms-word":
				return "fa-file-excel";
			case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
				return "fa-file-powerpoint";
			case "application/x-rar-compressed":
				return "fa-file-archive";
			case "application/rtf":
				return "fa-file-alt";
			case "image/svg+xml":
				return "fa-file-image";
			case "application/x-shockwave-flash":
				return "fa-file-video";
			case "image/tiff":
				return "fa-file-image";
			case "text/plain":
				return "fa-file-alt";
			case "audio/wav":
				return "fa-file-audio";
			case "audio/webm":
				return "fa-file-audio";
			case "video/webm":
				return "fa-file-video";
			case "video/mp4":
				return "fa-file-video";
			case "image/webp":
				return "fa-file-image";
			case "application/zip":
				return "fa-file-archive";
			case "application/x-7z-compressed":
				return "fa-file-archive";
			default:
				return "fa-file";
		}
	},

	scanIntervals(i18n) {
		return [
			{
				string: "never",
				label: i18n.t("fs.never"),
			},
			{
				string: "hourly",
				label: i18n.t("fs.hourly"),
			},
			{
				string: "daily",
				label: i18n.t("fs.daily"),
			},
		];
	},

	dateFormat() {
		return vm.$i18n.t("fs.date_format");
	},
	timeFormat() {
		return vm.$i18n.t("fs.time_format");
	},
	timezones: [
		{
			string: "America/Los_Angeles",
			label: `Pacific Time (${now.tz("America/Los_Angeles").format("zz")})`,
		},
		{
			string: "America/Denver",
			label: `Mountain Time (${now.tz("America/Denver").format("zz")})`,
		},
		{
			string: "America/Chicago",
			label: `Central Time (${now.tz("America/Chicago").format("zz")})`,
		},
		{
			string: "America/New_York",
			label: `Eastern Time (${now.tz("America/New_York").format("zz")})`,
		},
	],

	timezone(tzString) {
		let tz = _.find(this.timezones, { string: tzString });
		return tz ? tz.label : vm.$i18n.t("fs.unknown");
	},

	langs: [
		{
			string: "en",
			label: "American English (en-US)",
		},
		{
			string: "en-GB",
			label: "British English (en-GB)",
		},
		{
			string: "en-Acuity",
			label: "Acuity English (en-US)",
		},
		{
			string: "fr",
			label: "French (fr)",
		},
		{
			string: "zh",
			label: "Chinese (zh-CN)",
		},
	],

	intToDay(weekdayInt, abr) {
		switch (weekdayInt) {
			case 0:
				return abr ? vm.$i18n.t("fs.sunday_abr") : vm.$i18n.t("fs.sunday");
			case 1:
				return abr ? vm.$i18n.t("fs.monday_abr") : vm.$i18n.t("fs.monday");
			case 2:
				return abr ? vm.$i18n.t("fs.tuesday_abr") : vm.$i18n.t("fs.tuesday");
			case 3:
				return abr ? vm.$i18n.t("fs.wednesday_abr") : vm.$i18n.t("fs.wednesday");
			case 4:
				return abr ? vm.$i18n.t("fs.thursday_abr") : vm.$i18n.t("fs.thursday");
			case 5:
				return abr ? vm.$i18n.t("fs.friday_abr") : vm.$i18n.t("fs.friday");
			case 6:
				return abr ? vm.$i18n.t("fs.saturday_abr") : vm.$i18n.t("fs.saturday");
			case 7:
				return abr ? vm.$i18n.t("fs.every_day_abr") : vm.$i18n.t("fs.every_day");
		}
	},

	dayToInt(weekday) {
		switch (weekday) {
			case vm.$i18n.t("fs.sunday_abr"):
			case vm.$i18n.t("fs.sunday"):
				return 0;
			case vm.$i18n.t("fs.monday_abr"):
			case vm.$i18n.t("fs.monday"):
				return 1;
			case vm.$i18n.t("fs.tuesday_abr"):
			case vm.$i18n.t("fs.tuesday"):
				return 2;
			case vm.$i18n.t("fs.wednesday_abr"):
			case vm.$i18n.t("fs.wednesday"):
				return 3;
			case vm.$i18n.t("fs.thursday_abr"):
			case vm.$i18n.t("fs.thursday"):
				return 4;
			case vm.$i18n.t("fs.friday_abr"):
			case vm.$i18n.t("fs.friday"):
				return 5;
			case vm.$i18n.t("fs.saturday_abr"):
			case vm.$i18n.t("fs.saturday"):
				return 6;
			case vm.$i18n.t("fs.every_day_abr"):
			case vm.$i18n.t("fs.every_day"):
				return 7;
		}
	},

	slaHours() {
		return [24, 48, 72, 96, 120].map((hour) => ({
			id: hour,
			name: `${hour}`,
		}));
	},

	qcTags() {
		return [
			{
				label: vm.$i18n.t("fs.anchor"),
				abr: vm.$i18n.t("fs.anchor_abr"),
				id: 1,
			},
			{
				label: vm.$i18n.t("fs.further_review"),
				abr: vm.$i18n.t("fs.further_review_abr"),
				id: 2,
			},
			{
				label: vm.$i18n.t("fs.do_not_use"),
				abr: vm.$i18n.t("fs.do_not_use_abr"),
				id: 3,
			},
			{
				label: vm.$i18n.t("fs.practice"),
				abr: vm.$i18n.t("fs.practice_abr"),
				id: 4,
			},
			{
				label: vm.$i18n.t("fs.qualification"),
				abr: vm.$i18n.t("fs.qualification_abr"),
				id: 5,
			},
			{
				label: vm.$i18n.t("fs.validity"),
				abr: vm.$i18n.t("fs.validity_abr"),
				id: 6,
			},
			{
				label: vm.$i18n.t("fs.calibration"),
				abr: vm.$i18n.t("fs.calibration_abr"),
				id: 7,
			},
		];
	},

	qcTagAbrs(tagIDs) {
		return [...tagIDs]
			.sort()
			.map((tagID) => {
				const qc = this.qcTags().find(({ id }) => id === tagID);
				if (qc) return qc.abr;
				return tagID; // Custom Tag
			})
			.join(", ");
	},

	qcTagColor(tag) {
		return `rgb(${tag.r}, ${tag.g}, ${tag.b}) !important`;
	},

	qcTagBgColor(tag) {
		if (tag) {
			return `rgba(${tag.r}, ${tag.g}, ${tag.b}, 0.2) !important`;
		} else {
			return ``;
		}
	},

	qcTagObject(tagID) {
		const tagObject = _.find(this.qcTags(), { id: tagID });
		console.log("ID", tagID, "tagObject", tagObject);
		return tagObject || { id: tagID, label: tagID }; // custom tag;
	},

	scheduleTags() {
		return [
			{
				label: vm.$i18n.t("fs.normal"),
				abr: "N",
				id: 1,
			},
			{
				label: vm.$i18n.t("fs.provisional"),
				abr: "P",
				id: 2,
			},
			{
				label: vm.$i18n.t("fs.reserve"),
				abr: "R",
				id: 3,
			},
			{
				label: vm.$i18n.t("fs.practice"),
				abr: "T",
				id: 4,
			},
		];
	},

	scheduleTagAbrs(tagIDs) {
		let abrs = [];
		let sortedIDs = _.cloneDeep(tagIDs);
		sortedIDs.sort();
		_.each(sortedIDs, (id) => {
			let tag = _.find(this.scheduleTags(), { id: id });
			if (tag) {
				abrs.push(tag.abr);
			} else {
				abrs.push("?");
			}
		});

		return abrs.join(", ");
	},

	scheduleTagObject(tagID) {
		return _.find(this.scheduleTags(), { id: tagID });
	},

	scheduleTypes() {
		return [
			{
				name: vm.$i18n.t("fs.by_hours"),
				id: 1,
			},
			{
				name: vm.$i18n.t("fs.by_shifts"),
				id: 2,
			},
		];
	},

	scheduleExceptionTypes() {
		return [
			{
				name: vm.$i18n.t("fs.unavailable"),
				id: 1,
				sequence: 1,
				active: true,
			},
			{
				name: vm.$i18n.t("fs.partially_available"),
				id: 2,
				sequence: 2,
				active: true,
			},
		];
	},

	weekdayTypes() {
		return [
			{
				name: vm.$i18n.t("fs.sundays"),
				id: 0,
				sequence: 0,
			},
			{
				name: vm.$i18n.t("fs.mondays"),
				id: 1,
				sequence: 1,
			},
			{
				name: vm.$i18n.t("fs.tuesdays"),
				id: 2,
				sequence: 2,
			},
			{
				name: vm.$i18n.t("fs.wednesdays"),
				id: 3,
				sequence: 3,
			},
			{
				name: vm.$i18n.t("fs.thursdays"),
				id: 4,
				sequence: 4,
			},
			{
				name: vm.$i18n.t("fs.fridays"),
				id: 5,
				sequence: 5,
			},
			{
				name: vm.$i18n.t("fs.saturdays"),
				id: 6,
				sequence: 6,
			},
			{
				name: vm.$i18n.t("fs.every_day"),
				desc: vm.$i18n.t("fs.out_of_available_days"),
				id: -2,
				sequence: 7,
			},
			{
				name: vm.$i18n.t("fs.specific_date"),
				desc: vm.$i18n.t("fs.overrides_weekly_shifts"),
				id: -1,
				sequence: 8,
			},
		];
	},

	scheduleThresholdTypes() {
		return [
			{
				name: vm.$i18n.t("fs.no_goals"),
				id: 0,
				sequence: 0,
			},
			{
				name: vm.$i18n.t("fs.total_users"),
				id: 1,
				sequence: 1,
			},
			{
				name: vm.$i18n.t("fs.peak_users"),
				id: 2,
				sequence: 2,
			},
			{
				name: vm.$i18n.t("fs.workhours"),
				id: 3,
				sequence: 3,
			},
		];
	},

	getRecurTypes(weekday) {
		let types = [
			{
				label: vm.$i18n.t("fs.only_this_day"),
				id: "once",
				sequene: 1,
				active: true,
			},
			{
				label: vm.$i18n.t("fs.every_weekday", { weekday: weekday }),
				id: "week",
				sequene: 2,
				active: true,
			},
			{
				label: vm.$i18n.t("fs.every_day"),
				desc: vm.$i18n.t("fs.when_project_is_available"),
				id: "all",
				sequene: 1,
				active: true,
			},
		];

		return types;
	},

	ordinal(num) {
		let suffix = this.ordinalSuffix(num);
		return `${num}${suffix}`;
	},

	ordinalSuffix(num) {
		console.log("calc ordinal suffix", num, typeof num);
		if (!num) {
			return "th";
		}
		if (isNaN(num)) {
			return "";
		}

		//Goofy ultra-compressed function from StackOverflow ;p
		let suffix = ["st", "nd", "rd"][((((num + 90) % 100) - 10) % 10) - 1] || "th";
		return suffix;
	},

	exemplarPositions() {
		return [
			{
				name: vm.$i18n.t("fs.do_not_display"),
				id: 0,
				meta: true,
				sequence: 0,
			},
			{
				name: vm.$i18n.t("fs.above_response"),
				id: 1,
				active: true,
				sequence: 1,
			},
			{
				name: vm.$i18n.t("fs.below_response"),
				id: 2,
				active: true,
				sequence: 2,
			},
			{
				name: vm.$i18n.t("fs.previous_page"),
				id: 3,
				active: false,
				sequence: 3,
			},
			{
				name: vm.$i18n.t("fs.following_page"),
				id: 4,
				active: false,
				sequence: 4,
			},
		];
	},

	quotaTimeframes() {
		return [
			{
				name: vm.$i18n.t("fs.per_day"),
				id: 1,
				active: true,
			},
			{
				name: vm.$i18n.t("fs.overall"),
				id: 2,
				active: true,
			},
			{
				name: vm.$i18n.t("fs.hourly"),
				id: 3,
				active: true,
			},
		];
	},

	quotaScoreTypes() {
		return [
			{
				name: vm.$i18n.t("fs.first_and_second"),
				id: 1,
				sequence: 1,
				active: true,
			},
			{
				name: vm.$i18n.t("fs.resolution"),
				id: 2,
				sequence: 2,
				active: true,
			},
		];
	},

	quotaLimitTypes() {
		return [
			{
				name: vm.$i18n.t("fs.count"),
				id: 0,
				sequence: 0,
				active: true,
			},
			{
				name: vm.$i18n.t("fs.percent"),
				id: 1,
				sequence: 1,
				active: true,
			},
		];
	},

	observationStatusTypes() {
		return [
			{
				name: vm.$i18n.t("fs.not_scheduled"),
				id: 0,
				sequence: 0,
				active: true,
			},
			{
				name: vm.$i18n.t("fs.scheduled"),
				id: 1,
				sequence: 1,
				active: true,
			},
			{
				name: vm.$i18n.t("fs.in_progress"),
				id: 2,
				sequence: 2,
				active: true,
			},
			{
				name: vm.$i18n.t("fs.complete"),
				id: 3,
				sequence: 3,
				active: true,
			},
			{
				name: vm.$i18n.t("fs.no_show"),
				id: 4,
				sequence: 4,
				active: true,
			},
		];
	},

	flagActionTypes() {
		return Object.values(FLAG_ACTION_TYPES);
	},

	stepTypes() {
		return [
			{
				name: vm.$i18n.t("fields.step_1"),
				id: 1,
				sequence: 1,
				active: true,
			},
			{
				name: vm.$i18n.t("fields.step_05"),
				id: 0.5,
				sequence: 2,
				active: true,
			},
			{
				name: vm.$i18n.t("fields.step_025"),
				id: 0.25,
				sequence: 2,
				active: true,
			},
		];
	},

	rubricStyleTypes() {
		return [
			{
				name: vm.$i18n.t("fields.buttons"),
				id: 0,
				sequence: 1,
				active: true,
			},
			{
				name: vm.$i18n.t("fields.slider"),
				id: 1,
				sequence: 2,
				active: true,
			},
		];
	},

	traitResolutionAdjacentTypes() {
		return [
			{
				name: vm.$i18n.t("fields.no_resolution"),
				id: 0,
				sequence: 0,
				active: true,
			},
			{
				name: vm.$i18n.t("fields.exact_adjacent"),
				id: 1,
				sequence: 1,
				active: true,
			},
			{
				name: vm.$i18n.t("fields.exact"),
				id: 2,
				sequence: 2,
				active: true,
			},
		];
	},

	finalScoreTypes() {
		return [
			{
				name: vm.$i18n.t("fs.final_score_average"),
				description: vm.$i18n.t("data_description.final_score_average"),
				id: "average",
			},
			{
				name: vm.$i18n.t("fs.final_score_greater"),
				description: vm.$i18n.t("data_description.final_score_greater"),
				id: "greater",
			},
			{
				name: vm.$i18n.t("fs.final_score_greater_kt"),
				description: vm.$i18n.t("data_description.final_score_greater_kt"),
				id: "greater_kt",
			},
			{
				name: vm.$i18n.t("fs.final_score_kt"),
				description: vm.$i18n.t("data_description.final_score_kt"),
				id: "kt",
			},
			{
				name: vm.$i18n.t("fs.final_score_lesser"),
				description: vm.$i18n.t("data_description.final_score_lesser"),
				id: "lesser",
			},
			{
				name: vm.$i18n.t("fs.final_score_first"),
				description: vm.$i18n.t("data_description.final_score_first"),
				id: "first",
			},
			{
				name: vm.$i18n.t("fs.final_score_first_ignore_br"),
				description: vm.$i18n.t("data_description.final_score_first_ignore_br"),
				id: "first_ignore_br",
			},
			{
				name: vm.$i18n.t("fs.final_score_double"),
				description: vm.$i18n.t("data_description.final_score_double"),
				id: "double",
			},
			{
				name: vm.$i18n.t("fs.final_score_weighted_sum"),
				description: vm.$i18n.t("data_description.final_score_weighted_sum"),
				id: "weighted_sum",
			},
		];
	},

	aiScoringTypes() {
		return [
			{
				name: vm.$i18n.t("ItemEdit.Integrations.writeshift.first"),
				id: "FIRST",
			},
			{
				name: vm.$i18n.t("ItemEdit.Integrations.writeshift.second"),
				id: "SECOND",
			},
			{
				name: vm.$i18n.t("ItemEdit.Integrations.writeshift.resolution"),
				id: "RESOLUTION",
			},
		];
	},

	endpointTypes() {
		return [
			{
				name: vm.$i18n.t("ItemEdit.Integrations.scores_api.no_auth"),
				id: 0,
			},
			{
				name: vm.$i18n.t("ItemEdit.Integrations.scores_api.basic_auth"),
				id: 2,
			},
			{
				name: vm.$i18n.t("ItemEdit.Integrations.scores_api.scorpion"),
				id: 4,
			},
			{
				name: vm.$i18n.t("ItemEdit.Integrations.scores_api.gmac"),
				id: 3,
			},
			{
				name: vm.$i18n.t("ItemEdit.Integrations.scores_api.cognia"),
				id: 5,
			},
			{ name: vm.$i18n.t("ItemEdit.Integrations.scores_api.psi"), id: 6 },
			{ name: vm.$i18n.t("ItemEdit.Integrations.scores_api.pearson"), id: 7 },
			{ name: vm.$i18n.t("ItemEdit.Integrations.scores_api.pearson_accuplacer"), id: 8 },
		];
	},

	autoDeliverTypes() {
		return [
			{
				name: vm.$i18n.t("ItemEdit.Integrations.scores_api.immediately"),
				id: 1,
			},
			{
				name: vm.$i18n.t("ItemEdit.Integrations.scores_api.delay"),
				id: 2,
			},
		];
	},

	schoolCodeModes() {
		if (!vm) {
			return [];
		}
		return [
			{
				id: 0,
				name: vm.$i18n.t("ItemEdit.Scoring.school_code_behavior.exclusive"),
				desc: vm.$i18n.t("ItemEdit.Scoring.school_code_behavior.exclusive_description"),
				sequence: 1,
			},
			{
				id: 1,
				name: vm.$i18n.t("ItemEdit.Scoring.school_code_behavior.inclusive"),
				desc: vm.$i18n.t("ItemEdit.Scoring.school_code_behavior.inclusive_description"),
				sequence: 2,
			},
		];
	},

	rulerUnitTypes() {
		return [
			{
				name: "in.",
				id: 1,
				relative: 1,
			},
			{
				name: "cm",
				id: 2,
				relative: 2.54,
			},
			{
				name: "mm",
				id: 3,
				relative: 25.4,
			},
		];
	},

	validityLockoutBehaviors() {
		if (!vm) {
			return [];
		}
		return [
			{
				id: false,
				name: vm.$i18n.t("ItemEdit.Quality.validity.lockout"),
			},
			{
				id: true,
				name: vm.$i18n.t("ItemEdit.Quality.validity.calibration"),
			},
		];
	},

	validityWindowModes() {
		if (!vm) {
			return [];
		}
		return [
			{ id: false, name: vm.$i18n.t("ItemEdit.Quality.validity.window") },
			{
				id: true,
				name: vm.$i18n.t("ItemEdit.Quality.validity.threshold"),
			},
		];
	},

	colorClasses: ["blue", "green", "red", "yellow", "purple", "orange", "cyan", "black", "white"],
	colorfy(sequence) {
		for (let i = 0; i < sequence.length; i++) {
			let index = i % this.colorClasses.length;
			sequence[i].colorClass = this.colorClasses[index];
		}
	},

	earlierMoment(a, b) {
		if (a && !b) return a;
		if (b && !a) return b;
		return a.isBefore(b) ? a : b;
	},

	laterMoment(a, b) {
		if (a && !b) return a;
		if (b && !a) return b;
		return a.isAfter(b) ? a : b;
	},

	shortTime(time) {
		if (time.minute() == 0) {
			return time.format("ha");
		} else {
			return time.format("h:mma");
		}
	},

	scoreExportType(seType) {
		switch (seType) {
			case 0:
				return vm.$i18n.t("ItemEdit.Integrations.scores_api.no_auth");
			case 1:
				return "(Unknown)";
			case 2:
				return vm.$i18n.t("ItemEdit.Integrations.scores_api.basic_auth");
			case 3:
				return vm.$i18n.t("ItemEdit.Integrations.scores_api.gmac");
			case 4:
				return vm.$i18n.t("ItemEdit.Integrations.scores_api.scorpion");
			case 5:
				return vm.$i18n.t("ItemEdit.Integrations.scores_api.cognia");
			default:
				return "(Unknown)";
		}
	},

	mockAPIResponse(response) {
		return new Promise((resolve) => {
			resolve({ data: response });
		});
	},

	trainingElementType(elType) {
		switch (elType) {
			case 1:
				return vm.$i18n.t("TrainingEdit.scorm_module");
			case 2:
				return vm.$i18n.t("TrainingEdit.resource");
			case 3:
				return vm.$i18n.t("TrainingEdit.qualification");
			case 4:
				return vm.$i18n.t("TrainingEdit.practice_set");
			case 5:
				return vm.$i18n.t("TrainingEdit.exemplar_set");
			default:
				return "(Unknown)";
		}
	},

	trainingElementTypeIcon(elType) {
		const { SCORM, RESOURCE, QUALIFICATION, PRACTICE, EXEMPLAR } = TRAINING_ELEMENT_TYPES;

		switch (elType) {
			case SCORM.id:
				return "fa-balance-scale";
			case RESOURCE.id:
				return "fa-file";
			case PRACTICE.id:
				return "fa-tasks";
			case QUALIFICATION.id:
				return "fa-balance-scale";
			case EXEMPLAR.id:
				return "fa-file-certificate";
		}
		return "";
	},

	trainingElementProgress(prog) {
		switch (prog) {
			case -1:
				return vm.$i18n.t("Training.cannot_continue");
			case 0:
				return vm.$i18n.t("Training.not_started");
			case 1:
				return vm.$i18n.t("Training.in_progress");
			case 2:
				return vm.$i18n.t("Training.complete");
			default:
				return "(Unknown)";
		}
	},

	byteSize(byteSize) {
		const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

		let l = 0,
			n = parseInt(byteSize, 10) || 0;

		while (n >= 1024 && ++l) {
			n = n / 1024;
		}

		return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
	},

	listNames(objects, title) {
		if (objects && objects.length > 0) {
			let nameList = `${sanitizeHtml(title)}:<br /><br /><ul class='pl-4 pr-3'>`;
			_.each(objects, (object) => {
				nameList += `<li style="text-align: left;">${sanitizeHtml(object.name)}</li>`;
			});

			return nameList + "</ul>";
		} else {
			return "";
		}
	},

	spreadsheetColLetter(colIndex) {
		const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		let result = "";
		let digits = this.toBase26Digits(colIndex);
		for (let pos in digits) {
			let digit = digits[pos];
			let letterIndex = parseInt(digit);
			if (pos == 0 && digits.length > 1) {
				letterIndex--;
			}
			result += letters[letterIndex];
		}

		return result;
	},

	toBase26Digits(num) {
		if (num < 26) {
			return [num];
		} else {
			return [...this.toBase26Digits(Math.floor(num / 26)), num % 26];
		}
	},

	subjectTypes() {
		return [
			{
				name: vm.$i18n.t("ItemEdit.IeaItemSetupModal.math"),
				id: "math",
				icon: "fa-superscript",
				sequence: 0,
			},
			{
				name: vm.$i18n.t("ItemEdit.IeaItemSetupModal.science"),
				id: "science",
				icon: "fa-microscope",
				sequence: 1,
			},
			{
				name: vm.$i18n.t("ItemEdit.IeaItemSetupModal.social_studies"),
				id: "social_studies",
				icon: "fa-landmark-alt",
				sequence: 2,
			},
			{
				name: vm.$i18n.t("ItemEdit.IeaItemSetupModal.reading"),
				id: "reading",
				icon: "fa-book-open",
				sequence: 3,
			},
			{
				name: vm.$i18n.t("ItemEdit.IeaItemSetupModal.writing"),
				id: "writing",
				icon: "fa-pencil-alt",
				sequence: 4,
			},
		];
	},

	ieaModelingWorkflows() {
		return [
			{
				name: vm.$i18n.t("ItemEdit.IeaItemSetupModal.economy"),
				desc: vm.$i18n.t("ItemEdit.IeaItemSetupModal.economy_description"),
				id: "economy",
				sequence: 0,
			},
			{
				name: vm.$i18n.t("ItemEdit.IeaItemSetupModal.deluxe"),
				desc: vm.$i18n.t("ItemEdit.IeaItemSetupModal.deluxe_description"),
				id: "deluxe",
				sequence: 1,
			},
		];
	},

	dataTypeIcon(type) {
		let iconMap = {
			string: "fa-i-cursor",
			number: "fa-superscript",
			list: "fa-list",
			boolean: "fa-check",
		};
		if (iconMap[type]) {
			return iconMap[type];
		}
		return "fa-question";
	},

	combineRubricsTypes() {
		return [
			{
				name: vm.$i18n.t("ItemSetup.PreviewerItemImportModal.1_per_item"),
				desc: vm.$i18n.t("ItemSetup.PreviewerItemImportModal.rubric_1_per_item_explanation"),
				id: "1_per_item",
				sequence: 0,
			},
			{
				name: vm.$i18n.t("ItemSetup.PreviewerItemImportModal.combine"),
				desc: vm.$i18n.t("ItemSetup.PreviewerItemImportModal.rubric_combine_explanation"),
				id: "combine",
				sequence: 1,
			},
		];
	},

	combineSectionsTypes() {
		return [
			{
				name: vm.$i18n.t("ItemSetup.PreviewerItemImportModal.none"),
				desc: vm.$i18n.t("ItemSetup.PreviewerItemImportModal.section_none_explanation"),
				id: "none",
				sequence: 0,
			},
			{
				name: vm.$i18n.t("ItemSetup.PreviewerItemImportModal.1_per_item"),
				desc: vm.$i18n.t("ItemSetup.PreviewerItemImportModal.section_1_per_item_explanation"),
				id: "1_per_item",
				sequence: 1,
			},
			{
				name: vm.$i18n.t("ItemSetup.PreviewerItemImportModal.combine"),
				desc: vm.$i18n.t("ItemSetup.PreviewerItemImportModal.section_combine_explanation"),
				id: "combine",
				sequence: 2,
			},
		];
	},
};
