import axios from "axios";
import fs from "@/services/FormatService";
import moment from "moment";

function compileCompletionStats(data) {
	let fromCache = false;
	let oldest = new Date(2050, 0);

	let projects = {};
	_.each(data, (row) => {
		if (!projects[row.pid]) {
			projects[row.pid] = {
				name: row.pn,
				hidden: false,
				sections: {},
				totals: {
					backread: 0,
					complete: 0,
					completew2: 0,
					unscored: 0,
					resolution: 0,
					awaiting: 0,
					reliability: 0,
					withheld: 0,
					flagged: 0,
					flagged_reviewed: 0,
					review: 0,
					resolution_required: 0,
					appeals: 0,
					rescores: 0,
					invalidated: 0,
					total: 0,
				},
			};
		}
		let project = projects[row.pid];

		if (!project.sections[row.sid]) {
			project.sections[row.sid] = {
				name: row.sn,
				items: {},
				totals: {
					backread: 0,
					complete: 0,
					completew2: 0,
					unscored: 0,
					resolution: 0,
					awaiting: 0,
					reliability: 0,
					withheld: 0,
					flagged: 0,
					flagged_reviewed: 0,
					review: 0,
					resolution_required: 0,
					appeals: 0,
					rescores: 0,
					invalidated: 0,
					total: 0,
				},
			};
		}
		let section = project.sections[row.sid];

		let item = {
			id: row.iid,
			name: row.in,
			complete: row.comp,
			completew2: row.comp2,
			unscored: row.unsc,
			resolution: row.res,
			awaiting: row.awr,
			reliability: row.rel,
			withheld: row.with,
			flagged: row.flag,
			flagged_reviewed: row.rev,
			resolution_required: row.rr,
			appeals: row.appeals,
			rescores: row.rescores,
			invalidated: row.inv,
			backread: row.back,
			total: row.tot,
			sequence: row.siseq,
		};

		if (row.time) {
			fromCache = true;
			let time = new Date(row.time);
			if (time < oldest) {
				oldest = time;
			}
		}

		section.totals.complete += item.complete;
		section.totals.completew2 += item.completew2;
		section.totals.unscored += item.unscored;
		section.totals.resolution += item.resolution;
		section.totals.awaiting += item.awaiting;
		section.totals.reliability += item.reliability;
		section.totals.withheld += item.withheld;
		section.totals.flagged += item.flagged;
		section.totals.flagged_reviewed += item.flagged_reviewed;
		section.totals.review += item.review;
		section.totals.resolution_required += item.resolution_required;
		section.totals.rescores += item.rescores;
		section.totals.appeals += item.appeals;
		section.totals.invalidated += item.invalidated;
		section.totals.backread += item.backread;
		section.totals.total += row.tot;

		section.items[row.iid] = item;

		project.totals.complete += item.complete;
		project.totals.completew2 += item.completew2;
		project.totals.unscored += item.unscored;
		project.totals.resolution += item.resolution;
		project.totals.awaiting += item.awaiting;
		project.totals.reliability += item.reliability;
		project.totals.withheld += item.withheld;
		project.totals.flagged += item.flagged;
		project.totals.flagged_reviewed += item.flagged_reviewed;
		project.totals.review += item.review;
		project.totals.resolution_required += item.resolution_required;
		project.totals.rescores += item.rescores;
		project.totals.appeals += item.appeals;
		project.totals.invalidated += item.invalidated;
		project.totals.backread += item.backread;
		project.totals.total += item.total;
	});

	let projectList = [];
	_.each(projects, (project, pid) => {
		project.id = pid;
		let sectionList = [];
		_.each(project.sections, (section, sid) => {
			section.id = sid;
			let itemList = [];
			_.each(section.items, (item, iid) => {
				item.id = iid;
				itemList.push(item);
			});
			section.items = _.sortBy(itemList, ["sequence"]);
			sectionList.push(section);
		});
		project.sections = _.sortBy(sectionList, ["name"]);
		projectList.push(project);
	});

	let ret = { projects: _.sortBy(projectList, ["name"]) };

	if (fromCache) {
		let cacheTime = moment(oldest);
		let currentTime = moment();
		let expiration = currentTime.subtract(1, "minutes");
		if (cacheTime.isBefore(expiration)) {
			ret.expiredCache = true;
		}
	}

	return ret;
}

export default {
	getTrainingStats(section, item, user) {
		return axios.get(`/v1/admin/stat/training/${section}/${item}/${user}`);
	},

	getValidityStats(section, item, user) {
		return axios.get(`/v1/admin/stat/validity/${section}/${item}/${user}`);
	},

	getOverallTrainingStats(section, item) {
		return axios.get(`/v1/admin/stat/overall/training/${section}/${item}`);
	},

	getOverallValidityStats(section, item) {
		return axios.get(`/v1/admin/stat/overall/validity/${section}/${item}`);
	},

	removeTrainingScores(userId, responseId) {
		return axios.get(`/v1/admin/trainingRemove/${userId}/${responseId}`);
	},

	getItemSummaryStats(section, item, scoreTypesLeft, userLeft, scoreTypesRight, userRight, startDate, endDate) {
		let ctx = {
			section_id: section,
			item_id: item,
			stl: scoreTypesLeft,
			user_l: userLeft,
			str: scoreTypesRight,
			user_r: userRight,
		};
		if (startDate) {
			ctx.start_date = startDate;
		}
		if (endDate) {
			ctx.end_date = endDate;
		}

		return axios.post("/v1/admin/stat/summary", ctx);
	},

	getCompletionStatsCached(userOnly) {
		var url = "/v1/admin/stat/completion/cached";
		if (userOnly) {
			url = "/v1/stat/completion/cached";
		}
		return new Promise(function (resolve, reject) {
			axios
				.get(url)
				.then((resp) => {
					var compiledData = compileCompletionStats(resp.data.data);
					if (resp.data.expected > resp.data.data.length) {
						compiledData.expiredCache = true;
					}
					resolve({
						data: compiledData,
					});
				})
				.catch((err) => {
					reject(err);
				});
		});
	},

	getCompletionStats(userOnly, ctx) {
		var url = "/v1/admin/stat/completion";
		if (userOnly) {
			url = "/v1/stat/completion";
		}
		return new Promise(function (resolve, reject) {
			axios
				.get(url, { params: ctx })
				.then((resp) => {
					var compiledData = compileCompletionStats(resp.data);
					resolve({ data: compiledData });
				})
				.catch((err) => {
					reject(err);
				});
		});
	},

	getScorerStats(ctx) {
		return axios.post("/v1/admin/stat/scorers", ctx);
	},

	getPayrollStats(ctx) {
		return axios.post("/v1/admin/stat/payroll", ctx);
	},

	getDailyStats(ctx) {
		return axios.post("/v1/admin/stat/daily", ctx);
	},

	getDailyScoreMgmtStat(project_id, section_id, start_date, end_date) {
		return axios.post(`/v1/admin/stat/daily_score_mgmt/${project_id}`, {
			section_id,
			start_date,
			end_date,
		});
	},

	getSLAReportStats(project_id, section_id, hours) {
		return axios.post(`/v1/admin/stat/sla`, {
			project_id,
			section_id,
			hours,
		});
	},

	getSLAItem(section_id, item_id, hours) {
		return axios.post(`/v1/item/stat/sla`, {
			section_id,
			item_id,
			hours,
		});
	},

	getQuotaStats(ctx) {
		return axios.post("/v1/admin/stat/quotas", ctx);
	},

	getBurndown(ctx) {
		return axios.post("/v1/admin/stat/burndown", ctx);
	},

	getOutstandingByDay(ctx) {
		return axios.post("/v1/admin/stat/burndown/outstanding", ctx);
	},

	getPersonalStats(rt, includeQC) {
		let ctx = { rt: rt.toISOString(true) };
		if (includeQC) {
			ctx.qc = true;
		}
		return axios.get(`/v1/dashboard/personal`, {
			params: ctx,
		});
	},

	getPersonalTrainingStats(rt) {
		const ctx = { rt: rt.toISOString(true) };
		return axios.get(`/v1/dashboard/personal/training`, {
			params: ctx,
		});
	},

	compileItemAgreement(data) {
		let totals = {
			irr_exact: 0,
			irr_exact_adj: 0,
			irr_total: 0,
			val_exact: 0,
			val_exact_adj: 0,
			val_total: 0,
			completed_resps: 0,
		};
		let num = 0;

		_.each(data, (stat) => {
			num++;
			totals.irr_exact += stat.irr_exact;
			totals.irr_exact_adj += stat.irr_exact_adj;
			totals.irr_total += stat.irr_total;

			totals.val_exact += stat.val_exact;
			totals.val_exact_adj += stat.val_exact_adj;
			totals.val_total += stat.val_total;
			totals.completed_resps += stat.completed_resps;

			stat.irr_exact_percent = (stat.irr_exact * 100) / stat.irr_total;
			stat.irr_exact_adj_percent = (stat.irr_exact_adj * 100) / stat.irr_total;

			stat.val_exact_percent = (stat.val_exact * 100) / stat.val_total;
			stat.val_exact_adj_percent = (stat.val_exact_adj * 100) / stat.val_total;

			_.each(stat.traits, (trait) => {
				trait.irr_exact_percent = (trait.irr_exact * 100) / trait.irr_total;
				trait.irr_exact_adj_percent = (trait.irr_exact_adj * 100) / trait.irr_total;

				trait.val_exact_percent = (trait.val_exact * 100) / trait.val_total;
				trait.val_exact_adj_percent = (trait.val_exact_adj * 100) / trait.val_total;
			});
		});

		totals.section_name = `Totals (${num})`;
		totals.item_name = "";

		totals.irr_exact_percent = (totals.irr_exact * 100) / totals.irr_total;
		totals.irr_exact_adj_percent = (totals.irr_exact_adj * 100) / totals.irr_total;

		totals.val_exact_percent = (totals.val_exact * 100) / totals.val_total;
		totals.val_exact_adj_percent = (totals.val_exact_adj * 100) / totals.val_total;

		return { stats: data, totals: totals };
	},

	getAgreementItems(projectID, labels, startDate, endDate) {
		let ctx = { labels: labels };
		if (startDate) {
			ctx.start = startDate;
		}
		if (endDate) {
			ctx.end = endDate;
		}
		return new Promise((resolve, reject) => {
			axios
				.get(`/v1/admin/stat/item_agreement/${projectID}`, {
					params: ctx,
				})
				.then((resp) => {
					var compiledData = this.compileItemAgreement(resp.data.item_stats);
					resolve({ data: compiledData });
				})
				.catch((err) => {
					reject(err);
				});
		});
	},

	getAgreementTeams(teamID, from_date, to_date) {
		const team_ids = [];
		if (teamID) {
			team_ids.push(teamID);
		}
		return new Promise((resolve, reject) => {
			axios
				.post(`/v1/admin/stat/team_agreement`, {
					team_ids,
					from_date,
					to_date,
				})
				.then((resp) => {
					var compiledData = this.compileItemAgreement(resp.data.item_stats);
					resolve({ data: compiledData });
				})
				.catch((err) => {
					reject(err);
				});
		});
	},

	compileUserAgreement(stats) {
		const calcPercents = (stat) => ({
			...stat,
			irr_exact_percent: (stat.irr_exact * 100) / stat.irr_total,
			irr_exact_adj_percent: (stat.irr_exact_adj * 100) / stat.irr_total,
			val_exact_percent: (stat.val_exact * 100) / stat.val_total,
			val_exact_adj_percent: (stat.val_exact_adj * 100) / stat.val_total,
			res_required_percent: (stat.res_required * 100) / stat.res_total,
			res_changed_percent: (stat.res_changed * 100) / stat.res_required,
			traits: stat.traits.map((trait) => ({
				...trait,
				irr_exact_percent: (trait.irr_exact * 100) / trait.irr_total,
				irr_exact_adj_percent: (trait.irr_exact_adj * 100) / trait.irr_total,
				val_exact_percent: (trait.val_exact * 100) / trait.val_total,
				val_exact_adj_percent: (trait.val_exact_adj * 100) / trait.val_total,
				res_required_percent: (trait.res_required * 100) / trait.res_total,
				res_changed_percent: (trait.res_changed * 100) / trait.res_required,
			})),
		});

		const totals = stats.reduce(
			(totals, stat) => {
				totals.irr_exact += stat.irr_exact;
				totals.irr_exact_adj += stat.irr_exact_adj;
				totals.irr_total += stat.irr_total;

				totals.val_exact += stat.val_exact;
				totals.val_exact_adj += stat.val_exact_adj;
				totals.val_total += stat.val_total;

				totals.res_required += stat.res_required;
				totals.res_changed += stat.res_changed;
				totals.res_total += stat.res_total;

				if (totals.traits.length == 0) {
					totals.traits = (stat.traits || []).map(({ trait_id, trait_name }) => ({
						trait_id,
						trait_name,
						irr_exact: 0,
						irr_exact_adj: 0,
						irr_total: 0,
						val_exact: 0,
						val_exact_adj: 0,
						val_total: 0,
						res_required: 0,
						res_changed: 0,
						res_total: 0,
					}));
				}

				totals.traits = totals.traits.map((totalTrait) => {
					const statTrait = stat.traits.find(({ trait_id }) => trait_id === totalTrait.trait_id);
					if (!statTrait) return totalTrait;

					totalTrait.irr_total += stat.irr_total;
					totalTrait.irr_exact += stat.irr_exact;
					totalTrait.irr_exact_adj += stat.irr_exact_adj;

					totalTrait.val_total += stat.val_total;
					totalTrait.val_exact += stat.val_exact;
					totalTrait.val_exact_adj += stat.val_exact_adj;

					totalTrait.res_required += stat.res_required;
					totalTrait.res_changed += stat.res_changed;
					totalTrait.res_total += stat.res_total;

					return totalTrait;
				});

				return totals;
			},
			{
				scorer: `Totals (${stats.length})`,
				irr_exact: 0,
				irr_exact_adj: 0,
				irr_total: 0,
				val_exact: 0,
				val_exact_adj: 0,
				val_total: 0,
				res_required: 0,
				res_changed: 0,
				res_total: 0,
				traits: [],
			}
		);

		console.log(calcPercents(totals));
		return { stats: stats.map((stat) => calcPercents(stat)), totals: calcPercents(totals) };
	},

	getAgreementUsers(sectionID, itemID, userID, teamID, fromDate, toDate) {
		const body = {
			userID,
			teamID,
			fromDate,
			toDate,
		};
		return new Promise((resolve, reject) => {
			axios
				.post(`/v1/admin/stat/user_agreement/${sectionID}/${itemID}`, body)
				.then((resp) => {
					resolve({ data: this.compileUserAgreement(resp.data.user_stats) });
				})
				.catch((err) => {
					reject(err);
				});
		});
	},

	compileBackreadAgreement(data) {
		let totals = {
			irr_exact: 0,
			irr_exact_adj: 0,
			irr_total: 0,
			score_total: 0,
		};
		let num = 0;

		_.each(data, (stat) => {
			num++;
			stat.scorer = fs.scorerID(stat.user);
			totals.irr_exact += stat.irr_exact;
			totals.irr_exact_adj += stat.irr_exact_adj;
			totals.irr_total += stat.irr_total;

			stat.irr_exact_percent = (stat.irr_exact * 100) / stat.irr_total;
			stat.irr_exact_adj_percent = (stat.irr_exact_adj * 100) / stat.irr_total;

			if (stat.traits && stat.traits.length) {
				stat.score_total = stat.irr_total / stat.traits.length;
			}

			_.each(stat.traits, (trait) => {
				trait.irr_exact_percent = (trait.irr_exact * 100) / trait.irr_total;
				trait.irr_exact_adj_percent = (trait.irr_exact_adj * 100) / trait.irr_total;
			});
		});

		totals.scorer = `Totals (${num})`;
		totals.irr_exact_percent = (totals.irr_exact * 100) / totals.irr_total;
		totals.irr_exact_adj_percent = (totals.irr_exact_adj * 100) / totals.irr_total;

		if (data[0] && data[0].traits && data[0].traits.length) {
			totals.score_total = totals.irr_total / data[0].traits.length;
		}

		return { stats: data, totals: totals };
	},

	getBackreadAgreement(sectionID, itemID, userID, againstUserID) {
		let ctx = {};
		if (userID || againstUserID) {
			ctx.params = {};
			if (userID) {
				ctx.params.userID = userID;
			}
			if (againstUserID) {
				ctx.params.againstUserID = againstUserID;
			}
		}
		return new Promise((resolve, reject) => {
			axios
				.get(`/v1/admin/stat/backread_agreement/${sectionID}/${itemID}`, ctx)
				.then((resp) => {
					var compiledData = this.compileBackreadAgreement(resp.data);
					resolve({ data: compiledData });
				})
				.catch((err) => {
					reject(err);
				});
		});
	},

	getIngestPredictions(projectID) {
		return axios.get(`/v1/admin/stat/ingest_predictions/${projectID}`);
	},

	saveIngestPredictions(ingestRows) {
		return axios.post(`/v1/admin/stat/ingest_predictions`, ingestRows);
	},

	removeIngestPrediction(predictionID) {
		return axios.delete(`/v1/admin/stat/ingest_predictions/${predictionID}`);
	},

	getAttendance(userID) {
		return axios.get(`/v1/admin/stat/attendance/${userID}`);
	},

	//Group score stats into projects and dates
	compileAttendanceScoreStats(scoreStats) {
		let statMap = {};
		_.each(scoreStats, (ss) => {
			if (!statMap[ss.project_id]) {
				statMap[ss.project_id] = {};
			}
			if (!statMap[ss.project_id][ss.date]) {
				statMap[ss.project_id][ss.date] = {
					score_time: 0,
					scores: 0,
					res: 0,
				};
			}

			statMap[ss.project_id][ss.date].score_time += ss.score_time;
			statMap[ss.project_id][ss.date].scores += ss.scores - ss.res;
			statMap[ss.project_id][ss.date].res += ss.res;
		});

		return statMap;
	},

	//Split time stats into separate days
	compileAttendanceTimeStats(timeStats) {
		let statMap = {};
		_.each(timeStats, (ts) => {
			let splitStats = this.splitSessionTimes(ts.login, ts.logout);
			_.each(splitStats, (stat) => {
				if (!statMap[stat.date]) {
					statMap[stat.date] = 0;
				}
				statMap[stat.date] += stat.time;
			});
		});

		return statMap;
	},

	splitSessionTimes(start, end) {
		let startMoment = moment(start);
		let endMoment = moment(end);

		let times = [];
		let iter = startMoment.clone();
		for (;;) {
			if (iter.date() != endMoment.date()) {
				let endOfDay = iter.clone().endOf("day");
				let time = endOfDay.diff(iter, "seconds");
				times.push({ date: iter.format("MM/DD/YYYY"), time: time });

				iter.add(1, "days").startOf("day");
			} else {
				let time = endMoment.diff(iter, "seconds");
				times.push({ date: iter.format("MM/DD/YYYY"), time: time });
				break;
			}
		}

		return times;
	},

	getAttendanceDays(userID) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/v1/admin/stat/attendance/${userID}`)
				.then((resp) => {
					let compiledScores = this.compileAttendanceScoreStats(resp.data.scores);
					let compiledTimes = this.compileAttendanceTimeStats(resp.data.times);
					resolve({ data: { scores: compiledScores, times: compiledTimes } });
				})
				.catch((err) => {
					reject(err);
				});
		});
	},

	getTenantSubmittedRespCounts(from_date, to_date, exported, unique_refs) {
		return axios.post(`/v1/dashboard/tenant/submitted_resp_counts`, {
			from_date,
			to_date,
			exported,
			unique_refs,
		});
	},

	getTenantRespCounts(lastWeek, exported, uniqueRefs) {
		return axios.get(
			`/v1/dashboard/tenant/resp_count?lastWeek=${lastWeek}&export=${exported}&uniqueRefs=${uniqueRefs}`
		);
	},
};
