<!-- Template for a new page -->
<template>
	<page :title="$t('Scoring.title')">
		<template #navbar>
			<div class="btn-group pl-3 mr-2 pr-1 ml-auto topnav-margins">
				<button class="btn white" data-toggle="dropdown" aria-expanded="false">
					<i v-if="sortMode == 'sequence'" class="fas fa-sort faw"></i>
					<i v-if="sortMode == 'alpha'" class="fas fa-sort-alpha-down faw"></i>
					<i v-if="sortMode == 'urgency'" class="fas fa-calendar-times faw"></i>
				</button>
				<div class="dropdown-menu dropdown-menu-right">
					<div class="dropdown-item disabled">
						<strong>{{ $t("Scoring.sort_items_in_sections") }}</strong>
					</div>
					<div class="dropdown-divider"></div>
					<a
						class="dropdown-item"
						@click="sortMode = 'sequence'"
						:class="{ 'is-active': sortMode == 'sequence' }"
					>
						<i class="far fa-sort faw"></i>
						<label class="mb-0">{{ $t("Scoring.sequence") }}</label>
					</a>
					<a class="dropdown-item" @click="sortMode = 'alpha'" :class="{ 'is-active': sortMode == 'alpha' }">
						<i class="far fa-sort-alpha-down faw"></i>
						<label class="mb-0">{{ $t("Scoring.alphabetical") }}</label>
					</a>
					<a
						v-if="client && client.sla_enabled"
						class="dropdown-item"
						@click="sortMode = 'urgency'"
						:class="{ 'is-active': sortMode == 'urgency' }"
					>
						<i class="far fa-calendar-times faw"></i>
						<label class="mb-0">{{ $t("Scoring.urgent_first") }}</label>
					</a>
				</div>
			</div>
			<a
				v-if="client && client.survey_enabled"
				class="navbar-brand right survey-btn b-l"
				target="_blank"
				:href="client.survey_url"
				>{{ $t("Scoring.survey") }}</a
			>
		</template>

		<loading type="page" v-if="loading" />
		<div class="content scroll-y" v-else>
			<div class="padding">
				<!-- Page content goes here -->
				<div v-if="!sections.length" class="box section m-auto no-assignments">
					<div class="box-header theme lighten-3">{{ $t("Scoring.no_assignments") }}</div>
					<div class="box-body light lt text-center p-4">
						<p class="m-0">{{ $t("Scoring.not_assigned_work") }}</p>
					</div>
				</div>

				<div class="row" ref="sectionsContainer" v-if="sections.length">
					<div class="col-md-6 section-card" v-for="section in sections" :key="section.id">
						<div class="box section">
							<div class="box-header theme lighten-3 d-flex flex-row">
								<span class="wrap-text flex">{{ section.name }}</span>
								<div class="d-flex flex-column b-l section-header-icon-container">
									<template v-if="section.project.enforce_dates || section.project.enforce_times">
										<div
											class="section-header-icon"
											style="flex-basis: 33%"
											v-tippy="{
												theme: 'popover',
												html: `#pa-${section.id}`,
												placement: 'left-end',
											}"
										>
											<i
												class="fa"
												:class="{
													[available(section.project)
														? 'fa-calendar-check'
														: 'fa-calendar-exclamation']: true,
												}"
											></i>
										</div>
										<div class="d-none p-0" :id="`pa-${section.id}`">
											<project-availability :project="section.project" :client="client" />
										</div>
									</template>
									<div
										class="b-t b-b section-header-icon section-header-button"
										style="flex-basis: 33%"
										v-tippy="{ placement: 'left-end' }"
										:title="checkForResponsesTooltip()"
										@click="checkForResponses(section)"
									>
										<i class="fa fa-sync" :class="{ 'fa-spin': section.checkingForResponses }"></i>
									</div>
									<div
										v-if="!user.role.prevent_auto_enter"
										class="section-header-icon section-header-button"
										style="flex-basis: 33%"
										v-tippy="{ placement: 'left-end' }"
										:title="autoEnterTooltip()"
										@click="openAutoEnterModal(section)"
									>
										<i class="fa fa-eye"></i>
									</div>
								</div>
							</div>
							<div
								v-if="!section.items || !section.items.length"
								class="box-body light lt text-center p-4"
							>
								<p class="m-0">This section has no items.</p>
							</div>
							<div v-if="section.items && section.items.length" class="box-body light lt p-0">
								<table class="table">
									<thead>
										<tr>
											<th>{{ $t("fields.item") }}</th>
											<th v-if="sortMode == 'urgency'">{{ $t("Scoring.urgency") }}</th>
											<th>{{ $t("fields.resources") }}</th>
											<th style="text-align: center">{{ $t("fields.scoring") }}</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="item in section.items" :key="item.id">
											<td class="v-mid">
												<div class="wrap-text">{{ item.name }}</div>
												<div v-if="getCount(item) > 0" class="text-muted text-xs">
													<span
														v-if="!item.assigned_delivery"
														style="white-space: nowrap"
														v-tippy="{ placement: 'bottom' }"
														:title="$t('tooltip.scores_submitted')"
													>
														<i class="fas fa-check mr-1 text-muted"></i>
														{{ getCount(item) }}
													</span>
													<span
														v-if="item.assigned_delivery"
														style="white-space: nowrap"
														v-tippy="{ placement: 'bottom' }"
														:title="$t('tooltip.scores_submitted_and_assigned')"
													>
														<i class="fas fa-check mr-1 text-muted"></i>
														{{ getCount(item) }} /
														{{ getAssignedCount(item) }}
													</span>
													<span class="mx-1">|</span>
													<span
														style="white-space: nowrap"
														v-tippy="{ placement: 'bottom' }"
														:title="$t('tooltip.time_spent_scoring')"
													>
														<i class="fas fa-clock mr-1 text-muted"></i>
														{{ getTime(item) }}
													</span>
												</div>
											</td>
											<td v-if="sortMode == 'urgency'" class="v-mid">
												<div
													v-if="item.urgency && item.outstanding > 0"
													:id="`sla-${section.id}-${item.id}`"
												>
													<div>
														<table v-if="item.sla_rows">
															<tr>
																<th>{{ $t("Scoring.hours") }}</th>
																<th>{{ $t("Scoring.number_of_responses") }}</th>
															</tr>
															<tr v-for="(row, i) in item.sla_rows" :key="i">
																<td v-if="row.hours < 0" class="text-danger _600">
																	{{ $t("Scoring.past_due") }}
																</td>
																<td v-else>{{ row.hours }}</td>
																<td class="v-mid text-center _600">{{ row.count }}</td>
															</tr>
														</table>
														<loading v-else type="icon" class="my-3 mx-4" />
													</div>
												</div>

												<div>
													<template v-if="!item.urgency">
														<loading type="icon" />
													</template>
													<template v-else-if="item.outstanding > 0">
														<span
															v-if="item.urgency_days < 0"
															class="text-danger _600"
															v-tippy="{
																html: `#sla-${section.id}-${item.id}`,
																reactive: true,
																theme: 'popover',
															}"
															@show="loadSLAItem(section, item)"
														>
															{{ $t("Scoring.past_due") }}
														</span>
														<span
															v-else-if="item.urgency_days > 0"
															v-tippy="{
																html: `#sla-${section.id}-${item.id}`,
																reactive: true,
																theme: 'popover',
															}"
															@show="loadSLAItem(section, item)"
														>
															{{
																$tc("fields.n_days", item.urgency_days, {
																	n: item.urgency_days,
																})
															}}
															and
															{{
																$tc("fields.n_hours", item.urgency_hours, {
																	n: item.urgency_hours,
																})
															}}
														</span>
														<span
															v-else
															class="text-warning"
															v-tippy="{
																html: `#sla-${section.id}-${item.id}`,
																reactive: true,
																theme: 'popover',
															}"
															@show="loadSLAItem(section, item)"
														>
															{{
																$tc("fields.n_hours", item.urgency_hours, {
																	n: item.urgency_hours,
																})
															}}
														</span>
													</template>
													<template v-else>
														<div class="text-success _600">
															{{ $t("Scoring.complete") }}
														</div>
													</template>
												</div>
											</td>
											<td class="v-mid">
												<button
													v-if="!item.active && user.role.limit_inactive_resources"
													class="btn btn-icon btn-rounded btn-secondary lighten-3 disabled"
													v-tippy
													:title="inactiveItemResourcesTooltip()"
													aria-expanded="false"
												>
													<i class="fas fa-lg fa-file-alt"></i>
												</button>
												<button
													v-else
													class="btn btn-icon btn-rounded theme text-white lighten-3"
													data-toggle="dropdown"
													v-tippy="{ delay: [500, 0] }"
													:title="$t('buttons.resources')"
													aria-expanded="false"
												>
													<i class="fas fa-lg fa-file-alt"></i>
												</button>
												<div class="dropdown-menu">
													<div class="dropdown-header text-theme text-center b-b mb-2">
														{{ $t("tooltip.resources") }}
													</div>
													<div
														v-if="
															(!item.resources || item.resources.length == 0) &&
															(!item.exemplar_sets || item.exemplar_sets.length == 0)
														"
													>
														<h6 class="text-muted text-center">
															{{ $t("data_description.no_resources") }}
														</h6>
													</div>
													<template v-for="res in item.resources">
														<template v-if="item.resources && item.resources.length > 0">
															<a
																v-if="Utils.isRenderableResource(res)"
																:key="res.id"
																class="dropdown-item"
																@click="viewResource(item, res)"
															>
																<i
																	class="far fa-lg faw"
																	:class="{
																		[fs.mimeTypeIcon(res.mime_type)]: true,
																	}"
																></i>
																<label class="mb-0 emph-label">{{ res.name }}</label>
															</a>
															<a
																v-if="
																	!Utils.isRenderableResource(res) &&
																	!user.role.prevent_resource_download
																"
																:key="res.id"
																class="dropdown-item theme-hover d-flex flex-row align-items-center"
																@click="downloadResource(item, res)"
															>
																<i
																	class="far fa-lg faw"
																	:class="{
																		[fs.mimeTypeIcon(res.mime_type)]: true,
																	}"
																></i>
																<div class="flex d-flex flex-column">
																	<div>
																		<span class="flex emph-label">{{
																			res.name
																		}}</span>
																	</div>
																	<div
																		class="text-xxs text-muted mb-1"
																		style="line-height: 0.8"
																	>
																		{{ $t("ExternalResource.download_only") }}
																	</div>
																</div>
																<i
																	class="far ml-3 fa-lg fa-download text-extra-muted float-right"
																	style="margin-right: -16px"
																	@click="viewResource(res)"
																></i>
															</a>
															<div
																v-if="
																	!Utils.isRenderableResource(res) &&
																	user.role.prevent_resource_download
																"
																:key="res.id"
																class="dropdown-item"
															>
																<div>
																	<i
																		class="far fa-lg faw"
																		:class="{
																			[fs.mimeTypeIcon(res.mime_type)]: true,
																		}"
																	></i>
																	<span class="flex emph-label">{{ res.name }}</span>
																</div>
																<div class="text-xxs text-muted">
																	{{
																		$t("ExternalResource.cannot_render_or_download")
																	}}
																</div>
															</div>
														</template>
													</template>
													<hr
														class="my-2"
														v-if="
															item.resources &&
															item.resources.length > 0 &&
															item.exemplar_sets &&
															item.exemplar_sets.length > 0
														"
													/>
													<template v-for="set in item.exemplar_sets">
														<a
															v-if="item.exemplar_sets && item.exemplar_sets.length > 0"
															:key="set.id"
															class="dropdown-item"
															target="_blank"
															:href="`#/exemplar_set/${section.id}/${item.id}/${set.id}/${isForTraining}`"
														>
															<i class="far fa-lg fa-file-certificate"></i>
															<label class="mb-0 emph-label">{{ set.name }}</label>
														</a>
													</template>
												</div>
											</td>
											<!-- <td class="">
													<strong>Scores:</strong> {{getCount(section.id, item.id)}}<br/>
													<strong>Time:</strong> {{getTime(section.id, item.id)}}
												</td>-->
											<td>
												<div class="d-flex flex-row flex-wrap justify-content-center">
													<span
														v-if="
															user.role.flag_queue_scoring &&
															item.alerts &&
															item.alerts.length > 0
														"
														class="button-pad"
													>
														<a
															class="btn btn-icon btn-rounded lighten-2 theme text-white"
															data-toggle="dropdown"
															v-tippy
															:title="$t('tooltip.flags')"
															aria-expanded="false"
															@click="loadAlertCounts(section, item)"
														>
															<i v-if="!item.loading_flag" class="material-icons tiny">{{
																$t("Scoring.flag")
															}}</i>
															<loading v-if="item.loading_flag" type="icon" />
														</a>
														<div class="dropdown-menu">
															<div
																class="dropdown-header text-theme text-center b-b mb-2"
															>
																{{ $t("tooltip.flag_queues") }}
															</div>
															<button
																v-if="item.alerts.length > 1"
																class="dropdown-item"
																@click="enterFlagQueue(section, item)"
															>
																<label class="mb-0 emph-label">{{
																	$t("buttons.all")
																}}</label>
															</button>
															<hr class="my-2" />
															<template v-for="alert in item.alerts">
																<button
																	v-if="
																		!alert.restricted_review ||
																		user.role.review_restricted_flags
																	"
																	:key="alert.id"
																	class="dropdown-item"
																	@click="enterFlagQueue(section, item, alert.id)"
																>
																	<div
																		class="d-flex flex-row align-items-center emph-label"
																	>
																		<i
																			v-if="!item.loading_flag"
																			class="far fa-flag"
																		/>
																		<span class="mr-auto">{{
																			alert.description || alert.code
																		}}</span>
																		<span
																			v-if="alert.count != undefined"
																			class="text-xxs text-muted ml-2"
																			style="margin-bottom: 1px"
																			>({{ alert.count }})</span
																		>
																	</div>
																</button>
															</template>
														</div>
													</span>

													<span
														v-if="user.role.rf_score && item.rf_sets.length > 0"
														class="button-pad"
													>
														<a
															class="btn btn-icon btn-rounded lighten-2 theme text-white"
															data-toggle="dropdown"
															v-tippy
															:title="$t('tooltip.rangefinding')"
															aria-expanded="false"
														>
															<i class="fa far fa-drafting-compass"></i>
														</a>
														<div class="dropdown-menu">
															<div
																class="dropdown-header text-theme text-center b-b mb-2"
															>
																{{ $t("tooltip.rangefinding_sets") }}
															</div>
															<template v-for="set in item.rf_sets">
																<button
																	v-if="set.num_responses > 0"
																	:key="set.id"
																	class="dropdown-item"
																	@click="goToRfSet(set)"
																>
																	<label class="mb-0 emph-label">
																		<i class="fa far fa-drafting-compass" />
																		{{ set.name }}
																	</label>
																</button>
																<button v-else :key="set.id" class="dropdown-item">
																	<label class="mb-0 emph-label text-strikethrough">
																		<i class="fa far fa-drafting-compass" />
																		{{ set.name }}
																	</label>
																	<small class="text-muted"
																		>({{ $t("data_description.empty") }})</small
																	>
																</button>
															</template>
														</div>
													</span>

													<span
														v-if="
															item.uses_practice &&
															item.practice_sets &&
															item.practice_sets.length > 0
														"
														class="button-pad"
													>
														<a
															class="btn btn-icon btn-rounded lighten-2 theme text-white"
															data-toggle="dropdown"
															v-tippy
															:title="$t('tooltip.practice')"
															aria-expanded="false"
														>
															<i class="fa far fa-user-graduate"></i>
														</a>
														<div class="dropdown-menu">
															<div
																class="dropdown-header text-theme text-center b-b mb-2"
															>
																{{ $t("tooltip.practice_sets") }}
															</div>
															<template v-for="set in item.practice_sets">
																<button
																	v-if="set.active"
																	:key="set.id"
																	class="dropdown-item"
																	@click="goToPracticeSet(set)"
																>
																	<label class="mb-0 emph-label">
																		<i class="fa far fa-user-graduate" />
																		{{ set.name }}
																	</label>
																</button>
															</template>
														</div>
													</span>

													<span v-if="user.role.has_appeal" class="button-pad">
														<button
															@click="enterAppeal(section, item)"
															:disabled="item.loading_adj"
															class="btn btn-icon btn-rounded lighten-2 theme text-white button-pad"
															v-tippy
															:title="appealTooltip(section, item)"
															:class="{
																'text-extra-muted':
																	!sectionAvailable(section) ||
																	!item.active ||
																	getRemainingAppeal(item) == 0 ||
																	appealOutOfWork(item),
															}"
														>
															<i class="material-icons tiny">account_balance</i>
															<loading v-if="item.loading_appeal" type="icon" />
														</button>
													</span>

													<span v-if="user.role.page_backreading" class="button-pad">
														<a
															:href="
																item.active && `#/backreading/${section.id}/${item.id}`
															"
															class="btn btn-icon btn-rounded lighten-2 theme text-white button-pad"
															:class="{ 'text-extra-muted': !item.active }"
															v-tippy
															:title="backreadingTooltip(section, item)"
														>
															<i class="material-icons tiny">verified_user</i>
														</a>
													</span>
													<span
														v-if="
															item.adjudication_enabled &&
															user.role.has_adjudication &&
															isResolutionType(section, item) // check if this item even does resolution
														"
														class="button-pad"
													>
														<button
															@click="enterAdjudication(section, item)"
															:disabled="item.loading_adj"
															class="btn btn-icon btn-rounded lighten-2 theme text-white button-pad"
															v-tippy
															:title="adjudicationTooltip(section, item)"
															:class="{
																'text-extra-muted':
																	!sectionAvailable(section) ||
																	!item.active ||
																	getRemainingAdj(item) == 0 ||
																	adjOutOfWork(item),
															}"
														>
															<i class="material-icons tiny">gavel</i>
															<loading v-if="item.loading_adj" type="icon" />
														</button>
													</span>
													<span
														v-if="
															user.role.has_resolution && isResolutionType(section, item)
														"
														class="button-pad"
													>
														<button
															@click="enterResolution(section, item)"
															:disabled="item.loading_res"
															class="btn btn-icon btn-rounded lighten-2 theme text-white button-pad"
															v-tippy
															:title="resolutionTooltip(section, item)"
															:class="{
																'text-extra-muted':
																	!sectionAvailable(section) ||
																	!item.active ||
																	getRemainingRes(item) == 0 ||
																	resOutOfWork(item),
															}"
														>
															<i v-if="!item.loading_res" class="material-icons tiny"
																>done_all</i
															>
															<loading v-if="item.loading_res" type="icon" />
														</button>
													</span>
													<span class="button-pad">
														<button
															v-if="!item.assigned_delivery"
															@click="enterScoring(section, item)"
															:disabled="item.loading"
															class="btn btn-icon btn-rounded theme text-white button-pad"
															v-tippy
															:title="defaultScoreTooltip(section, item)"
															:class="{
																'text-extra-muted lighten-2':
																	!sectionAvailable(section) ||
																	!item.active ||
																	getRemainingLive(item) == 0 ||
																	defaultOutOfWork(item),
															}"
														>
															<i v-if="!item.loading" class="material-icons tiny"
																>check</i
															>
															<loading v-if="item.loading" type="icon" />
														</button>
														<button
															v-else
															:disabled="item.loading"
															data-toggle="dropdown"
															class="btn btn-icon btn-rounded theme text-white button-pad"
															v-tippy
															:title="defaultScoreTooltip(section, item)"
															:class="{
																'text-extra-muted lighten-2':
																	!sectionAvailable(section) ||
																	!item.active ||
																	getRemainingLive(item) == 0 ||
																	defaultOutOfWork(item),
															}"
														>
															<div v-if="!item.loading">
																<i class="material-icons tiny" style="margin-top: -3px"
																	>check</i
																>
																<div style="margin-top: 4px">...</div>
															</div>
															<loading v-if="item.loading" type="icon" />
														</button>
														<div class="dropdown-menu">
															<div
																class="dropdown-header text-theme text-center b-b mb-2"
															>
																{{ $t("tooltip.enter_scoring") }}
															</div>
															<a
																class="dropdown-item"
																@click="enterScoring(section, item)"
															>
																<i class="far fa-lg fa-arrow-right"></i>
																<label class="mb-0 emph-label">{{
																	$t("Scoring.queue")
																}}</label>
															</a>
															<a
																class="dropdown-item"
																@click="goToAssignedResponses(section, item)"
															>
																<i class="far fa-lg fa-bullseye-pointer"></i>
																<label class="mb-0 emph-label">{{
																	$t("Scoring.assigned_response_list")
																}}</label>
															</a>
														</div>
													</span>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<b-modal id="autoEnterModal" size="lg" :visible="autoEnterJob != false" @hide="autoEnterJob = false">
			<template v-if="autoEnterJob" slot="modal-header">
				<h5 class="modal-title pl-3">
					{{ $t("Scoring.auto-enter") }} <span class="text-muted">({{ autoEnterJob.section.name }})</span>
				</h5>
			</template>

			<div v-if="autoEnterJob" class="modal-scroll">
				<div class="mb-3">
					{{ $t("Scoring.auto-enter_choose_items") }}
				</div>
				<table class="table border">
					<thead>
						<tr>
							<th v-if="!autoEnterJob.timer">
								<label class="md-check">
									<input
										type="checkbox"
										:checked="autoEnterNumSelectedItems == autoEnterJob.items.length"
										@click="autoEnterToggleAllItems"
									/>
									<i class="theme-accent"></i>
								</label>
							</th>
							<th style="width: 50%">{{ $t("Scoring.item") }}</th>
							<th v-if="!autoEnterJob.initializing" style="width: 50%">{{ $t("Scoring.status") }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in autoEnterJob.items" :key="item.id">
							<template v-if="!autoEnterJob.timer || item.selected">
								<template v-if="autoEnterJob.initializing">
									<td v-if="!autoEnterJob.timer" style="width: 34px; padding-right: 0px">
										<label class="md-check">
											<input type="checkbox" :checked="true" disabled />
											<i class="theme-accent"></i>
										</label>
									</td>
									<td class="text-muted">{{ item.name }}</td>
									<td><loading type="icon" /></td>
								</template>
								<template v-else>
									<td v-if="!autoEnterJob.timer" style="width: 34px; padding-right: 0px">
										<label class="md-check">
											<input type="checkbox" v-model="item.selected" :disabled="item.disabled" />
											<i class="theme-accent"></i>
										</label>
									</td>
									<td v-if="item.disabled" class="text-muted">{{ item.name }}</td>
									<td v-else @click="item.selected = !item.selected" class="clickable">
										{{ item.name }}
									</td>
									<td v-if="item.checking">
										{{ $t("Scoring.checking") }}<loading type="icon" class="ml-1" />
									</td>
									<td
										v-else-if="item.outOfWork"
										class="text-danger"
										:class="{ 'text-muted': item.disabled }"
									>
										{{ item.outOfWork }}
									</td>
									<td v-else class="text-success">{{ $t("Scoring.available") }}</td>
								</template>
							</template>
						</tr>
					</tbody>
				</table>
				<div v-if="autoEnterJob.items.length == 0">
					{{ $t("Scoring.auto-enter_choose_items") }}
				</div>
			</div>

			<template v-if="autoEnterJob" slot="modal-footer">
				<button class="btn btn-secondary btn-flat" @click="cancelAutoEnter">{{ $t("buttons.cancel") }}</button>
				<button
					v-if="!autoEnterJob.timer"
					class="btn btn-success btn-flat"
					@click="doAutoEnterJob"
					:disabled="autoEnterNumSelectedItems == 0"
				>
					{{ $t("buttons.auto-enter") }}
				</button>
				<button v-else class="btn btn-secondary btn-flat auto-enter-check-button-animate" :disabled="true">
					{{ $t("Scoring.checking") }}<loading type="icon" class="ml-1" />
				</button>
			</template>
		</b-modal>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.no-assignments {
	max-width: 600px;
}
.section > .box-header {
	text-align: center;
	font-size: 28px;
}
.section-header-icon-container {
	margin-right: -1rem;
	margin-top: -1rem;
	margin-bottom: -1rem;
	border-color: rgba(255 255 255 / 35%);
}
.section-header-icon {
	border-color: rgba(255 255 255 / 35%);
	padding-left: 4px;
	padding-right: 4px;
	font-size: 16px;
	opacity: 1;
}
.section-header-button {
	cursor: pointer;
	transition: background-color 0.25s;
}
.section-header-button:hover {
	background-color: rgba(0, 0, 0, 0.35);
}
.section-header-button:focus {
	background-color: rgba(0, 0, 0, 0.35);
}
.button-pad {
	padding: 0.1rem;
}

.survey-btn {
	margin-top: -8px;
	margin-bottom: -8px;
	margin-right: -16px;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 16px;
	padding-right: 16px;
	height: 56px;
	line-height: 56px;
	color: inherit;
	text-align: center;
	cursor: pointer;
}
.survey-btn:hover {
	background-color: rgba(0, 0, 0, 0.075);
}

.clickable {
	cursor: pointer;
}

.auto-enter-check-button-animate {
	width: 150px;
	animation: check-progress 61s infinite forwards linear;
}

@keyframes check-progress {
	0% {
		box-shadow: inset 150px 0px 2px -2px rgba(0 0 0 / 50%);
	}
	100% {
		box-shadow: inset 0px 0px 2px -2px rgba(0 0 0 / 50%);
	}
}
</style>

<script>
//Libraries
import _ from "lodash";

//Services
import AuthService from "@/services/AuthService";
import ResourceService from "@/services/ResourceService";
import ScoringService from "../../services/ScoringService";
import ProjectService from "../../services/ProjectService";
import ResGroupService from "../../services/ResGroupService";
import QCService from "@/services/QCService";
import TenantService from "@/services/TenantService";
import ReportingService from "@/services/ReportingService";

import ProjectAvailability from "@/components/ProjectAvailability";

import NormalScoringDriver from "../../services/ScoringDrivers/NormalScoringDriver";
import ResolutionDriver from "../../services/ScoringDrivers/ResolutionDriver";
import AdjudicationDriver from "../../services/ScoringDrivers/AdjudicationDriver";
import AppealDriver from "../../services/ScoringDrivers/AppealDriver";
import FlagQueueDriver from "../../services/ScoringDrivers/FlagQueueDriver";
import PracticeDriver from "@/services/ScoringDrivers/PracticeDriver";
import RFDriver from "@/services/ScoringDrivers/RFDriver";
import Store from "@/services/Store";

import { RESPONSE_STATES } from "@/services/Constants";

import fs from "../../services/FormatService";
import notie from "../../services/NotieService";
import Utils from "../../services/Utils";
import BB from "bluebird";
import moment from "moment";
import Masonry from "masonry-layout";

export default {
	name: "Scoring",

	props: ["user"],

	components: { ProjectAvailability },

	data() {
		return {
			Utils: Utils,
			fs: fs,

			loading: true,
			sections: [],
			section_stats: {},
			resGroupMap: {},
			client: null,
			masonry: null,

			autoEnterJob: false,
			isForTraining: false,

			sortMode: Store.getDefault(this, `Scoring.sortMode.${this.user.client.id}`, "sequence"),
		};
	},

	computed: {
		autoEnterNumSelectedItems() {
			if (!this.autoEnterJob) {
				return 0;
			}
			if (this.autoEnterJob.items.length == 0) {
				return 0;
			}

			let numSelected = 0;
			for (let item of this.autoEnterJob.items) {
				if (item.selected) {
					numSelected++;
				}
			}
			return numSelected;
		},
	},

	watch: {
		sortMode() {
			this.loadData();
			Store.set(this, `Scoring.sortMode.${this.user.client.id}`, this.sortMode);
		},
	},

	created() {
		this.loadData();
	},

	methods: {
		loadData() {
			if (this.sortMode == "urgency") {
				this.loading = true;
			}

			BB.props({
				sectionResp: ScoringService.mySections(this.sortMode),
				statsResp: ScoringService.sectionStats(),
				practiceSetsResp: QCService.getMyPracticeSets(),
				exemplarSetsResp: QCService.getMyExemplarSets(),
				rfSetsResp: QCService.getMyRfSets(),
				clientResp: TenantService.getClient(),
				resGroupsResp: ResGroupService.listResGroups(),
			})
				.then((results) => {
					this.client = results.clientResp.data;

					if (this.sortMode == "urgency" && !this.client.sla_enabled) {
						console.log("NO URGENCY", this.client);
						this.sortMode = "sequence";
					}

					setTimeout(() => {
						let sections = results.sectionResp.data;
						switch (this.sortMode) {
							case "alpha":
								for (let section of sections) {
									section.items = _.sortBy(section.items, "name");
								}
								this.sections = _.sortBy(sections, "name");
								break;
							case "urgency":
								for (let section of sections) {
									for (let i in section.items) {
										if (section.items[i].urgency == "0001-01-01T00:00:00Z") {
											section.items[i].urgency = "9999-01-01T00:00:00Z";
										}
									}

									section.items = _.sortBy(section.items, "urgency");
									if (section.items && section.items.length > 0) {
										section.urgency = section.items[0].urgency;

										let now = moment();
										for (let item of section.items) {
											if (item.outstanding > 0) {
												let expires = moment(item.urgency).add(this.client.sla_hours, "hours");
												let diffHours = expires.diff(now, "hours");
												item.urgency_hours = diffHours % 24;
												item.urgency_days = Math.floor(diffHours / 24);
											}
										}
									}
								}
								sections = _.sortBy(sections, "urgency");
								break;
							case "sequence":
							default:
								for (let section of sections) {
									section.items = _.sortBy(section.items, "section_item_sequence");
								}
								sections = _.sortBy(sections, "name");
								break;
						}
						this.sections = sections;
						this.section_stats = results.statsResp.data;
						let alerts = {};
						_.each(this.client.alerts, (alert) => {
							alerts[alert.id] = alert;
						});

						let resGroups = results.resGroupsResp.data.res_groups;
						let resGroupMap = {};
						_.each(resGroups, (rg) => {
							_.each(rg.elements, (el) => {
								let slug = `${el.section_id}-${el.item_id}`;
								resGroupMap[slug] = rg;
							});
						});
						this.resGroupMap = resGroupMap;

						_.each(this.sections, (s) => {
							_.each(s.items, (i) => {
								i.exemplar_sets = [];
								i.practice_sets = [];
								i.rf_sets = [];
								i.alerts = [];

								_.each(i.alert_ids, (alert_id) => {
									if (alerts[alert_id]) {
										i.alerts.push(_.cloneDeep(alerts[alert_id]));
									} else {
										notie.error("Error loading flag codes", "Please contact your administrator");
										console.error(
											`Item ${i.name} has alert id ${alert_id} that does not exist on client`
										);
									}
								});

								_.each(results.exemplarSetsResp.data, (set) => {
									if (set.section_id == s.id && set.item_id == i.id && set.active) {
										i.exemplar_sets.push(set);
									}
								});
								i.exemplar_sets = _.sortBy(i.exemplar_sets, (t) => {
									return t.sequence;
								});

								_.each(results.practiceSetsResp.data, (set) => {
									if (set.section_id == s.id && set.item_id == i.id) {
										i.practice_sets.push(set);
									}
								});
								i.practice_sets = _.sortBy(i.practice_sets, (t) => {
									return t.sequence;
								});

								_.each(results.rfSetsResp.data, (set) => {
									if (set.section_id == s.id && set.item_id == i.id && set.active) {
										i.rf_sets.push(set);
									}
								});
								i.rf_sets = _.sortBy(i.rf_sets, (t) => {
									return t.sequence;
								});

								i.stat = _.find(this.section_stats, {
									section_id: s.id,
									item_id: i.id,
								});
							});

							s.project = _.find(this.client.projects, {
								id: s.project_id,
							});
						});

						this.loading = false;

						this.$nextTick(() => {
							this.masonry = new Masonry(this.$refs.sectionsContainer, {
								itemSelector: ".section-card",
								gutter: 0,
							});
						});
					}, 1);
				})
				.catch((err) => {
					console.log(err);
					notie.error("Failed to load sections for scoring", err);
				});
		},

		loadSLAItem(section, item) {
			this.debug("loadSLAItem", section.id, item.id);
			ReportingService.getSLAItem(section.id, item.id, 120)
				.then((r) => {
					this.debug("got loadSLAItem", r.data);
					item.sla_rows = _.sortBy(r.data, "hours");
					this.$forceUpdate();
				})
				.catch((e) => {
					this.logError(e);
					notie.error("Failed to load item SLA stats", e);
				});
		},

		checkForResponsesTooltip() {
			return `${this.$i18n.t("Scoring.check_for_responses")}
<br />
<span class="text-xxs text-muted" style="line-height: 1">${this.$i18n.t(
				"Scoring.check_for_responses_explanation"
			)}</span>`;
		},

		autoEnterTooltip() {
			return `${this.$i18n.t("Scoring.auto-enter")}
<br />
<span class="text-xxs text-muted" style="line-height: 1">${this.$i18n.t("Scoring.auto-enter_explanation")}</span>`;
		},

		async checkForResponses(section) {
			this.$set(section, "checkingForResponses", true);
			try {
				const { data } = await NormalScoringDriver.checkSectionForResponses(section.id);
				section.checkingForResponses = false;
				section.items.forEach((item) => {
					item.outOfWork = data
						.filter(({ item_id, code }) => item_id === item.id && code != 200)
						.reduce((map, itemRes) => {
							const { resp_state, msg } = itemRes;
							map[resp_state] = msg;
							return map;
						}, {});
				});
				this.$forceUpdate();
			} catch (e) {
				section.checkingForResponses = false;
				console.log(e);
				notie.error("Failed to check for responses", e);
				this.$forceUpdate();
			}
		},

		async openAutoEnterModal(section) {
			this.autoEnterJob = {
				section: section,
				items: [],
				lastChecked: -1,
				running: false,
				timer: null,
				initializing: true,
			};

			for (let item of section.items) {
				let newItem = {
					id: item.id,
					name: item.name,
					selected: true,
					disabled: false,
					status: "",
					checking: false,
				};

				this.autoEnterJob.items.push(newItem);
			}

			try {
				const { data } = await NormalScoringDriver.checkSectionForResponses(section.id);
				this.autoEnterJob.initializing = false;

				this.autoEnterJob.items.forEach((item) => {
					const result = data.find(
						({ item_id, resp_state }) => resp_state === RESPONSE_STATES.UNSCORED.id && item_id == item.id
					);
					if (!result) return;

					if (result.code == 200) {
						item.outOfWork = false;
						item.selected = false;
					} else {
						item.outOfWork = result.msg;
						if (
							result.msg == "You are not qualified to score this item." ||
							result.msg == "No available QC set"
						) {
							item.selected = false;
							item.disabled = true;
						}
					}
				});
				this.$forceUpdate();
			} catch (e) {
				this.autoEnterJob.initializing = false;
				console.log(e);
				notie.error("Failed to check for responses", err);
				this.$forceUpdate();
			}
		},

		doAutoEnterJob() {
			// We'll infinitely loop if there are no items to check, so let's prevent that first of all
			if (this.autoEnterNumSelectedItems == 0) {
				return;
			}

			let checkNextItem = () => {
				let currentTime = new Date().getTime();
				if (this.autoEnterJob.timerStarted && currentTime - this.autoEnterJob.timerStarted > 60000) {
					this.cancelAutoEnter();
					return;
				}

				let i = this.autoEnterJob.lastChecked;
				if (i >= 0) {
					this.autoEnterJob.items[i].checking = false;
				}
				let checkItem = null;
				while (!checkItem) {
					i = (i + 1) % this.autoEnterJob.items.length;
					if (this.autoEnterJob.items[i].selected) {
						checkItem = this.autoEnterJob.items[i];
						this.autoEnterJob.lastChecked = i;
					}
				}

				let sectionID = this.autoEnterJob.section.id;
				let itemID = checkItem.id;

				checkItem.checking = true;
				NormalScoringDriver.getNext({
					section_id: sectionID,
					item_id: itemID,
				})
					.then((resp) => {
						this.cancelAutoEnter();
						this.$router.push(`/scoring/${sectionID}/${itemID}`);
					})
					.catch((e) => {
						let errMsg = notie.extractErrorMessage(e);
						checkItem.outOfWork = errMsg;
					});
			};

			this.autoEnterJob.timerStarted = new Date().getTime();
			this.autoEnterJob.currentTime = null;
			checkNextItem();
			this.autoEnterJob.timer = setInterval(checkNextItem, 1000);
			this.$nextTick(() => {
				this.autoEnterJob.doAnim = true;
				this.$forceUpdate();
			});
		},

		cancelAutoEnter() {
			if (!this.autoEnterJob) {
				return;
			}

			if (this.autoEnterJob.timer) {
				for (let item of this.autoEnterJob.items) {
					item.checking = false;
				}
				clearInterval(this.autoEnterJob.timer);
				this.autoEnterJob.timer = null;
				this.autoEnterJob.lastChecked = -1;
			} else {
				this.autoEnterJob = false;
			}
		},

		autoEnterToggleAllItems() {
			if (!this.autoEnterJob) return;

			let maxSelectable = 0;
			for (let item of this.autoEnterJob.items) {
				if (!item.disabled) {
					maxSelectable++;
				}
			}

			if (this.autoEnterNumSelectedItems >= maxSelectable) {
				for (let item of this.autoEnterJob.items) {
					item.selected = false;
				}
			} else {
				for (let item of this.autoEnterJob.items) {
					if (!item.disabled) {
						item.selected = true;
					}
				}
			}
		},

		getCount(item) {
			if (item.stat) {
				return item.stat.count;
			}
			return 0;
		},

		getAssignedCount(item) {
			if (item.stat) {
				return item.stat.assigned;
			}
			return 0;
		},

		getTime(item) {
			if (item.stat) {
				return fs.medDuration(item.stat.time);
			}
			return this.$i18n.t("Scoring.default_time");
		},

		getRemainingLive(item) {
			if (item.stat) {
				return item.stat.remaining_live;
			}
			return 1;
		},

		getRemainingRes(item) {
			if (item.stat) {
				return item.stat.remaining_res;
			}
			return 1;
		},

		getRemainingAdj(item) {
			if (item.stat) {
				return item.stat.remaining_adj;
			}
			return 1;
		},

		getRemainingAppeal(item) {
			if (item.stat) {
				return item.stat.remaining_appeal;
			}
			return 1;
		},

		isResolutionType(section, item) {
			let obj = item;
			if (obj.live_res) {
				return false;
			}

			let slug = `${section.id}-${item.id}`;
			let resGroup = this.resGroupMap[slug];
			if (resGroup) {
				obj = resGroup;
			}

			return [
				"DOUBLE_EXACT",
				"DOUBLE_EXACT_ADJACENT",
				"DOUBLE_WEIGHTED",
				"THREE_READS",
				"OMAHA",
				"ADJACENT_EXACT_UNDER_THREE",
				"TRAIT_RESOLUTION",
			].includes(obj.type);
		},

		enterScoring(section, item) {
			item.loading = true;
			this.$forceUpdate();
			NormalScoringDriver.getNext({
				section_id: section.id,
				item_id: item.id,
			})
				.then((resp) => {
					this.$router.push(`/scoring/${section.id}/${item.id}`);
					item.loading = false;
					this.$forceUpdate();
				})
				.catch((err) => {
					const errMsg = notie.extractErrorMessage(err);
					notie.error(this.$i18n.t("notie.cannot_score_item", { item: item.name }), err);
					item.loading = false;
					this.setOutOfWork(item, RESPONSE_STATES.UNSCORED.id, errMsg);
					this.$forceUpdate();
				});
		},

		enterResolution(section, item) {
			item.loading_res = true;
			this.$forceUpdate();
			ResolutionDriver.getNext({
				section_id: section.id,
				item_id: item.id,
			})
				.then((resp) => {
					this.$router.push(`/resolution/${section.id}/${item.id}`);
					item.loading_res = false;
					this.$forceUpdate();
				})
				.catch((err) => {
					notie.error(`Cannot enter resolution for ${item.name}`, err);
					item.loading_res = false;
					this.setOutOfWork(item, RESPONSE_STATES.RESOLUTION.id, notie.extractErrorMessage(err));
					this.$forceUpdate();
				});
		},

		enterAdjudication(section, item) {
			item.loading_adj = true;
			this.$forceUpdate();
			AdjudicationDriver.getNext({
				section_id: section.id,
				item_id: item.id,
			})
				.then((resp) => {
					this.$router.push(`/adjudication/${section.id}/${item.id}`);
					item.loading_adj = false;
					this.$forceUpdate();
				})
				.catch((err) => {
					notie.error(`Cannot enter adjudication for ${item.name}`, err);
					item.loading_adj = false;
					this.setOutOfWork(item, RESPONSE_STATES.ADJUDICATION.id, notie.extractErrorMessage(err));
					this.$forceUpdate();
				});
		},

		enterAppeal(section, item) {
			item.loading_adj = true;
			this.$forceUpdate();
			AppealDriver.getNext({
				section_id: section.id,
				item_id: item.id,
			})
				.then((resp) => {
					this.$router.push(`/appeal/${section.id}/${item.id}`);
					item.loading_adj = false;
					this.$forceUpdate();
				})
				.catch((err) => {
					notie.error(`Cannot enter appeal for ${item.name}`, err);
					item.loading_adj = false;
					this.setOutOfWork(item, RESPONSE_STATES.APPEAL.id, notie.extractErrorMessage(err));
					this.$forceUpdate();
				});
		},

		enterFlagQueue(section, item, alertID) {
			if (!alertID) {
				alertID = "all";
			}
			item.loading_flag = true;
			this.$forceUpdate();
			FlagQueueDriver.getNext({
				section_id: section.id,
				item_id: item.id,
				alert_id: alertID,
			})
				.then((resp) => {
					this.$router.push(`/flag_queue/${section.id}/${item.id}/${alertID}`);
					item.loading_flag = false;
					this.$forceUpdate();
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.cannot_enter_flag_queue", { item: item.name }), err);
					item.loading_flag = false;
					this.$forceUpdate();
				});
		},

		goToPracticeSet(set) {
			PracticeDriver.getNext({
				item_id: set.item_id,
				section_id: set.section_id,
				set_id: set.id,
			})
				.then((resp) => {
					this.$router.push(`/practice/${set.section_id}/${set.item_id}/${set.id}`);
				})
				.catch((e) => {
					const { response } = e;
					if (response.status === 500) {
						notie.error("Cannot Enter Practice", response.data);
					} else {
						this.$router.push(`/qc_set_report/${set.id}`);
					}
				});
		},

		goToRfSet(set) {
			RFDriver.getNext({
				item_id: set.item_id,
				section_id: set.section_id,
				set_id: set.id,
			})
				.then((resp) => {
					this.$router.push(`/rangefinding/${set.section_id}/${set.item_id}/${set.id}`);
				})
				.catch((e) => {
					let code = e.response.status;
					let msg = e.response.data;
					console.log("CODE", code);
					console.log("error", { error: e });
					if (code == 404 && msg == "Rangefinding Complete") {
						this.$router.push(`/rf_set_report/${set.id}`);
					} else {
						notie.error(this.$i18n.t("notie.cannot_enter_rangefinding"), e);
					}
				});
		},

		loadAlertCounts(section, item) {
			ScoringService.loadAlertCounts(section.id, item.id).then((resp) => {
				const { data } = resp;
				_.each(item.alerts, (alert) => {
					alert.count = 0;
					const count = data[alert.id];
					if (count) {
						alert.count = count.count;
					}
				});
				this.$forceUpdate();
			});
		},

		viewResource(item, resource) {
			window.open(`#/resource/${item.id}/${resource.id}`);
		},

		downloadResource(item, resource) {
			ResourceService.downloadResource(item, resource);
		},

		available(project) {
			return ProjectService.checkIfAvailable(project, this.client.timezone);
		},

		goToAssignedResponses(section, item) {
			this.$router.push(`/assigned_responses/${section.id}/${item.id}`);
		},

		sectionAvailable(section) {
			if (section.available == undefined) {
				let timezone = this.client.timezone;
				if (!timezone) {
					timezone = "America/New_York";
				}
				moment.tz.setDefault(timezone);

				section.available = ProjectService.checkIfAvailable(section.project, timezone);
			}

			return section.available;
		},

		defaultScoreTooltip(section, item) {
			return this.scoreTooltip(section, item, RESPONSE_STATES.UNSCORED.id);
		},

		scoreTooltip(section, item, respType) {
			let defaultText = this.$i18n.t("tooltip.score");
			if (this.getRemainingLive(item) == 0) {
				defaultText = `<span class="text-muted">${this.$i18n.t("tooltip.no_scores_remaining")}</span>`;
			} else if (this.itemOutOfWork(item, respType)) {
				defaultText = this.outOfWorkTooltip(this.itemOutOfWork(item, respType));
			}

			return this.tooltipHelper(section, item, defaultText);
		},

		setOutOfWork(item, respType, text) {
			if (!item.outOfWork) item.outOfWork = {};
			item.outOfWork[respType] = text;
		},

		defaultOutOfWork(item) {
			return this.itemOutOfWork(item, RESPONSE_STATES.UNSCORED.id);
		},

		resOutOfWork(item) {
			return this.itemOutOfWork(item, RESPONSE_STATES.RESOLUTION.id);
		},

		adjOutOfWork(item) {
			return this.itemOutOfWork(item, RESPONSE_STATES.ADJUDICATION.id);
		},

		appealOutOfWork(item) {
			return this.itemOutOfWork(item, RESPONSE_STATES.APPEAL.id);
		},

		itemOutOfWork(item, respType) {
			return (item.outOfWork || {})[respType];
		},

		outOfWorkTooltip(text) {
			return `<div>${this.$i18n.t("tooltip.out_of_work")}</div>
				<div class="text-muted text-xxs">${text === "Out of work!" ? "No available responses" : text}</div>`;
		},

		resolutionTooltip(section, item) {
			let defaultText = this.$i18n.t("tooltip.resolution");
			if (this.resOutOfWork(item)) defaultText = this.outOfWorkTooltip(this.resOutOfWork(item));
			if (this.getRemainingRes(item) === 0)
				defaultText = `<span class="text-muted">${this.$i18n.t("tooltip.no_resolution_scores_remaining")}</span>`;

			return this.tooltipHelper(section, item, defaultText);
		},

		adjudicationTooltip(section, item) {
			let defaultText = this.$i18n.t("tooltip.adjudication");
			if (this.adjOutOfWork(item)) defaultText = this.outOfWorkTooltip(this.adjOutOfWork(item));
			return this.tooltipHelper(section, item, defaultText);
		},

		appealTooltip(section, item) {
			let defaultText = this.$i18n.t("tooltip.appeal");
			if (this.appealOutOfWork(item)) defaultText = this.outOfWorkTooltip(this.appealOutOfWork(item));
			return this.tooltipHelper(section, item, defaultText);
		},

		backreadingTooltip(section, item) {
			const defaultText = this.$i18n.t("tooltip.backreading");
			return this.tooltipHelper(section, item, defaultText);
		},

		tooltipHelper(section, item, defaultText) {
			if (!this.sectionAvailable(section)) {
				return `<span class="text-muted">${this.$i18n.t("tooltip.project_not_available")}</span>`;
			}
			if (!item.active) {
				return `<span class="text-muted">${this.$i18n.t("tooltip.item_inactive")}</span>`;
			}
			return defaultText;
		},

		inactiveItemResourcesTooltip() {
			return `<span class="text-muted">${this.$i18n.t("tooltip.inactive_item_resources")}</span>`;
		},
	},
};
</script>
