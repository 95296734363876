<template>
	<div ref="img" class="image-box">
		<div v-if="imageLoaded == null" class="grid-center padding">
			<loading type="large" class="grid-overlap text-extra-muted" style="line-height: 0"> </loading>
			<i class="fas fa-file-image fa-lg text-extra-muted grid-overlap" v-tippy title="Loading image..." />
		</div>
		<div v-else-if="imageLoaded == false" class="grid-center padding">
			<div class="text-center">
				<i class="fas fa-file-code fa-2x text-extra-muted" />
				<div class="text-extra-muted font-initial">Image failed to load</div>
			</div>
		</div>

		<canvas v-if="!forPrinting" v-show="imageLoaded" :id="page.id" style="margin-bottom: -8px"></canvas>
		<img v-else ref="altImage" :src="page.url" style="max-width: 100%" />

		<template v-for="ar in annotationRegions">
			<div
				:key="`box-${ar.timestamp}`"
				class="annotation-box"
				:class="[`_highlight-box-${ar.annotation.color}`, { hovered: ar.hovered }]"
				:style="{
					left: `${ar.boxLeft * totalScale}px`,
					top: `${ar.boxTop * totalScale}px`,
					width: `${ar.boxWidth * totalScale}px`,
					height: `${ar.boxHeight * totalScale}px`,
				}"
				:id="`box-${ar.timestamp}`"
				@mouseenter="applyHoverToGroup(ar.timestamp)"
				@mouseleave="applyUnhoverToGroup(ar.timestamp)"
			></div>
			<div
				:key="`controls-${ar.timestamp}`"
				class="annotation-margin"
				:style="{
					left: `${(ar.boxLeft + ar.boxWidth) * totalScale + 8}px`,
					top: `${ar.boxTop * totalScale}px`,
				}"
				:id="`overlay-${ar.timestamp}`"
				@mouseenter="applyHoverToGroup(ar.timestamp)"
				@mouseleave="applyUnhoverToGroup(ar.timestamp)"
			>
				<div
					class="annotation-side-border"
					:class="{ [`highlight-${ar.annotation.color}`]: true }"
					:style="{ height: `${ar.boxHeight * totalScale}px` }"
					:id="`overlay-border-${ar.timestamp}`"
					v-tippy="{
						placement: 'top-end',
						theme: 'popover',
						interactive: true,
						reactive: true,
						hideOnClick: false,
						trigger: 'hover',
						html: `#overlay-popup-${ar.timestamp}`,
					}"
				></div>
				<div :id="`overlay-popup-${ar.timestamp}`" class="overlay-popup">
					<span v-if="!ar.editing" v-html="ar.annotation.text"></span>
					<div class="d-flex flex-row" v-if="ar.editing">
						<RichText
							:initial="annotationInput"
							:text.sync="annotationOutput"
							:options="{ height: 104 }"
							class="flex mb-0"
						/>
						<div class="pl-2 ml-2 border-left d-flex flex-column">
							<div class="flex d-flex flex-row">
								<div class="flex d-flex flex-column">
									<button
										class="btn-xs btn-icon btn-rounded mb-1 highlight-blue"
										:class="{
											selected: ar.annotation.color == 'blue',
										}"
										@click="changeHighlightColor(ar, 'blue')"
									></button>
									<button
										class="btn-xs btn-icon btn-rounded mb-1 highlight-red"
										:class="{
											selected: ar.annotation.color == 'red',
										}"
										@click="changeHighlightColor(ar, 'red')"
									></button>
									<button
										class="btn-xs btn-icon btn-rounded mb-1 highlight-yellow"
										:class="{
											selected: ar.annotation.color == 'yellow',
										}"
										@click="changeHighlightColor(ar, 'yellow')"
									></button>
								</div>
								<div class="flex d-flex flex-column ml-1">
									<button
										class="btn-xs btn-icon btn-rounded mb-1 highlight-green"
										:class="{
											selected: ar.annotation.color == 'green',
										}"
										@click="changeHighlightColor(ar, 'green')"
									></button>
									<button
										class="btn-xs btn-icon btn-rounded mb-1 highlight-orange"
										:class="{
											selected: ar.annotation.color == 'orange',
										}"
										@click="changeHighlightColor(ar, 'orange')"
									></button>
									<button
										class="btn-xs btn-icon btn-rounded mb-1 highlight-purple"
										:class="{
											selected: ar.annotation.color == 'purple',
										}"
										@click="changeHighlightColor(ar, 'purple')"
									></button>
								</div>
							</div>
							<div class="btn btn-sm" @click="saveAnnotationRegion(ar)">Save</div>
						</div>
					</div>
				</div>
				<div
					v-if="ar.editable"
					class="position-absolute"
					style="top: 0; left: -8px; width: 10px"
					:style="{ height: `${ar.boxHeight * totalScale}px` }"
				>
					<a
						class="btn-subtle overlay-btn position-absolute"
						style="left: 8px; font-size: 18px"
						:style="{ transform: `rotate(-${rotate}deg)` }"
						@click="toggleEditAnnotation(ar)"
					>
						<i class="fa-comment" :class="{ [ar.editing ? 'fas' : 'far']: true }" />
					</a>
					<a
						class="btn-subtle overlay-btn position-absolute"
						style="font-size: 18px"
						:style="{
							left: ar.boxHeight >= 56 ? '8px' : '36px',
							top: ar.boxHeight >= 56 ? '28px' : '0',
							transform: `rotate(-${rotate}deg)`,
						}"
						@click="removeAnnotationRegion(ar.timestamp)"
					>
						<i class="far fa-trash" />
					</a>
				</div>
				<div
					v-if="!ar.editable"
					class="position-absolute"
					style="top: 0; left: -8px; width: 10px"
					:style="{ height: `${ar.boxHeight * totalScale}px` }"
				>
					<a class="overlay-btn position-absolute" style="font-size: 18px; left: 10px">
						<i class="far fa-comment-lines" />
					</a>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import RichText from "@/components/RichText";
import store from "../../services/Store";
import ds from "../../services/DrawingService";
import $ from "jquery";

export default {
	name: "ImageMedia",
	props: {
		page: true,
		chan: true,
		item: true,
		annotations: true,
		forPrinting: { type: Boolean, default: false },
	},
	components: { RichText },
	data() {
		return {
			imageLoaded: null,

			events: [],
			sizes: [0.5, 0.65, 0.8, 1, 1.2, 1.5, 1.8, 2],
			rotate: 0,
			zoomScale: 0,
			totalScale: 0,
			invert: false,
			image: null,
			angleEnabled: false,
			angleState: {},
			rulerEnabled: false,
			rulerState: {},
			// page: this.page

			selectingHighlight: false,
			annotationRegions: [],
			annotationInput: null,
			annotationOutput: null,
			annotationOrigColor: "blue",

			selectionBox: null,
			hX1: null,
			hY1: null,
			hX2: null,
			hY2: null,
		};
	},

	watch: {},

	created() {
		if (!this.chan) {
			return;
		}

		this.chan.$emit("angle-off");
		this.chan.$emit("ruler-off");

		var zoom = () => {
			this.drawImage();
		};
		this.events.push({ key: "zoom-in", val: zoom });
		this.events.push({ key: "zoom-out", val: zoom });
		this.chan.$on("zoom-in", zoom);
		this.chan.$on("zoom-out", zoom);

		//Invert
		var invert = () => {
			var ele = $(this.$refs.img);
			this.invert = !this.invert;
			if (this.invert) {
				ele.css("filter", "invert(100%)");
			} else {
				ele.css("filter", "invert(0%)");
			}
		};
		this.events.push({ key: "invert", val: invert });
		this.chan.$on("invert", invert);

		//Rotate
		var rotate = () => {
			var ele = $(this.$refs.img);
			this.rotate += 90;
			ele.css("transform", "rotate(" + this.rotate + "deg)");

			this.positionAnnotationTooltips();
		};
		this.events.push({ key: "rotate", val: rotate });
		this.chan.$on("rotate", rotate);

		var disableAngle = (element) => {
			this.angleState = {};
			$(element).css("cursor", "default");
			$(element).off("click");
			this.angleEnabled = false;
			this.chan.$emit("angle-off");
			this.drawImage();
		};

		var disableRuler = (element) => {
			this.rulerState = {};
			$(element).css("cursor", "default");
			$(element).off("click");
			this.rulerEnabled = false;
			this.chan.$emit("ruler-off");
			this.drawImage();
		};

		//Angle
		var angle = () => {
			var element = $(this.$refs.img);
			//If ruler is in use, cancel it
			if (this.rulerEnabled) {
				disableRuler(element);
			}
			//If we're already enabled, disable
			if (this.angleEnabled) {
				disableAngle(element);
				return;
			}
			this.angleEnabled = true;
			this.chan.$emit("angle-on");

			$(element).css("cursor", "crosshair");
			// console.log(state);
			$(element).on("click", (event) => {
				// console.log(deg);
				// console.log(state);
				var canv = $("#" + this.page.id).get(0);
				var ctx = canv.getContext("2d");

				var clickX = event.offsetX;
				var clickY = event.offsetY;

				if (!this.angleState.firstClick) {
					this.angleState.firstClick = {
						x: clickX,
						y: clickY,
					};
					ds.drawCrossPoint(ctx, clickX, clickY);
				} else if (!this.angleState.secondClick) {
					this.angleState.secondClick = {
						x: clickX,
						y: clickY,
					};
					ds.drawLine(ctx, this.angleState.firstClick, this.angleState.secondClick);
					ds.drawCrossPoint(ctx, clickX, clickY);
				} else if (!this.angleState.thirdClick) {
					this.angleState.thirdClick = {
						x: clickX,
						y: clickY,
					};
					ds.drawLine(ctx, this.angleState.secondClick, this.angleState.thirdClick);
					ds.drawCrossPoint(ctx, clickX, clickY);
					var angle =
						ds.find_angle(
							this.angleState.firstClick,
							this.angleState.thirdClick,
							this.angleState.secondClick
						) *
						(180 / Math.PI);
					var rounded = Math.round(angle * 100) / 100;
					$(element).css("cursor", "not-allowed");

					ds.drawText(
						ctx,
						rounded + "°",
						this.angleState.secondClick.x,
						this.angleState.secondClick.y,
						canv.width,
						canv.height
					);
					console.log(angle);
				} else {
					this.angleState = {};
					$(element).css("cursor", "crosshair");
					this.drawImage();
				}
				console.log(this.angleState);
			});
		};
		this.events.push({ key: "angle", val: angle });
		this.chan.$on("angle", angle);

		//Ruler
		var ruler = () => {
			var element = $(this.$refs.img);
			//If angle is in use, cancel it
			if (this.angleEnabled) {
				disableAngle(element);
			}
			//If we're already enabled, disable
			if (this.rulerEnabled) {
				disableRuler(element);
				return;
			}
			this.rulerEnabled = true;
			this.chan.$emit("ruler-on");

			$(element).css("cursor", "crosshair");
			// console.log(state);
			$(element).on("click", (event) => {
				// console.log(deg);
				// console.log(state);
				var canv = $("#" + this.page.id).get(0);
				var ctx = canv.getContext("2d");

				var clickX = event.offsetX;
				var clickY = event.offsetY;

				if (!this.rulerState.firstClick) {
					this.rulerState.firstClick = {
						x: clickX,
						y: clickY,
					};
					ds.drawCrossPoint(ctx, clickX, clickY);
				} else if (!this.rulerState.secondClick) {
					this.rulerState.secondClick = {
						x: clickX,
						y: clickY,
					};
					ds.drawLine(ctx, this.rulerState.firstClick, this.rulerState.secondClick);
					ds.drawCrossPoint(ctx, clickX, clickY);
					var distance = ds.find_distance(
						this.rulerState.firstClick,
						this.rulerState.secondClick,
						this.totalScale,
						this.item
					);
					$(element).css("cursor", "not-allowed");

					var unitStr = "";
					var rounded = distance;
					if (this.item.ruler_units == 1) {
						unitStr = '"';
						rounded = Math.round(distance * 10) / 10;
					} else if (this.item.ruler_unit == 2) {
						unitStr = "cm";
						rounded = Math.round(distance * 10) / 10;
					} else if (this.item.ruler_units == 3) {
						unitStr = "mm";
						rounded = Math.round(distance);
					}

					ds.drawText(
						ctx,
						rounded + unitStr,
						this.rulerState.secondClick.x,
						this.rulerState.secondClick.y,
						canv.width,
						canv.height
					);
					console.log(distance);
				} else {
					this.rulerState = {};
					$(element).css("cursor", "crosshair");
					this.drawImage();
				}
				console.log(this.rulerState);
			});
		};
		this.events.push({ key: "ruler", val: ruler });
		this.chan.$on("ruler", ruler);

		this.chan.$on("annotation", this.toggleAnnotation);
		this.chan.$on("annotation-interrupt", this.interruptAnnotation);
		this.chan.$on("annotation-cancel", this.cancelAnnotation);

		let highlightInterface = {};
		highlightInterface.setAnnotations = (annos) => {
			this.setAnnotations(annos);
		};
		highlightInterface.getAnnotations = () => {
			return this.getAnnotations();
		};
		this.$emit("update:highlighter", highlightInterface);
	},

	mounted() {
		this.loadImage();
	},
	destroyed() {
		if (!this.chan) {
			return;
		}

		var _this = this;
		_.each(this.events, (e) => {
			_this.chan.$off(e.key, e.val);
		});

		var canvas = document.getElementById(this.page.id);
		if (canvas) {
			canvas.parentElement.removeChild(canvas);
		}
	},

	methods: {
		drawImage() {
			console.log("drawing image");
			var size = 1;
			if (this.chan) {
				this.zoomScale = store.get(this, "viewerScale");
				size = this.sizes[this.zoomScale] || this.sizes[3];
			}

			let aspectRatio = this.origImage.height / this.origImage.width;

			let targetWidth = Math.min(1500, this.origImage.width) * size;
			let targetHeight = Math.floor(targetWidth * aspectRatio);

			this.totalScale = targetWidth / this.origImage.width;
			this.image.width = targetWidth;
			this.image.height = targetHeight;

			let canvas = document.getElementById(this.page.id);
			if (canvas) {
				let context = canvas.getContext("2d");

				canvas.width = targetWidth;
				canvas.height = targetHeight;
				if (this.chan) {
					$(this.$refs.img).css("max-width", targetWidth);
					$(this.$refs.img).css("min-width", targetWidth);
				}

				context.drawImage(this.image, 0, 0, targetWidth, targetHeight);
			}

			this.initHighlightInterface();

			this.positionAnnotationTooltips();

			this.imageLoaded = true;
			this.chan.$emit("increment-media-loaded");
		},

		loadImage() {
			this.origImage = new Image();
			this.origImage.src = this.page.url;
			this.image = this.origImage.cloneNode(true);
			this.image.addEventListener("load", this.drawImage);
			this.image.addEventListener("error", () => {
				this.chan.$emit("increment-media-failed");
				this.imageLoaded = false;
			});

			this.chan.$emit("increment-media-to-load");
		},

		interruptAnnotation() {
			if (this.selectingHighlight) {
				this.disableAnnotation();
			}
		},

		toggleAnnotation() {
			if (!this.selectingHighlight) {
				this.enableAnnotation();
			} else {
				this.disableAnnotation();
			}
		},

		cancelAnnotation() {
			var el = this.$refs.img;
			if (!el) return;
			this.selectingHighlight = false;
			el.style.cursor = "default";

			el.removeEventListener("mousedown", this.startSelection);
			el.removeEventListener("touchstart", this.startSelection);
			el.removeEventListener("mouseup", this.endSelection);
			el.removeEventListener("touchend", this.endSelection);
		},

		disableAnnotation() {
			this.cancelAnnotation();
			this.chan.$emit("annotation-off");
		},

		enableAnnotation() {
			var el = this.$refs.img;
			this.selectingHighlight = true;
			el.style.cursor = "alias";
			this.chan.$emit("annotation-on");

			el.addEventListener("mousedown", this.startSelection);
			el.addEventListener("touchstart", this.startSelection);
			el.addEventListener("mouseup", this.endSelection);
			el.addEventListener("touchend", this.endSelection);
		},

		startSelection(event) {
			let container = this.$refs.img;
			this.selectionBox = document.createElement("div");
			this.selectionBox.style.position = "absolute";
			this.selectionBox.style.border = "2px dashed #888888";
			container.appendChild(this.selectionBox);
			window.selectionBox = this.selectionBox;

			let containerRect = container.getBoundingClientRect();
			this.hX1 = event.clientX - containerRect.left;
			this.hY1 = event.clientY - containerRect.top;
			this.hX2 = event.clientX;
			this.hY2 = event.clientY;
			this.redrawSelection(event);

			container.addEventListener("mousemove", this.redrawSelection);
			container.addEventListener("touchmove", this.redrawSelection);
		},

		endSelection(event) {
			let container = this.$refs.img;
			let containerRect = container.getBoundingClientRect();
			this.hX2 = event.clientX - containerRect.left;
			this.hY2 = event.clientY - containerRect.top;

			this.selectionBox.remove();

			let left = Math.min(this.hX1, this.hX2);
			let right = Math.max(this.hX1, this.hX2);
			let top = Math.min(this.hY1, this.hY2);
			let bottom = Math.max(this.hY1, this.hY2);

			container.removeEventListener("mousemove", this.redrawSelection);
			container.removeEventListener("touchmove", this.redrawSelection);

			this.disableAnnotation();
			let timestamp = +new Date();
			this.createAnnotationRegion(
				timestamp,
				left / this.totalScale,
				(right - left) / this.totalScale,
				top / this.totalScale,
				(bottom - top) / this.totalScale
			);
		},

		redrawSelection(event) {
			let container = this.$refs.img;
			let containerRect = container.getBoundingClientRect();
			this.hX2 = event.clientX - containerRect.left;
			this.hY2 = event.clientY - containerRect.top;

			let left = Math.min(this.hX1, this.hX2);
			let right = Math.max(this.hX1, this.hX2);
			let top = Math.min(this.hY1, this.hY2);
			let bottom = Math.max(this.hY1, this.hY2);

			this.selectionBox.style.left = `${left}px`;
			this.selectionBox.style.top = `${top}px`;
			this.selectionBox.style.width = `${right - left}px`;
			this.selectionBox.style.height = `${bottom - top}px`;
		},

		applyHoverToGroup(timestamp) {
			let ar = _.find(this.annotationRegions, {
				timestamp: timestamp,
			});
			if (!ar) {
				console.log("Could not find annotation with timestamp", timestamp);
				return;
			}

			ar.hovered = true;

			let box = document.getElementById(`box-${timestamp}`);
			if (box) {
				box.classList.add("hovered");
			}

			let overlay = document.getElementById(`overlay-${timestamp}`);
			if (overlay) {
				overlay.classList.add("hovered");
			}

			let border = document.getElementById(`overlay-border-${timestamp}`);
			border._tippy.show();
		},

		applyUnhoverToGroup(timestamp, force) {
			let ar = _.find(this.annotationRegions, {
				timestamp: timestamp,
			});
			if (!ar) {
				console.log("Could not find annotation with timestamp", timestamp);
				return;
			}

			if (!force && ar.editing) {
				return;
			}

			ar.hovered = false;

			let box = document.getElementById(`box-${timestamp}`);
			if (box) {
				box.classList.remove("hovered");
			}

			let overlay = document.getElementById(`overlay-${timestamp}`);
			if (overlay) {
				overlay.classList.remove("hovered");
			}

			let border = document.getElementById(`overlay-border-${timestamp}`);

			//Delay the hide until next tick to ensure that we're actually not hovered
			//over anything anymore. Prevents flickering between contiguous regions
			this.$nextTick(() => {
				if (!ar.hovered) {
					border._tippy.hide();
				}
			});
		},

		createAnnotationRegion(timestamp, left, width, top, height) {
			let serialized = `${left}:${width}:${top}:${height}`;
			let annotation = this.createAnnotation(serialized, timestamp);
			this.annotationRegions.push({
				timestamp: timestamp,
				annotation: annotation,
				editable: true,
				boxLeft: left,
				boxWidth: width,
				boxTop: top,
				boxHeight: height,
			});
			this.disableAnnotation();

			//Open the tooltip in edit mode right after the highlight is created
			//We need to wait three ticks here: 1 for the annotation region overlay to be created,
			//2 for the tippy code to hook into it, and 3 for the tooltip to position itself properly
			this.$nextTick(() => {
				this.$nextTick(() => {
					this.$nextTick(() => {
						let ar = _.find(this.annotationRegions, {
							timestamp: timestamp,
						});
						this.toggleEditAnnotation(ar);
					});
				});
			});
		},

		createAnnotation(serialized, timestamp) {
			let annotation = {
				id: timestamp.toString(),
				user_id: "5cd9b8c9098fc151a4780de2",
				type: 1, //Box highlight
				region: serialized,
				color: "blue",
				// text: `Annotation ${this.annotationRegions.length + 1}`,
				text: "",
			};
			this.$emit("saveAnnotation", annotation);
			return annotation;
		},

		toggleEditAnnotation(ar) {
			if (ar.editing) {
				ar.annotation.color = this.annotationOrigColor;

				this.applyUnhoverToGroup(ar.timestamp, true);

				//Delaying this to prevent the tooltip from flickering back into non-edit mode during its hiding animation.
				//Tippy has on onHidden callback that would be better here, but I couldn't get it to fire.
				//So we're using setTimeout as a hacky replacement. It works, though.
				setTimeout(() => {
					ar.editing = false;
					this.$forceUpdate();
				}, 200);
			} else {
				this.annotationOrigColor = ar.annotation.color;
				ar.editing = true;
				this.annotationInput = ar.annotation.text;

				let el = document.getElementById(`overlay-border-${ar.timestamp}`);
				let popup = document.getElementById(`overlay-popup-${ar.timestamp}`);

				if (!popup) {
					//If edit is toggled from an outside source, the actual region might not be in a hovered state yet
					//If this is the case, change it to hovered before opening the tooltip
					this.applyHoverToGroup(ar.timestamp);
					el._tippy.popperInstance.update();
				}
				el._tippy.onMount = () => {
					new ResizeObserver(el._tippy.popperInstance.update).observe(popup);
				};
			}

			this.$forceUpdate();
		},

		removeAnnotationRegion(timestamp) {
			let ar = _.find(this.annotationRegions, { timestamp, timestamp });
			if (!ar) {
				console.log(`Tried to remove annotation region ${timestamp} but it did not exist`);
			}

			this.annotationRegions = _.without(this.annotationRegions, ar);
			this.$emit("deleteAnnotation", timestamp);
		},

		removeAllAnnotationRegions() {
			_.each(this.annotationRegions, (_, timestamp) => {
				this.removeAnnotationRegion(timestamp);
			});
		},

		positionAnnotationTooltips() {
			this.$nextTick(() => {
				console.log("Position annotation tooltips");
				_.each(this.annotationRegions, (ar) => {
					let el = document.getElementById(`overlay-border-${ar.timestamp}`);
					if (el && el._tippy && el._tippy.popperInstance) {
						console.log("update", el);
						el._tippy.popperInstance.update();
					}
				});
			});
		},

		changeHighlightColor(ar, color) {
			this.doChangeHighlightColor(ar.timestamp, color);
			ar.annotation.color = color;
		},

		doChangeHighlightColor(timestamp, color) {
			let colors = ["blue", "red", "yellow", "green"];
			_.each(colors, (c) => {
				// this.highlighter.removeClassFromGroup(
				// 	`_highlighter-${c}`,
				// 	timestamp
				// );
			});
			// this.highlighter.addClassToGroup(
			// 	`_highlighter-${color}`,
			// 	timestamp
			// );
		},

		saveAnnotationRegion(ar) {
			ar.annotation.text = this.annotationOutput;
			this.annotationOrigColor = ar.annotation.color;
			this.toggleEditAnnotation(ar);

			this.$emit("saveAnnotation", ar.annotation);
		},

		initHighlightInterface() {
			let highlightInterface = {};
			highlightInterface.setAnnotations = (annos) => {
				this.setAnnotations(annos);
			};
			highlightInterface.getAnnotations = () => {
				return this.getAnnotations();
			};
			this.$emit("update:highlighter", highlightInterface);
		},

		setAnnotations(annos) {
			this.annotationRegions = [];

			_.each(annos, (annotation) => {
				let coords = annotation.region.split(":");
				let left = parseFloat(coords[0]);
				let width = parseFloat(coords[1]);
				let top = parseFloat(coords[2]);
				let height = parseFloat(coords[3]);

				this.annotationRegions.push({
					timestamp: parseInt(annotation.id),
					annotation: annotation,
					boxLeft: left,
					boxWidth: width,
					boxTop: top,
					boxHeight: height,
				});

				this.positionAnnotationTooltips();
			});
		},

		getAnnotations() {
			let annos = [];
			_.each(this.annotationRegions, (ar) => {
				let anno = ar.annotation;
				annos.push(anno);
			});

			return annos;
		},
	},
};
</script>

<style scoped>
.image-box {
	margin-top: 20px;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	/*min-height:1250px;*/
	/*width:1000px;*/
	/*font-size: 17pt;*/

	/*min-height:1000px;*/
	max-width: 1500px;
	min-width: 400px;
	font-size: 13pt;

	/*padding:80px;*/
	-webkit-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	-moz-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	background-color: white;
	font-family: "Times New Roman", Times, Georgia, serif;
}

.image-box:not(.mz-loading-icon) {
	color: black;
}

.annotation-box {
	position: absolute;
}

.annotation-margin {
	position: absolute;
	width: 60px;
	right: 10px;
	display: flex;
	flex-direction: row;
}
.annotation-side-border {
	position: absolute;
	width: 3px;
	left: -5px;
	height: 100px;
	opacity: 0;
	transition: opacity 0.15s;
	pointer-events: none;
}
.annotation-margin.hovered .annotation-side-border {
	opacity: 1;
}

a.btn-subtle {
	margin-top: -2px;
	opacity: 1;
	transition: background-color 0.25s;
	width: 30px;
	height: 30px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 2rem;
	transition: opacity 0.15s;
}
a.btn-subtle:hover {
	background-color: rgba(0, 0, 0, 0.1);
}
.annotation-margin:not(.hovered) a.overlay-btn {
	opacity: 0;
}

.highlight-btn {
	width: 23px;
	height: 23px;
	border: 1px solid #111;
	border-radius: 12px;
	margin: 5px 8px;
	display: inline-block;
	cursor: pointer;
}
.highlight-btn.selected {
	box-shadow: #666 0 0 4px 2px;
}
.highlight-blue {
	background-color: rgb(41, 182, 246);
}
.highlight-red {
	background-color: rgba(244, 67, 54, 0.75);
}
.highlight-yellow {
	background-color: rgb(248, 240, 100);
}
.highlight-green {
	background-color: rgb(139, 195, 74);
}
.highlight-orange {
	background-color: rgb(246, 154, 77);
}
.highlight-purple {
	background-color: rgb(143, 100, 150);
}
</style>
